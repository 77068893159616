import React, { useEffect, useState } from 'react';
import { Card, Col, Form, PageHeader, Row, Select } from 'antd';

import 'antd/dist/antd.css';
import './index.scss';
import EchartsComponent from '../../charts/EchartsComponent';
import {
    getActiveUserStatistics,
    getActivityCountStatistics,
    getGiftStatistics,
    getInviteStatistics,
    getNewUserStatistics,
    getPvStatistics,
    getSearchActivities,
    getStayStatistics,
    getUvStatistics,
} from '../../../axios/tools';

function composeLineEchartData(title, legend, category, series, total) {
    return {
        title: title,
        options: {
            title: { left: 'left', text: total },
            color: [
                'rgba(77, 174, 245,1)',
                'rgba(245, 154, 35,1)',
                'rgba(239,219,200,1)',
                'rgba(137,246,100,1)',
                'rgba(155,314,203,1)',
                'rgba(155,155,146)',
                'rgba(111,222,100,1)',
            ],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999',
                    },
                },
            },
            toolbox: {
                //   feature: {
                //     dataView: { show: true, readOnly: false },
                //     magicType: { show: true, type: ['line', 'bar'] },
                //     restore: { show: true },
                //     saveAsImage: { show: true }
                //   }
            },
            legend: {
                left: 'left',
                bottom: '0',
                data: legend,
            },
            xAxis: [
                {
                    type: 'category',
                    data: category,
                    axisPointer: {
                        type: 'shadow',
                    },
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    name: '数量',
                    // min: 0,
                    // max: 250,
                    // interval: 50,
                    axisLabel: {
                        formatter: '{value}',
                    },
                },
            ],
            series: series,
        },
    };
}

function composeBarEchartData(title, legend, category, series, total) {
    return {
        title: title,
        options: {
            title: { left: 'left', text: total },
            legend: {
                left: 'right',
                top: '0',
                data: legend,
            },
            xAxis: [
                {
                    type: 'category',
                    data: category,
                    axisPointer: {
                        type: 'shadow',
                    },
                },
            ],
            yAxis: [
                {
                    type: 'value',
                },
            ],
            series: series,
        },
    };
}

// const data = [];
// for (let i = 0; i < 46; i++) {
//     data.push({
//         key: i,
//         address: i,
//         name: '按钮' + i,
//         type: '浪漫主义' + i,
//         size: Math.round(Math.random()*1000) + '*' + Math.round(Math.random()*1000),
//         click: Math.round(Math.random()*1000)
//     });
// }
function ActivityInformation() {
    const [form] = Form.useForm();
    // const [ tabelList, setTabelList ] = useState([])
    const [echartList, setEchartList] = useState([]);
    const [activities, setActivities] = useState([]);
    const [activityID, setActivityID] = useState(null);
    const [activityType, setActivityType] = useState('9');
    const [activityState, setActivityState] = useState('0');
    const [timeType, setTimeType] = useState([1, 1, 1, 1, 1, 1, 1, 1]);

    // const columns = [
    //     {
    //         title: '位置',
    //         dataIndex: 'address',
    //         key: 'address',
    //     },
    //     {
    //         title: '名称',
    //         dataIndex: 'name',
    //         key: 'name',
    //     },
    //     {
    //         title: '默认样式',
    //         dataIndex: 'type',
    //         key: 'type',
    //     },
    //     {
    //         title: '尺寸',
    //         dataIndex: 'size',
    //         key: 'size',
    //     },
    //     {
    //         title: '点击次数',
    //         dataIndex: 'click',
    //         key: 'click',
    //     },
    // ];
    useEffect(() => {
        const getSearchActivitiesFn = async () => {
            let activityList = await getSearchActivities({
                activityType: 9,
                activityState: 0,
            });
            setActivities(activityList.activities);
            setActivityID(activityList.activities[0] ? activityList.activities[0].id : 0);
            form.setFieldsValue({
                activityType: '9',
                activityState: '0',
                activityID: activityList.activities[0] ? activityList.activities[0].id : 0,
            });
        };
        getSearchActivitiesFn().then(() => {});
    }, [form]);
    useEffect(() => {
        if (activityID === null) return;
        const getEchartData = async () => {
            let giftData = await getGiftStatistics({
                timeType: timeType[0],
                activityID: activityID,
                activityType,
                activityState,
            });
            let giftEchartData = composeBarEchartData(
                '奖品发放',
                giftData.legend,
                giftData.category,
                giftData.series,
                giftData.total
            );

            let pvData = await getPvStatistics({
                timeType: timeType[1],
                activityID: activityID,
                activityType,
                activityState,
            });
            let pvEchartData = composeLineEchartData(
                'Pv',
                pvData.legend,
                pvData.category,
                pvData.series,
                pvData.total
            );

            let uvData = await getUvStatistics({
                timeType: timeType[2],
                activityID: activityID,
                activityType,
                activityState,
            });
            let uvEchartData = composeLineEchartData(
                'Uv',
                uvData.legend,
                uvData.category,
                uvData.series,
                uvData.total
            );

            let activityData = await getActivityCountStatistics({
                timeType: timeType[3],
                activityID: activityID,
                activityType,
                activityState,
            });
            let activityEchartData = composeLineEchartData(
                '参与活动次数',
                activityData.legend,
                activityData.category,
                activityData.series,
                activityData.total
            );

            let inviteData = await getInviteStatistics({
                timeType: timeType[4],
                activityID: activityID,
                activityType,
                activityState,
            });
            let inviteEchartData = composeLineEchartData(
                '裂变用户',
                inviteData.legend,
                inviteData.category,
                inviteData.series,
                inviteData.total
            );

            let activeData = await getActiveUserStatistics({
                timeType: timeType[5],
                activityID: activityID,
                activityType,
                activityState,
            });
            let activeEchartData = composeBarEchartData(
                '活跃用户数',
                activeData.legend,
                activeData.category,
                activeData.series,
                activeData.total
            );

            let newUserData = await getNewUserStatistics({
                timeType: timeType[6],
                activityID: activityID,
                activityType,
                activityState,
            });
            let newUserEchartData = composeLineEchartData(
                '新用户',
                newUserData.legend,
                newUserData.category,
                newUserData.series,
                newUserData.total
            );

            let stayData = await getStayStatistics({
                timeType: timeType[7],
                activityID: activityID,
                activityType,
                activityState,
            });
            let stayEchartData = composeLineEchartData(
                '人均停留时长',
                stayData.legend,
                stayData.category,
                stayData.series,
                stayData.total
            );

            setEchartList([
                giftEchartData,
                pvEchartData,
                uvEchartData,
                activityEchartData,
                inviteEchartData,
                activeEchartData,
                newUserEchartData,
                stayEchartData,
            ]);
        };
        getEchartData();
    }, [activityState, activityType, activityID, timeType]);

    const getGiftStatisticsFn = async () => {
        let giftData = await getGiftStatistics({
            timeType: timeType[0],
            activityID: activityID,
            activityType,
            activityState,
        });
        echartList[0] = composeBarEchartData(
            '奖品发放',
            giftData.legend,
            giftData.category,
            giftData.series,
            giftData.total
        );
        setEchartList([...echartList]);
    };
    const getPvStatisticsFn = async () => {
        let pvData = await getPvStatistics({
            timeType: timeType[1],
            activityID: activityID,
            activityType,
            activityState,
        });
        echartList[1] = composeLineEchartData(
            'Pv',
            pvData.legend,
            pvData.category,
            pvData.series,
            pvData.total
        );
        setEchartList([...echartList]);
    };
    const getUvStatisticsFn = async () => {
        let uvData = await getUvStatistics({
            timeType: timeType[2],
            activityID: activityID,
            activityType,
            activityState,
        });
        echartList[2] = composeLineEchartData(
            'Uv',
            uvData.legend,
            uvData.category,
            uvData.series,
            uvData.total
        );
        setEchartList([...echartList]);
    };
    const getActivityCountStatisticsFn = async () => {
        let activityData = await getActivityCountStatistics({
            timeType: timeType[3],
            activityID: activityID,
            activityType,
            activityState,
        });
        echartList[3] = composeLineEchartData(
            '参与活动次数',
            activityData.legend,
            activityData.category,
            activityData.series,
            activityData.total
        );
        setEchartList([...echartList]);
    };
    const getInviteStatisticsFn = async () => {
        let inviteData = await getInviteStatistics({
            timeType: timeType[4],
            activityID: activityID,
            activityType,
            activityState,
        });
        echartList[4] = composeLineEchartData(
            '裂变用户',
            inviteData.legend,
            inviteData.category,
            inviteData.series,
            inviteData.total
        );
        setEchartList([...echartList]);
    };
    const getActiveUserStatisticsFn = async () => {
        let activeData = await getActiveUserStatistics({
            timeType: timeType[5],
            activityID: activityID,
            activityType,
            activityState,
        });
        echartList[5] = composeBarEchartData(
            '活跃用户数',
            activeData.legend,
            activeData.category,
            activeData.series,
            activeData.total
        );
        setEchartList([...echartList]);
    };
    const getNewUserStatisticsFn = async () => {
        let newUserData = await getNewUserStatistics({
            timeType: timeType[6],
            activityID: activityID,
            activityType,
            activityState,
        });
        echartList[6] = composeLineEchartData(
            '新用户',
            newUserData.legend,
            newUserData.category,
            newUserData.series,
            newUserData.total
        );
        setEchartList([...echartList]);
    };
    const getStayStatisticsFn = async () => {
        let stayData = await getStayStatistics({
            timeType: timeType[7],
            activityID: activityID,
            activityType,
            activityState,
        });
        echartList[7] = composeLineEchartData(
            '人均停留时长',
            stayData.legend,
            stayData.category,
            stayData.series,
            stayData.total
        );
        setEchartList([...echartList]);
    };

    function onFilterDate(time, index) {
        timeType[index] = time;
        setTimeType(timeType);
        switch (index) {
            case 0:
                getGiftStatisticsFn();
                break;
            case 1:
                getPvStatisticsFn();
                break;
            case 2:
                getUvStatisticsFn();
                break;
            case 3:
                getActivityCountStatisticsFn();
                break;
            case 4:
                getInviteStatisticsFn();
                break;
            case 5:
                getActiveUserStatisticsFn();
                break;
            case 6:
                getNewUserStatisticsFn();
                break;
            case 7:
                getStayStatisticsFn();
                break;
            default:
                break;
        }
    }

    function onFinish(value) {
        // search
        if (value.activityID) {
            setActivityID(value.activityID);
        }
        if (value.activityState) {
            setActivityState(value.activityState);
        }
        if (value.activityType) {
            setActivityType(value.activityType);
        }
        // getEchartData();
        // setTabelList(data.filter(e=> {
        //     if (value.note1 || value.note2 || value.note3 || value.note4) {
        //         return (
        //             value.note1 && e.name.includes(value.note1) ||
        //             value.note2 && e.userName.includes(value.note2) ||
        //             value.note3 && e.express.includes(value.note3) ||
        //             value.note4 && e.type.includes(value.note4) ||
        //             value.note5 && e.id.includes(value.note5) ||
        //             value.note6 && e.taskName.includes(value.note6)
        //         )
        //     }
        //     return true
        // }))
    }
    const onValuesChange = async (changedValues) => {
        if (changedValues.activityState) {
            if (changedValues.activityState) setActivityState(changedValues.activityState);
            let activityList = await getSearchActivities({
                activityType,
                activityState: changedValues.activityState,
            });
            setActivities(activityList.activities);
            setActivityID(activityList.activities[0] ? activityList.activities[0].id : 0);
            form.setFieldsValue({
                activityID: activityList.activities[0] ? activityList.activities[0].id : 0,
            });
        }

        if (changedValues.activityType) {
            if (changedValues.activityType) setActivityType(changedValues.activityType);
            let activityList = await getSearchActivities({
                activityType: changedValues.activityType,
                activityState,
            });
            setActivities(activityList.activities);
            setActivityID(activityList.activities[0] ? activityList.activities[0].id : 0);
            form.setFieldsValue({
                activityID: activityList.activities[0] ? activityList.activities[0].id : 0,
            });
        }

        if (changedValues.activityID) {
            setActivityID(changedValues.activityID ? changedValues.activityID : 0);
            form.setFieldsValue({
                activityID: changedValues.activityID ? changedValues.activityID : 0,
            });
        }
    };
    // function onRangePickerChange(value) {
    //     console.log(+value[0], moment(value[0]).format('YY-MM-DD'));
    // }
    return (
        <div className="activity_information">
            <PageHeader title="选择活动" className="site-page-header">
                <Form form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
                    <div className="activity_information_flex">
                        <div className="activity_information_flex_left activity_information_flex">
                            <Form.Item name="activityType" label="游戏类型">
                                <Select placeholder="人人集卡" style={{ width: '150px' }}>
                                    {/* <Select.Option value="1">翻翻乐</Select.Option>
                                    <Select.Option value="5">叠猫猫</Select.Option>
                                    <Select.Option value="6">福牛叠叠乐</Select.Option> */}
                                    <Select.Option value="9">人人集卡</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="activityState" label="活动状态">
                                <Select placeholder="全部" style={{ width: '150px' }}>
                                    <Select.Option value="0">全部</Select.Option>
                                    <Select.Option value="1">进行中</Select.Option>
                                    <Select.Option value="3">暂停中</Select.Option>
                                    <Select.Option value="2">已结束</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="activityID" label="活动名称">
                                <Select data-placeholder="暂无数据" style={{ width: '150px' }}>
                                    {activities.map((activity) => {
                                        return (
                                            <Select.Option value={activity.id} key={activity.id}>
                                                {activity.title}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </PageHeader>

            <Row gutter={24}>
                {echartList.map((item, index) => {
                    return (
                        <Col span={8} style={{ margin: '10px 0' }} key={`home_echarts${index}`}>
                            <Card title={item.title}>
                                <Select
                                    placeholder="全部"
                                    style={{ width: '150px' }}
                                    onChange={(val) => {
                                        onFilterDate(val, index);
                                    }}
                                    defaultValue={1}
                                >
                                    {/* <Select.Option value={-1}>全部</Select.Option> */}
                                    <Select.Option value={1}>近一日</Select.Option>
                                    <Select.Option value={2}>近三日</Select.Option>
                                    <Select.Option value={3}>近一周</Select.Option>
                                    <Select.Option value={4}>近15日</Select.Option>
                                    <Select.Option value={5}>近一月</Select.Option>
                                    <Select.Option value={6}>近三月</Select.Option>
                                    <Select.Option value={7}>近六月</Select.Option>
                                    <Select.Option value={8}>近一年</Select.Option>
                                </Select>
                                <EchartsComponent options={item.options} />
                            </Card>
                        </Col>
                    );
                })}
            </Row>

            {/* <Card title="UI部件交互数据" extra={<><span>起止时间：&nbsp;</span><RangePicker onChange={onRangePickerChange}></RangePicker></>}>
            <Table columns={columns} dataSource={tabelList} />
        </Card> */}
        </div>
    );
}
export default ActivityInformation;
