import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Table,
} from 'antd';
import moment from 'moment';

import './index.scss';
import {usersList} from '../../../axios/tools';
import TagList from '../tagManagement/TagList';
import {useAlita} from 'redux-alita';

const { RangePicker } = DatePicker;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function UserList (props) {
    const [form] = Form.useForm()
    const [listData, setListData] = useState([])
    const [lastLoginAtStart, setLastLoginAtStart] = useState(null)
    const [firstLoginAtStart, setFirstLoginAtStart] = useState(null)
    const [lastLoginAtEnd, setLastLoginAtEnd] = useState(null)
    const [firstLoginAtEnd, setFirstLoginAtEnd] = useState(null)
    const [page, setPage] = useState({pageSize: 20, current: 1});
    const [visible, setVisible] = useState(false)
    const [tags, setTags] = useState([])
    const [userTags = [], ] = useAlita('userTags', { light: true })
    const columns = [
        {
            title: '用户昵称',
            key: 'nickname',
            dataIndex: 'nickname',
            render: text => {
                return (<span>{text}</span>)
            },
        },
        {
            title: 'open ID',
            key: 'openId',
            dataIndex: 'openId',
        },
        {
            title: '手机号码',
            key: 'mobile',
            dataIndex: 'mobile',
        },
        {
            title: '首次登录时间',
            name: 'createdAtFormat',
            key: 'createdAtFormat',
            dataIndex: 'createdAtFormat',
        },
        {
            title: '最近登录时间',
            key: 'lastLoginAtFormat',
            dataIndex: 'lastLoginAtFormat',
        },
        {
            title: '用户标签',
            key: 'mobile',
            dataIndex: 'tags',
            render: (tags) => {
                return (
                    <>
                        {tags && tags.map(tag => tag.tag_name + ",")}
                    </>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'reduce',
            render: (text, record) => {
                return (
                    <span className="actions">
                        <span className="" onClick={() => { onLookDetail(record)}}>详情</span>
                    </span>
                );
            },
        },
    ]
    useEffect(() => {
        usersList({
            page: 1
        }).then(res => {
            if (res.code === 0) {
                setListData(res.data.items)
                setPage({
                    current: res.data.page * 1,
                    pageSize: res.data.page_size * 1,
                    total: res.data.total * 1
                });
            }
        });
    }, [])
    function onLookDetail(value) {
        props.history.push(`/app/user/detail?id=${value.openId}&name=${value.nickname}`)
    }
    // 搜索
    function onFinish(values) {
        setListData([])
        setPage({
            current: 1,
            pageSize: 20,
            total: 0,
        });
        console.log("onFinish......")
        getList();
    }
    function getList() {
        console.log("getList................")
        usersList({
            page: page.current,
            pageSize: page.pageSize,
            ...form.getFieldsValue(true),
            last_login_at_end: lastLoginAtEnd,
            last_login_at_start: lastLoginAtStart,
            first_login_at_start: firstLoginAtStart,
            first_login_at_end: firstLoginAtEnd,
            tags_id: tags,
        }).then(res => {
            if (res.code === 0) {
                setListData(res.data.items)
                setPage({
                    current: res.data.page * 1,
                    pageSize: res.data.page_size * 1,
                    total: res.data.total
                });
            }
        });
    }
    function onTableChange(page) {
        setPage(page.pagination)
        getList();
    }
    function onReset() {
        form.resetFields();
        setFirstLoginAtStart(null);
        setFirstLoginAtEnd(null);
        setLastLoginAtStart(null);
        setLastLoginAtEnd(null);
        setListData([])
    }

    function onFirstRangePickerChange (value) {
        setFirstLoginAtStart(moment(value[0]).unix())
        setFirstLoginAtEnd(moment(value[1]).unix())
    }
    function onLastRangePickerChange (value) {
        setLastLoginAtStart(moment(value[0]).unix())
        setLastLoginAtEnd(moment(value[1]).unix())
    }
    const handleOk = () => {
        let names = userTags;
        setTags(userTags);
        form.setFieldsValue({
            tagsName: names.join(",")
        })
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    return (<div className="user_list">
        <Card className="user_list_header" title="用户表" bordered={false}>
            <Form {...layout} form={form} size="small" name="control-hooks" onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item name="firstLoginAt" label="首次登陆时间">
                            <RangePicker onChange={onFirstRangePickerChange}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="tagsName" label="用户标签">
                            <Input size="small" onClick={()=>{
                                setVisible(true);
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="nickname" label="用户昵称">
                            <Input size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item name="lastLoginAt" label="最近登录时间">
                            <RangePicker onChange={onLastRangePickerChange}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="下线数量">
                            <Form.Item name="invite_num_min" noStyle><InputNumber size="small" style={{width:"60px"}} /></Form.Item>
                            <span style={{margin: '0 16px'}}>至</span>
                            <Form.Item name="invite_num_max" noStyle><InputNumber size="small" style={{width:"60px"}} /></Form.Item>
                        </Form.Item>
                    </Col>
                    <Col span={8} />
                </Row>
                <Form.Item>
                    <Button className="user_list_button" type="primary" htmlType="submit" size="small">搜索</Button>
                    <Button className="user_list_button" htmlType="button" size="small" onClick={() => onReset()}>重置</Button>
                    {/*<Button className="user_list_button" htmlType="button" size="small" onClick={() => onExport()}>导出excel</Button>*/}
                </Form.Item>
            </Form>
        </Card>

        <Card>
            <Table rowKey="openId" columns={columns} dataSource={listData} pagination={page} onChange={onTableChange}/>
        </Card>
        <Modal
            width={1000}
            visible={visible}
            title="选择用户标签"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>取消</Button>,
                <Button key="submit" type="primary" onClick={handleOk}>确认</Button>
            ]}
        >
            <TagList type="add"/>
        </Modal>
    </div>)
}

export default UserList
