import React, {useCallback, useEffect} from 'react';
import { Form, Card} from 'antd'
import { useAlita } from 'redux-alita'

import ImportTicket from '../../../../../assets/import.png'
import WechatTicket from '../../../../../assets/wechatquan.png'
import PortTicket from '../../../../../assets/port.png'

import TypeImport from './TypeImport/index'
import TypeWechat from './TypeWechat/index'
import TypePort from './TypePort/index'

const TicketImgType = {
    ImportTicket: ImportTicket,
    WechatTicket: WechatTicket,
    PortTicket: PortTicket,
}
const TicketComponent = {
    "ImportTicket": <TypeImport/>,
    "WechatTicket": <TypeWechat/>,
    "PortTicket": <TypePort/>,
}
const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
};
function EditTicket () {
    const [ form ] = Form.useForm()
    const [ selectTab , setAlita ] = useAlita( 'selectTab', { light: true })
    useEffect(() => {
        setAlita({ stateName: 'ticketForm', data: form })
    }, [form, setAlita])
    function onFinish (values) {
        console.log('Success:', values);
    }

    const TypeTicket = useCallback(() => {
        return (TicketComponent[selectTab])
    }, [selectTab])

    return (<>
        <Card><div style={{textAlign: 'center'}}><img src={TicketImgType[selectTab]} alt="" /></div></Card>
        <Form {...layout} form={form} onFinish={onFinish}>
            <TypeTicket/>
        </Form>
    </>)
}

export default EditTicket
