import React from 'react'
import { Card, Input, Form, Upload, Select, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons';

import './index.scss'

function normFile (e) {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
}
function TypeImport () {
    return (<>
        <Card className='type_wechat' title="使用场景">
            <div>用户获奖后，需要手动复制券号，前往目标平台粘贴使用。</div>
        </Card>
        <Form.Item label="券名称" name="username" rules={[{ required: true, message: '请输入券名称' }]}>
            <Input style={{width: '40%'}}/>
        </Form.Item>
        <Form.Item label="业务类型" name="type" rules={[{ required: true, message: '请选择业务类型' }]}>
            <Select style={{width: '40%'}}>
                <Select.Option value="满减券"></Select.Option>
            </Select>
        </Form.Item>
        <Form.Item label="导入" name="excel" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: '请上传Execl' }]}>
            <Upload name="files" action="https://ant.design/components/form-cn/upload.do">
                <Button icon={<UploadOutlined />}>上传Execl</Button>
                <span style={{margin:'0 0 0 10px', fontSize: '12px'}}>单次上传不超过2000条数据，请依照模板格式进行上传，</span>
                <span className='type_import_down_execl'>下载模板</span>
            </Upload> 
        </Form.Item>
    </>)
}

export default TypeImport
