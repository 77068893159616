import React from "react"
import {HomeOutlined, LinkOutlined, WechatOutlined, UserOutlined, RocketOutlined, ProjectOutlined, RedEnvelopeOutlined, LogoutOutlined} from '@ant-design/icons';
const routes = {
    menus: [
        // 菜单相关路由
        // { key: '/app/home/index', label: '首页', icon: 'mobile', component: 'Dashboard' },
        { key: '/app/home/index', label: '首页', icon: <HomeOutlined/>, component: 'HomePage' },
        {
            key: '/app/activity/index',
            label: '活动管理',
            icon: <ProjectOutlined/>,
            children: [
                {
                    key: '/app/activity/list',
                    label: '活动列表',
                    component: 'ActivityList',
                },
                {
                    key: '/app/activity/home',
                    label: '添加活动',
                    component: 'Home',
                },
            ],
        },
        {
            key: '/app/user/index',
            label: '用户',
            icon: <UserOutlined/>,
            children: [
                {
                    key: '/app/user/list',
                    label: '用户表',
                    component: 'UserList',
                },
                {
                    key: '/app/user/tag',
                    label: '标签管理',
                    component: 'TagManagement',
                },
            ],
        },
        {
            key: '/app/reward/index',
            label: '奖品管理',
            icon: <RedEnvelopeOutlined/>,
            children: [
                {
                    key: '/app/prize/physical',
                    label: '实物奖品',
                    component: 'Physical',
                },
                {
                    key: '/app/prize/convert',
                    label: '奖品兑换',
                    component: 'Convert',
                },
                {
                    key: '/app/prize/ticket',
                    label: '券',
                    component: 'Ticket',
                },
            ],
        },
        {
            key: '/app/link',
            label: '链接管理',
            icon: <LinkOutlined/>,
            children: [
                {
                    key: '/app/link/list',
                    label: '链接列表',
                    component: 'Link',
                },
            ],
        },
        {
            key: '/app/information/index',
            label: '数据分析',
            icon: <RocketOutlined/>,
            children: [
                {
                    key: '/app/information/activity',
                    label: '活动数据',
                    component: 'ActivityInformation',
                },
            ],
        },
        {
            key: '/app/wechat',
            label: '公众号授权管理',
            icon: <WechatOutlined/>,
            component: 'Wechat',
        },
        {
            key: '/app/logout',
            label: '退出登录',
            icon: <LogoutOutlined/>,
            component: 'LoginOut',
        },
    ],
    others: [
        {
            key: '/app/activity/edit',
            label: '活动编辑',
            component: 'ActivityEdit',
        },
        {
            key: '/app/user/detail',
            label: '用户详情',
            component: 'UserDetail',
        },
        {
            key: '/app/prize/physical/created',
            label: '奖品实物',
            component: 'CreatePhysical',
        },
        {
            key: '/app/link/created',
            label: '新建链接',
            component: 'CreateLink'
        }
    ], // 非菜单相关路由
};
export default routes;
