import React, { Component } from 'react';
import Routes from './routes';
import DocumentTitle from 'react-document-title';
import SiderCustom from './components/SiderCustom';
import HeaderCustom from './components/HeaderCustom';
import { Layout } from 'antd';
import { ThemePicker } from './components/widget';
import { connectAlita } from 'redux-alita';

import {permission} from './axios/tools';

const { Content, Footer} = Layout;

class App extends Component {
  constructor(props) {
    console.log("App++++++++++", props)
    super(props);
    const js = localStorage.getItem("user")
    console.log("app.js-------", js)
    if (js === "" || js === null) {
      this.goLogin();
    } else {
      const user = JSON.parse(js);
      if (!user) {
        this.goLogin();
      } else {
        this.getClientWidth();
        if (!props.auth.data) {
          permission().then((u)=>{
              props.setAlitaState({stateName: 'auth', data: u});
          });
        }
        this.state = {
          collapsed: false,
          title: '',
        }
      }
    }
  }
  componentDidMount() {
    this.getClientWidth();
    window.onresize = () => {
      console.log('屏幕变化了');
      this.getClientWidth();
    };
  }
  goLogin() {
    localStorage.removeItem('user');
    localStorage.removeItem("token");
    const clientWidth = window.innerWidth;
    this.props.setAlitaState({stateName: "auth", data: {}});
    this.props.setAlitaState({stateName: "responsive", data: { isMobile: clientWidth <= 992 }});
    this.props.history.push('/login')
  }
  getClientWidth = () => {
    // 获取当前浏览器宽度并设置responsive管理响应式
    const { setAlitaState } = this.props;
    const clientWidth = window.innerWidth;
    console.log(clientWidth);
    setAlitaState({ stateName: 'responsive', data: { isMobile: clientWidth <= 992 } });
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  updateState = (data) => {
    console.log("updateState........",data)
    this.setState({
      ...data
    })
  }
  render() {
    const { title } = this.state;
    return (
        <DocumentTitle title={title}>
          <Layout>
            {(!this.props.responsive.data || !this.props.responsive.data.isMobile) && <SiderCustom collapsed={this.state.collapsed} />}
            <ThemePicker />
            <Layout hasSider={false}>
              {this.props.auth.data &&
              <HeaderCustom
                  toggle={this.toggle}
                  collapsed={this.state.collapsed}
                  updateState={this.updateState}
              />
              }
              <Content style={{ margin: '0 16px', overflow: 'initial', flex: '1 1 0' }}>
                {this.props.auth.data &&
                <Routes responsive={this.props.responsive} updateState={this.updateState} />
                }
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                {/* 游戏营销平台 ©{new Date().getFullYear()} */}
              </Footer>
            </Layout>
          </Layout>
        </DocumentTitle>
    );
  }
}

export default connectAlita(['auth', 'responsive'])(App);
