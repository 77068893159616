import React, { useState, useEffect } from 'react';
import { Modal, Tag, Form, Button, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useAlita } from 'redux-alita'
import './index.scss'

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };
const TagAddDialog = (props) => {
    const [ form ] = Form.useForm();
    const [ isModalVisible, setIsModalVisible ] = useState(false);
    const [ buttonText, setButtonText ] = useState('New Tag')
    const [ dialogTitle, setDialogTitle] = useState('新增标签')

    const [ tagList, setAlita] = useAlita('tagList', { light: true })
    useEffect(() => {
        setButtonText(props.text)

        if (props.type === 'edit') {
            form.setFieldsValue({
              ...props.item
            });
            setDialogTitle('编辑标签')
          } else {
            form.resetFields();
            form.setFieldsValue({
              tag:[undefined]
            })
            setDialogTitle('新增标签')
          }
    }, [form, props.item, props.text, props.type])

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form.submit()
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = values => {
        console.log('Received values of form:', values);
        values.tag = values.tag.filter(e => e)
        setAlita({
            stateName: 'tagList',
            data: [...tagList.map(e => {
                if (e.id === props.item.id) {
                    if (props.type === 'edit') {
                        return {...e, ...values}
                    } else {
                        return {
                            ...e,
                            tag: [...values.tag, ...e.tag]
                        }
                    }
                }
                return e
            })]
        })
    };

    return (
        <>
        <Tag className="site-tag-plus" onClick={showModal}>{ buttonText }</Tag>
        <Modal title={dialogTitle} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
            footer={[
                // props.item ? <Button key="delete" onClick={handleDelete}>删除标签</Button>: '',
                <Button key="back" onClick={handleCancel}>关闭</Button>,
                <Button key="submit" type="primary" onClick={handleOk}>保存</Button>,
            ]}
        >
            <Form name="dynamic_form_nest_item" form={form} onFinish={onFinish} autoComplete="off">
                <Form.List name="tag">
                    {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                label={index === 0 ? '标签名称' : ''}
                                required={false}
                                key={field.key}
                            >
                                <Form.Item {...field} validateTrigger={['onChange', 'onBlur']} noStyle>
                                <Input placeholder="输入标签" style={{ width: '60%' }} />
                                </Form.Item> {fields.length > 1 ? (
                                <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => remove(field.name)}
                                />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item {...formItemLayoutWithOutLabel}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add field
                            </Button>
                        </Form.Item>
                    </>
                    )}
                </Form.List>
            </Form>
        </Modal>
        </>
    );
};

export default TagAddDialog
