import React, { useEffect, useState } from 'react'
import { Card, Button, Form, Input, InputNumber, Upload } from 'antd'
import './index.scss'
import {PlusOutlined} from '@ant-design/icons';
import {
    getToken,
    getApiBase, productAdd, productDetail, productUpdate,
} from '../../../../axios/tools';
import {beforeUpload} from '../../../../utils';
const uploadURI = getApiBase() + 'youzan/admin/upload';
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
const uploadExtra = {
    token: getToken(),
};
function CreatePhysical (props) {
    const [ form ] = Form.useForm()
    const [thumb, setThumb] = useState("")
    const [oldProduct, setOldProduct] = useState({})
    useEffect(() => {
        const id = props.productID
        if (id) {
            productDetail({product_id: id}).then((res) => {
                if (res.code === 0) {
                    res.data.price = res.data.price / 100
                    form.setFieldsValue(res.data);
                    setOldProduct(res.data);
                    setThumb(res.data.product_thumb);
                }
            })
        }
    }, [form, props.productID])

    function onReset () {
        form.resetFields()
    }

    function onFinish (value) { // search
        value.price = value.price * 100
        if (props.productID) {
            productUpdate({
                ...oldProduct,
                ...value
            }).then((res) => {
                if (res.code === 0) {
                    onReset();
                    props.success();
                }
            })
        } else {
            productAdd(value).then((res) => {
                if (res.code === 0) {
                    onReset();
                    props.success();
                }
            })
        }
        console.log('onFinish', value, form.getFieldValue("product_thumb"))
    }
    function normFile (e) {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        if (e && e.file && e.file.response && e.file.response.data.image_url) {
            setThumb(e.file.response.data.image_url);
            return e && e.file.response && e.file.response.data.image_url;
        }
    }
    return (<div className='create_physical'>
        <Card title="基本信息">
            <Form {...layout} form={form} onFinish={onFinish}>
                <Form.Item name="product_title" label="奖品名称" rules={[{required: true,message: '请输入奖品名称'}]}>
                    <Input placeholder='请输入奖品名称' style={{width: '40%'}}/>
                </Form.Item>
                <Form.Item name="stock" label="库存数" rules={[{required: true,message: '请输入库存数'}]}>
                    <InputNumber placeholder='请输入库存数' step="1" style={{width: '40%'}}/>
                </Form.Item>
                <Form.Item name="price" label="原价格" rules={[{required: true,message: '请输入原价格'}]}>
                    <InputNumber placeholder='原价格可输入小数点后两位' step="0.01" style={{width: '40%'}}/>
                </Form.Item>
                <Form.Item name="product_thumb" valuePropName="product_thumb" getValueFromEvent={normFile} label="奖品图片" rules={[{required: true,message: '请上传奖品图片'}]}>
                    <Upload name="file" action={uploadURI}
                            accept="image/*"
                            className="reward-product-thumb-upload"
                            data={uploadExtra}
                            beforeUpload={beforeUpload}
                            maxCount={1}
                            listType="picture-card"
                            showUploadList={false}
                    >
                        {thumb ? (<img src={thumb} alt={""} width={85} height={85} />) : (<PlusOutlined />)}
                    </Upload>
                </Form.Item>
                <Form.Item label="&nbsp;">
                    <span style={{color: '#0079FE',margin:'0 0 0 10px', fontSize: '12px'}}>只能上传jpg/png文件，且不超过500kb，分辨率300*300，最多上传1张</span>
                </Form.Item>
                <Form.Item>
                    {/*<Button onClick={() =>window.history.back()}>取消</Button>*/}
                    <Button htmlType="button" onClick={onReset}>重置</Button>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </Card>
    </div>)
}
export default CreatePhysical
