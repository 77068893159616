import React, { useEffect, useState } from 'react'
import { PageHeader,Card, Button, Form, Input, Select, Table, Space } from 'antd'
import "antd/dist/antd.css";
import './index.scss'
import {ActivityTypes} from '../../../utils';
import {ship, shipments} from '../../../axios/tools';
import {Dialog} from 'zent';
const data = [];
function Convert () {
    const [form] = Form.useForm();
    const [formShip] = Form.useForm();
    const [tableList, setTableList] = useState([]);
    const [page, setPage] = useState({pageSize: 20, current: 1});
    const [showShip, setShowShip] = useState(false);
    const [shipRow, setShowRow] = useState({});
    const columns = [
        {
            title: '获奖时间',
            dataIndex: 'reward_at_format',
            key: 'reward_at_format',
        },
        {
            title: '活动类型',
            dataIndex: 'activity_type',
            key: 'activity_type',
            render: text => ActivityTypes[text]
        },
        {
            title: '活动名称',
            dataIndex: 'activity_title',
            key: 'activity_title',
        },
        {
            title: '微信昵称',
            dataIndex: 'wechat_nickname',
            key: 'wechat_nickname',
        },
        {
            title: '收货姓名',
            dataIndex: 'consignee',
            key: 'consignee',
        },
        {
            title: '商品名称',
            dataIndex: 'gift_name',
            key: 'gift_name',
        },
        {
            title: '收件地址',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '联系方式',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '快递公司',
            dataIndex: 'express_company',
            key: 'express_company',
        },
        {
            title: '快递单号',
            dataIndex: 'express_no',
            key: 'express_no',
        },
        {
            title: '状态',
            dataIndex: 'express_state',
            key: 'express_state',
            render: text => (text === 1 ? "已发货" : "待发货")
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                record.express_state !== 1 &&
                <Space size="middle">
                    <Button type="primary" onClick={() => onSendConvert(record)}>发货</Button>
                </Space>
            ),
        },
    ];
    useEffect(() => {
        shipments({
            page: 1,
            pageSize: 20,
        }).then(res => {
            if (res.code === 0) {
                setTableList(res.data.items)
                setPage({
                    current: 1,
                    pageSize: 20,
                    total: res.data.total
                })
            }
        });
    }, [])

    function onSendConvert (record) {
        setShowRow(record);
        console.log(record);
        setShowShip(true);
        formShip.resetFields();
    }
    function doShip() {
        console.log(formShip.getFieldsValue(true));
        ship(formShip.getFieldsValue(true)).then(res => {
            if (res.code === 0) {
                setShowShip(false);
                shipments({
                    page: page.current,
                    pageSize: page.pageSize,
                    ...form.getFieldsValue(true)
                }).then(res => {
                    if (res.code === 0) {
                        setTableList(res.data.items)
                        setPage({
                            current: 1,
                            pageSize: 20,
                            total: res.data.total
                        })
                    }
                });
            }
        })
    }
    function cancelShip() {
        setShowShip(false);
    }
    function onReset () {
        setTableList(data)
        form.resetFields()
    }
    function onFinish () { // search
        setPage({
            current: 1,
            pageSize: 20,
        })
        shipments({
            page: 1,
            pageSize: 20,
            ...form.getFieldsValue(true)
        }).then(res => {
            if (res.code === 0) {
                setTableList(res.data.items)
                setPage({
                    current: 1,
                    pageSize: 20,
                    total: res.data.total
                })
            }
        });
    }

    function onTableChange(p) {
        setPage(p.pagination)
        shipments({
            page: page.current,
            pageSize: page.pageSize,
            ...form.getFieldsValue(true)
        }).then(res => {
            if (res.code === 0) {
                setTableList(res.data.items)
            }
        });
    }

    return (<div className='convert'>
        <PageHeader title="奖品兑换" className="site-page-header">
            <Form form={form} onFinish={onFinish}>
                <div className='convert_flex'>
                    <div className='convert_flex_left convert_flex'>
                        <Form.Item name="activity_title" label="活动名称">
                            <Input placeholder='请输入活动名称' />
                        </Form.Item>
                        <Form.Item name="consignee" label="收货人">
                            <Input placeholder='请输入收货人' />
                        </Form.Item>
                        <Form.Item name="express_no" label="快递单号">
                            <Input placeholder='请输入快递单号' />
                        </Form.Item>
                        <Form.Item name="express_state">
                            <Select placeholder='全部' style={{width:'150px'}}>
                                <Select.Option value={null}>全部</Select.Option>
                                <Select.Option value={0}>待发货</Select.Option>
                                <Select.Option value={1}>已发货</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="product_title" label="商品名称">
                            <Input placeholder='请输入商品名称' />
                        </Form.Item>
                    </div>
                    <div className='convert_flex_right'>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">搜索</Button>
                            <Button htmlType="button" onClick={onReset}>重置</Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </PageHeader>
        <Card>
            <Table columns={columns} rowKey="id" dataSource={tableList} pagination={page} onChange={onTableChange} />
        </Card>
        {showShip &&
        <Dialog visible={showShip} title="发货管理">
            <Form form={formShip} initialValues={shipRow} onFinish={doShip} labelCol={{span: 4}}>
                <Form.Item name="express_no" label="快递单号" required>
                    <Input placeholder='请输入快递单号'/>
                </Form.Item>
                <Form.Item name="express_company" label="快递公司" required>
                    <Input placeholder='请输入快递公司'/>
                </Form.Item>
                <Form.Item name="consignee" label="收货人">
                    <Input placeholder='请输入收货人' disabled/>
                </Form.Item>
                <Form.Item name="mobile" label="联系方式">
                    <Input placeholder='请输入联系方式' disabled/>
                </Form.Item>
                <Form.Item name="address" label="收件地址">
                    <Input placeholder='请输入收件地址' disabled/>
                </Form.Item>
                <Form.Item hidden name="id"><Input/></Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">发货</Button>
                    <Button htmlType="button" onClick={cancelShip}>取消</Button>
                </Form.Item>
            </Form>
        </Dialog>
        }
    </div>)
}
export default Convert
