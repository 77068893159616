import React, {useEffect} from 'react';
import { useAlita } from 'redux-alita'

import './index.scss'

import ImportTicket from '../../../../../assets/import.png'
import WechatTicket from '../../../../../assets/wechatquan.png'
import PortTicket from '../../../../../assets/port.png'
import { Modal } from 'antd';

function SelectTicket () {
    const [ selectTab, setAlita ] = useAlita( 'selectTab', { light: true })
    useEffect(() => {
        setAlita({ stateName: 'selectTab', data: 'WechatTicket' })
    }, [setAlita]);
    function onSelectTab(type) {
        switch (type) {
            case "WechatTicket":
                break;
            default:
                Modal.info({
                    title: '提示',
                    content: <span>功能开发中，敬请期待。</span>
                });
                return;
        }
        console.log(type)
        setAlita({ stateName: 'selectTab', data: type })
    }
    return (<div className='select_ticket'>
        <div className={`${selectTab === 'ImportTicket' ? 'active' : ''}`} onClick={() => onSelectTab('ImportTicket')}><img src={ImportTicket} alt="" /></div>
        <div className={`${selectTab === 'WechatTicket' ? 'active' : ''}`} onClick={() => onSelectTab('WechatTicket')}><img src={WechatTicket} alt="" /></div>
        <div className={`${selectTab === 'PortTicket' ? 'active' : ''}`} onClick={() => onSelectTab('PortTicket')}><img src={PortTicket} alt="" /></div>
    </div>)
}

export default SelectTicket
