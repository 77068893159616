import React from 'react';
import {PageHeader, Descriptions, Button, Modal, Card} from 'antd';
import './index.scss';

import UserDetailTag from './UserDetailTag/index';
import SocialRelation from './SocialRelation/index';
import SendInformation from './SendInformation/index';
import ParticipateActivities from './ParticipateActivities/index';
import {getUserByOpenID} from '../../../axios/tools';
import moment from 'moment';

const routes = [
  {
    path: 'index',
    breadcrumbName: '用户表',
  },
  {
    path: 'first',
    breadcrumbName: '用户详情',
  },
];
const tabListNoTitle = [
  {
    key: 'userDetailTag',
    tab: '用户标签',
  },
  {
    key: 'socialRelation',
    tab: '社交关系',
  },
  {
    key: 'sendInformation',
    tab: '寄送信息',
  },
  {
    key: 'participateActivities',
    tab: '参与活动',
  },
];

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openID: props.id,
      activeTabKey: 'userDetailTag',
      isModalVisible: false,
      userInfo: {},
      contentListNoTitle: {
        userDetailTag: <UserDetailTag id={props.query.id}/>,
        socialRelation: <SocialRelation id={props.query.id}/>,
        sendInformation: <SendInformation id={props.query.id}/>,
        participateActivities: <ParticipateActivities id={props.query.id}/>,
      },
    };
    getUserByOpenID({
      open_id: props.query.id,
    }).then((res) => {
      this.setState({
        userInfo: res.data || {},
      });
    });
  }

  onBack() {
    this.props.history.push(`/app/user/list`);
  }

  showModal() {
    this.setState({
      isModalVisible: true,
    });
  };

  handleOk() {
    this.props.history.push('/app/user/list');
    this.setState({
      isModalVisible: false,
    });
  };

  handleCancel() {
    this.setState({
      isModalVisible: false,
    });
  };

  onTabChange(key) {
    this.setState({
      activeTabKey: key,
    });
  };

  render() {
    const {isModalVisible, activeTabKey, contentListNoTitle} = this.state;
    return (<div>
      <PageHeader
          className="site-page-header"
          breadcrumb={{routes}}
          onBack={() => this.onBack()}
          title="用户详情"
      >
      </PageHeader>
      {this.state.userInfo &&
      <>
        <Card>
          <Descriptions title={this.state.userInfo.nickname} column={3} extra={<Button type="primary" danger onClick={() => this.showModal()}>拉黑</Button>}>
            <Descriptions.Item label="手机号">{this.state.userInfo.mobile}</Descriptions.Item>
            <Descriptions.Item label="地区">-</Descriptions.Item>
            <Descriptions.Item label="年龄">-</Descriptions.Item>
            <Descriptions.Item label="open id">{this.state.userInfo.openId}</Descriptions.Item>
            <Descriptions.Item label="性别">-</Descriptions.Item>
            <Descriptions.Item label="首次登录时间">{moment(
                this.state.userInfo.createdAt)
                .format('YYYY-MM-DD HH:mm')}</Descriptions.Item>
            <Descriptions.Item label="最近登录时间">{moment(
                this.state.userInfo.lastLoginAt)
                .format('YYYY-MM-DD HH:mm')}</Descriptions.Item>
          </Descriptions>
        </Card>
        <Card
            style={{margin: '20px 0'}}
            tabList={tabListNoTitle}
            activeTabKey={activeTabKey}
            // tabBarExtraContent={<a href="#">More</a>}
            onTabChange={key => {
              this.onTabChange(key);
            }}
        >
          <div style={{minHeight: '300px'}}>
            {contentListNoTitle[activeTabKey]}
          </div>
        </Card>
        <Modal title="确认" visible={isModalVisible} onOk={() => this.handleOk()}
               onCancel={() => this.handleCancel()}
        >
          <p>确认拉黑用户“{this.state.userInfo.nickname}”</p>
        </Modal>
      </>
      }
    </div>);
  }
}

export default UserDetail;
