import React, {useEffect, useState} from 'react';
import {
  PageHeader,
  Card,
  Button,
  Form,
  Table,
  Space,
  Select, Modal,
} from 'antd';
import 'antd/dist/antd.css';
import './index.scss';
import {linkList} from '../../axios/tools';
import CreateLink from './CreateLink';

const data = [];
function Link(props) {
  const [form] = Form.useForm();
  const [tableList, setTableList] = useState([]);
  const [page, setPage] = useState({pageSize: 20, current: 1});
  const [showCreate, setShowCreate] = useState(false);
  const [editLinkID, setEditLinkID] = useState("");
  const columns = [
    {
      title: 'ID',
      dataIndex: 'link_id',
      key: 'link_id',
    },
    {
      title: '名称',
      dataIndex: 'link_title',
      key: 'link_title',
    },
    {
      title: '类型',
      dataIndex: 'link_type',
      key: 'link_type',
      render: (text => {
        switch (text) {
          case 1:
            return "H5链接";
          case 2:
            return "小程序链接";
          case 3:
            return "第三方小程序";
          default:
            return "-";
        }
      })
    },
    {
      title: '链接',
      dataIndex: 'h5_url',
      key: 'h5_url',
      render: (text, record) => (
          <span>{record.link_type === 1 ? text : record.mini_path}</span>
      )
    },
    {
      title: '创建日期',
      dataIndex: 'created_at_format',
      key: 'created_at_format',
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => onEditLink(record)}>编辑</Button>
          {
            props.chooseDialog &&
            <Button type="primary" onClick={() => props.chooseCallback(record)}>选择</Button>
          }
        </Space>
      ),
    },
  ];

  useEffect(() => {
    linkList({
      page: 1,
      pageSize: 20,
    }).then(res => {
      if (res.code === 0) {
        setTableList(res.data.items)
        setPage({
          current: 1,
          pageSize: 20,
          total: res.data.total
        });
      }
    });
  }, []);

  function onEditLink(record) {
    setEditLinkID(record.link_id);
    setShowCreate(true);
  }

  function onReset() {
    setTableList(data);
    form.resetFields();
    linkList({
      page: 1,
      pageSize: 20,
    }).then(res => {
      if (res.code === 0) {
        setTableList(res.data.items)
        setPage({
          current: 1,
          pageSize: 20,
          total: res.data.total
        });
      }
    });
  }

  function onCreateLink() {
    setEditLinkID("");
    setShowCreate(true);
  }

  function onTableChange(p) {
    setPage(p.pagination)
    linkList({
      page: page.current,
      pageSize: page.pageSize,
      ...form.getFieldsValue(true)
    }).then(res => {
      if (res.code === 0) {
        setTableList(res.data.items)
      }
    });
  }
  function onFinish() { // search
    setPage({
      current: 1,
      pageSize: 20,
    })
    linkList({
      page: 1,
      pageSize: 20,
      ...form.getFieldsValue(true)
    }).then(res => {
      if (res.code === 0) {
        setTableList(res.data.items)
        setPage({
          current: 1,
          pageSize: 20,
          total: res.data.total
        })
      }
    });
  }

  return (<div className="Link">
    <PageHeader title={props.chooseDialog ? '':'链接管理'} className="site-page-header">
      <Form form={form} onFinish={onFinish}>
        <div className="Link_flex">
          <div className="Link_flex_left Link_flex">
            <Form.Item name="link_type" label="链接类型">
              <Select placeholder='全部' style={{width:'150px'}}>
                <Select.Option value={null}>--</Select.Option>
                <Select.Option value={1}>H5</Select.Option>
                <Select.Option value={2}>小程序页面</Select.Option>
                <Select.Option value={3}>第三方小程序</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="Link_flex_right">
            <Form.Item>
              <Button type="primary" htmlType="submit">搜索</Button>
              <Button htmlType="button" onClick={onReset}>重置</Button>
              <Button type="primary" onClick={onCreateLink}>新建链接</Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </PageHeader>
    <Card>
      <Table rowKey="link_id" columns={columns} dataSource={tableList} pagination={page} onChange={onTableChange}/>
    </Card>
    <Modal
        visible={showCreate}
        title="新建/编辑链接"
        width={1200}
        onCancel={() => setShowCreate(false)}
        onOk={() => setShowCreate(false)}
        footer={[
        ]}
    >
      {
        showCreate &&
        <CreateLink linkID={editLinkID} success={() => {
          setShowCreate(false);
          onReset();
          onFinish();
        }}
        />
      }
    </Modal>
  </div>);
}

export default Link;
