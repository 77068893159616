import React, {useEffect, useState} from 'react';
import {Table} from 'antd';
import {shipmentsAddress} from '../../../../axios/tools';

const columns = [
  {
    title: '收件人',
    dataIndex: 'consignee',
    key: 'consignee',
    render: text => <span>{text}</span>,
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',
  },
  {
    title: '寄送地址',
    dataIndex: 'address',
    key: 'address',
  },
];

// 寄送信息
const SendInformation = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState({current: 1, pageSize: 20, total: 0});
  useEffect(() => {
    shipmentsAddress({
      page: 1,
      pageSize: 20,
      open_id: props.id,
    }).then(res => {
      if (res.code === 0) {
        setData(res.data.items);
        setPage({
          current: 1,
          pageSize: res.data.page_size,
          total: res.data.total,
        });
      }
    });
  }, [props.id]);

  function onTableChange(p) {
    setPage(p.pagination);
    shipmentsAddress({
      page: p.pagination.current,
      pageSize: page.pageSize,
    }).then(res => {
      if (res.code === 0) {
        setData(res.data.items);
        setPage({
          current: res.data.page,
          pageSize: res.data.page_size,
          total: res.data.total,
        });
      }
    });
  }

  return (<>
    <Table columns={columns} pagination={page} onChange={onTableChange} dataSource={data}/>
  </>);
};
export default SendInformation;
