import React from 'react';
import { Menu } from 'antd';
// import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
// import "./SiderMenu.scss";
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
//
// const renderMenuItem = (
//     item // item.route 菜单单独跳转的路由
// ) => (
//     <Menu.Item key={item.key}>
//         <Link to={(item.route || item.key) + (item.query || '')}>
//             {item.icon && <Icon component={item.icon} />}
//             <span className="nav-text">{item.title}</span>
//         </Link>
//     </Menu.Item>
// );
//
// const renderSubMenu = item => (
//     <Menu.SubMenu
//         key={item.key}
//         title={
//             <span>
//                 {item.icon && <Icon component={item.icon} />}
//                 <span className="nav-text">{item.title}</span>
//             </span>
//         }
//     >
//         {item.subs.map(item => renderMenuItem(item))}
//     </Menu.SubMenu>
// );

const SiderMenu = (props) => {
    // const [dragItems, setDragItems] = useState(menus);
    // const reorder = (list, startIndex, endIndex) => {
    //     const result = Array.from(list);
    //     const [removed] = result.splice(startIndex, 1);
    //     result.splice(endIndex, 0, removed);
    //     return result;
    // };
    // const onDragEnd = result => {
    //     // dropped outside the list
    //     if (!result.destination) {
    //         return;
    //     }
    //
    //     const _items = reorder(dragItems, result.source.index, result.destination.index);
    //     setDragItems(_items);
    // };
    const history = useHistory();
    console.log('sidermenu=====================',props.menus);
    return (
        <div>
          <Menu mode="inline" items={props.menus} onClick={({key}) => {
            return history.push(key);
          }}
          />
        </div>
    )
};
export default SiderMenu;
