//引入react jsx写法的必须
import React from 'react';
import {Form, Input, Upload, Card} from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import {Notify, Dialog} from 'zent';

import fanfanleCardExample from '../../assets/翻卡图片示例.gif';
import catCoffeeExample from '../../assets/喵呜照片海报.png';
import guessPriceExample from '../../assets/guess-example/广告图-banner图示例-商品图示例.png';
import diemaomaoExample from '../../assets/diemaomao/card-example.jpg';
import funiuExample from '../../assets/funiu/card-example.jpg';
import code1 from '../../assets/code/image001.png';
import code2 from '../../assets/code/image002.png';
import code3 from '../../assets/code/image003.png';
import code4 from '../../assets/code/image004.png';
import code5 from '../../assets/code/image005.png';
import code6 from '../../assets/code/image006.png';
import code7 from '../../assets/code/image007.png';
import code8 from '../../assets/code/image008.png';
import code9 from '../../assets/code/image009.png';

import {
  getToken,
  getApiBase,
} from '../../axios/tools';
import explainPic from '../../assets/卡牌.png';

const uploadURI = getApiBase() + 'youzan/admin/upload';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    Notify.error('只可以上传jpg/png图片!');
  }
  const isLt500K = file.size / 1024 < 500;
  if (!isLt500K) {
    Notify.error('图片大小必须小于 500KB!');
  }
  return isJpgOrPng && isLt500K;
}

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 20},
};

export default class cardEdit extends React.Component {
  constructor(props) {
    super(props);
    console.log('init...............', props);
    let formRef = React.createRef();

    this.state = {
      ...props,
      uploading: false,
      formRef: formRef,
      showProductCodeTips: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('next props.............', nextProps);

    this.setState({
      ...nextProps,
    });
  }

  updateActivityField(field, value) {
    let ac = this.state.activityDetail;
    ac[field] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  updateActivityBanner(field, index, type, value) {
    let ac = this.state.activityDetail;
    ac[field][index][type] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  handleChange(info, index) {
    if (info.file.status === 'uploading') {
      this.setState({uploading: true});
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      let res = info.file.response;
      if (res.code !== 0) {
        return Notify.error(res.message);
      }
      let imageUrl = res.data.image_url;
      this.updateActivityBanner('cards', index, 'image', imageUrl);
      this.setState({uploading: false});
    }
  };

  render() {
    const uploadExtra = {
      token: getToken(),
    };
    const uploadButton = (
        <div>
          {this.state.uploading ? <LoadingOutlined/> : <PlusOutlined/>}
          <div style={{marginTop: 8}}>上传</div>
        </div>
    );
    const cardSize = {
      1: '230X176',
      2: '357X268',
      3: '566X644',
      4: '566X644',
      5: '608X390',
      6: '608X390',
    };
    const cardName = {
      1: '翻牌卡牌',
      2: '明信片',
      3: '商品图片',
      4: '商品图片',
      5: 'PK助力商品',
      6: 'PK助力商品',
    };
    const cardExample = {
      1: fanfanleCardExample,
      2: catCoffeeExample,
      3: guessPriceExample,
      4: guessPriceExample,
      5: diemaomaoExample,
      6: funiuExample,
    };
    return (
        <div style={{
          position: 'relative',
        }}
        >
          {(this.state.activityDetail.activity_type === 5 || this.state.activityDetail.activity_type === 6) &&
          <div>
            <div><input type="checkbox"
                        checked={this.state.activityDetail.pk_need_buy === 1}
                        onChange={(e) => {
                          this.updateActivityField('pk_need_buy',
                              e.target.checked ? 1 : 0);
                        }}
                 />开启PK模式购买指定商品战力值+20功能
            </div>
            <div>1.开启此按钮可以增强用户购买转化,用户购买商家填写的商品即可获得20战力值。</div>
            <div>2.开启此按钮,商家需给每个参加活动的商品配置不同商品编码才能使用( <span
                style={{cursor: 'pointer'}} onClick={() => {
              this.setState({showProductCodeTips: true});
            }}
                                                    ><QuestionCircleOutlined style={{color: '#FC8C03'}}/><span>怎么设置商品编码</span></span>）
            </div>
            <div>3.最低需要设置4个商品编码,将需要参与活动的商品、链接、编码填入下方,并勾选最上方</div>
          </div>
          }
          {(this.state.activityDetail.activity_type === 5 || this.state.activityDetail.activity_type === 6) && this.state.activityDetail.pk_need_buy === 0 &&
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '90%',
            opacity: 0.5,
            background: '#fff',
            zIndex: '2',
          }}
          >&nbsp;</div>
          }

          <Form
              {...layout}
              name="cards"
              ref={this.state.formRef}
          >
            <Card style={{marginBottom: '24px'}}>
              <div style={{
                display: 'flex',
                justifyContent: 'left',
              }}
              >
                <div><img alt="" src={explainPic} /></div>
                <div style={{marginLeft: '24px'}}>
                  <div>设置建议：</div>
                  <div>1、一句话写清出产品/活动特点<br />
                    2、优质产品图片能够有更好的种草效果
                  </div>
                </div>
              </div>
            </Card>
            <div style={{
              display: 'flex',
              justifyContent: 'left',
              flexWrap: 'wrap',
            }}
            >
              {
                this.state.activityDetail.cards.map((item, index) => {
                  return (
                      <Card className="card-half-width" style={{
                        marginBottom: '24px',
                        marginLeft: index % 2 === 1 ? '24px' : '0px',
                      }} key={index}
                      >
                        <Form.Item
                            label={`链接${index + 1}：`}
                            name={`cardlink${index}`}
                        >
                          <Input
                              defaultValue={item.link}
                              onChange={(e) => {
                                console.log('onchange............');
                                this.updateActivityBanner('cards', index,
                                    'link',
                                    e.target.value);
                              }}
                          />
                          <span>请输入http://或https://开头的链接</span>
                        </Form.Item>
                        {
                          this.state.activityDetail.activity_type === 4 &&
                          <Form.Item
                              label={`名称${index + 1}：`}
                              initialValue={item.title}
                              name={`cardtitle${index}`}
                          >
                            <Input
                                defaultValue={item.title}
                                onChange={(e) => {
                                  console.log('onchange............');
                                  this.updateActivityBanner('cards', index,
                                      'title', e.target.value);
                                }}
                            />
                          </Form.Item>
                        }
                        {
                          this.state.activityDetail.activity_type === 3 &&
                          <Form.Item
                              label={`价格${index + 1}：`}
                              initialValue={item.price}
                              name={`cardprice${index}`}
                          >
                            <Input
                                defaultValue={item.price}
                                onChange={(e) => {
                                  console.log('onchange............');
                                  this.updateActivityBanner('cards', index,
                                      'price', e.target.value);
                                }}
                            />
                          </Form.Item>
                        }
                        {
                          this.state.activityDetail.activity_type === 5 &&
                          <Form.Item
                              label={`编码${index + 1}：`}
                              initialValue={item.product_id}
                              name={`product_id${index}`}
                          >
                            <Input
                                defaultValue={item.product_id}
                                onChange={(e) => {
                                  console.log('onchange............');
                                  this.updateActivityBanner('cards', index,
                                      'product_id', e.target.value);
                                }}
                            />
                          </Form.Item>
                        }
                        {
                          this.state.activityDetail.activity_type === 6 &&
                          <Form.Item
                              label={`编码${index + 1}：`}
                              initialValue={item.product_id}
                              name={`product_id${index}`}
                          >
                            <Input
                                defaultValue={item.product_id}
                                onChange={(e) => {
                                  console.log('onchange............');
                                  this.updateActivityBanner('cards', index,
                                      'product_id', e.target.value);
                                }}
                            />
                          </Form.Item>
                        }
                        <Form.Item
                            className="uploader"
                            label={`图片${index + 1}：`}
                            valuePropName="fileList"
                            style={{marginBottom: 0}}
                        >
                          <Upload
                              name="file"
                              listType="picture-card"
                              className="card-uploader"
                              showUploadList={false}
                              action={uploadURI}
                              data={uploadExtra}
                              beforeUpload={beforeUpload}
                              onChange={(info) => {
                                this.handleChange(info, index);
                              }}
                          >
                            {this.state.activityDetail.cards[index]['image'] ?
                                <img
                                    src={this.state.activityDetail.cards[index]['image']}
                                    alt="avatar" style={{width: '100%'}}
                                /> :
                                uploadButton}
                          </Upload>
                          <div className="uploader-tips">
                            * {cardSize[this.state.activityDetail.activity_type]}，≤500k
                            <br/>* 点击上传
                          </div>
                        </Form.Item>
                      </Card>);
                })
              }
              <Card>
                <div className="card-example">
                  <div
                      className="example-title"
                  >{cardName[this.state.activityDetail.activity_type]}示例
                  </div>
                  <img alt=""
                      src={cardExample[this.state.activityDetail.activity_type]}
                  />
                </div>
              </Card>
            </div>
          </Form>
          <Dialog
              visible={this.state.showProductCodeTips}
              title="如何设置商品编码"
              style={{width: '800px'}}
              onClose={(e) => this.setState({showProductCodeTips: false})}
          >
            <div style={{marginBottom: '24px'}}>
              <p style={{fontWeight: "bold", fontSize: "16px"}}>怎么设置商品编码？</p>
              <p style={{fontWeight: "bold", fontSize: "14px"}}> 一、什么是商品编码？</p>
              <p><img src={code1} width="740" alt=""/></p>
              <p style={{fontWeight: "bold", fontSize: "14px"}}> 二、如何设置商品编码</p>
              <p> 1.点击商品页中，未设置编码的商品右侧的【编辑】按钮，进入商品管理页面</p>
              <p><img src={code2} width="740" alt=""/></p>
              <p> 2.在商品管理页面>编辑基本信息中，点开折叠更多设置中，有商品编码选项</p>
              <p><img src={code3} width="740" alt=""/></p>
              <p> 3.填写商品编码，点击保存</p>
              <p><img src={code4} width="740" alt=""/></p>
              <p> 4.返回商品管理页面，查看商品列表，商品编码已经显示，叠叠猫需要给6个商品设置商品编码才可以正常发布游戏。</p>
              <p><img src={code5} width="740" alt=""/></p>
              <p style={{fontWeight: "bold", fontSize: "16px"}}> 如何在游戏中加入购买商品增加战力值按钮【增加活动复购转化】</p>
              <p> 1.在【商品配置】栏目中，勾选开启PK模式</p>
              <p><img src={code6} width="740" alt=""/></p>
              <p> 2.【链接】处填入商品购买页面链接，在【编码】处填写为此商品设定的商品编码</p>
              <p>商品配置页面》商品设置部分</p>
              <p><img src={code7} width="740" alt=""/></p>
              <p>商品购买链接处：</p>
              <p><img src={code8} width="740" alt=""/></p>
              <p>商品对应商品编码</p>
              <p><img src={code9} width="740" alt=""/></p>
            </div>
          </Dialog>
        </div>
    );
  }
}