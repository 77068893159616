/**
 * Created by 叶子 on 2017/8/13.
 */
import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import AllComponents from '../components';
import routesConfig from './config';
import queryString from 'query-string';
import {connectAlita} from 'redux-alita';

class CRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps, _) {
        this.setState({
            ...nextProps,
        });
    }
    requireAuth = (permission, component) => {
        const { auth } = this.props;
        if (!auth || !auth.data) {
            return <Redirect to={'404'} />;
        }
        const { permissions } = auth.data;
        if (!permissions || !permissions.includes(permission)) return <Redirect to={'404'} />;
        return component;
    };
    requireLogin = (component, permission) => {
        const { auth } = this.props;
        if (!auth || !auth.data) {
            return <Redirect to={'/login'} />;
        }
        const { permissions } = auth.data;
        if (!permissions) {
            // 线上环境判断是否登录
            return <Redirect to={'/login'} />;
        }
        return permission ? this.requireAuth(permission, component) : component;
    };
    updateState = (data) => {
        console.log('route update state..............', data)
        this.setState({
            ...data
        })
        this.props.updateState(data)
    }
    render() {
        let that = this
        return (
            <Switch>
                {Object.keys(routesConfig).map(key =>
                    routesConfig[key].map(r => {
                        const route = r => {
                            const Component = AllComponents[r.component];
                            return (
                                <Route
                                    key={r.route || r.key}
                                    exact
                                    path={r.route || r.key}
                                    render={props => {
                                        const reg = /\?\S*/g;
                                        // 匹配?及其以后字符串
                                        const queryParams = window.location.hash.match(reg);
                                        // 去除?的参数
                                        const { params } = props.match;
                                        Object.keys(params).forEach(key => {
                                            params[key] =
                                                params[key] && params[key].replace(reg, '');
                                        });
                                        props.match.params = { ...params };
                                        const merge = {
                                            ...that.state,
                                            ...props,
                                            updateState: that.updateState,
                                            query: queryParams
                                                ? queryString.parse(queryParams[0])
                                                : {},
                                        };
                                        // 重新包装组件
                                        const wrappedComponent = (
                                            <DocumentTitle title={r.label || r.title}>
                                                <Component {...merge} />
                                            </DocumentTitle>
                                        );
                                        return r.login
                                            ? wrappedComponent
                                            : this.requireLogin(wrappedComponent, r.auth);
                                    }}
                                />
                            );
                        };
                        return r.component ? route(r) : r.children.map(r => route(r));
                    })
                )}

                <Route render={() => <Redirect to="/404" />} />
            </Switch>
        );
    }
}
export default connectAlita(['auth'])(CRouter);
