import React, { useCallback, useState } from 'react';
import { Modal, Button, message } from 'antd';

import SelectTicket from './SelectTicket/index'
import EditTicket from './EditTicket/index'
import { useAlita } from 'redux-alita';

function CreateTicket () {
    const [ isModalVisible = false, setAlita ] = useAlita('isCreateTicket', { light: true });
    const [ next, setNext ] = useState(false);
    const [ ticketForm ] = useAlita('ticketForm', {light: true});
    const [ selectTab = '' ] = useAlita('selectTab', {light: true});

    function showModal () {
        setAlita({ stateName: "isCreateTicket", data: true});
    }
    function handleOk () {
        if (!selectTab) {
            message.warning('请选择创建类型')
            return
        }
        if (next) {
            switch (selectTab) {
                case "WechatTicket":
                    setAlita({ stateName: "activeTabKeyTicket", data: "TicketWechat"});
                    setAlita({ stateName: "isCreateTicket", data: false});
                    setAlita({stateName: 'showCreateVoucher', data: true});
                    break;
                default:
                    onSubmit()
                    break;
            }
            return
        }
        setNext(true)
    }
    function handleCancel () {
        // 初始化弹窗
        setNext(false)
        setAlita({ stateName: "isCreateTicket", data: false});
    }
    function handlePre () {
        // 初始化弹窗
        setNext(false)
    }
    // 最终保存结果
    function onSubmit () {
        ticketForm.submit()
        ticketForm.getFieldsValue()
        // axios({
        //     selectTab, // selectTab 选择的类型 导入 微信 接口
        //     ...(ticketForm.getFieldsValue())
        // })
    }

    const PrizeTabComponent = useCallback(() => {
        return (next ? <EditTicket/> : <SelectTicket/>)
    },[next])

    return (
        <>
            <Button type="primary" onClick={showModal}>
                创建券
            </Button>
            <Modal title="创建券" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>取消</Button>,
                    next ? <Button key="pre" onClick={handlePre}>上一步</Button> : '',
                    <Button key="submit" type="primary" onClick={handleOk}>{ next && selectTab !== "WechatTicket" ? "保存" : "下一步" }</Button>,
                ]}
                width={1000}
            >
                <PrizeTabComponent/>
            </Modal>
        </>
    );
}

export default CreateTicket
