/**
 * Created by 叶子 on 2017/7/30.
 * http通用工具函数
 */
import Axios from 'axios';
import { message, Modal } from 'antd';
import qs from 'qs';

let token = '';

const axios = Axios.create({
  baseURL: process.env.API_BASE_URL, // api的base_url
  timeout: 1000 * 10, // 请求超时时间
});
export const getApiBase = function() {
  return process.env.API_BASE_URL;
};
export const getUploadURI = function() {
  return getApiBase() + "youzan/admin/upload";
}
export const getToken = function() {
  return localStorage.getItem('token') || token;
};
axios.interceptors.request.use(
    config => {
      let token = getToken();
      config.method === 'post' ?
          (config.data = {
            ...config.data,
            token: token,
          }) :
          (config.params = {
            ...config.params,
            token: token,
          });
      if (config.url.indexOf('activity/create') !== -1 ||
          config.url.indexOf('activity/update') !== -1 ||
        (config.headers && config.headers['Content-Type'] === 'application/json')
      ) {
        config.headers['Content-Type'] = 'application/json';
      } else {
        config.data = qs.stringify(config.data);
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      }
      config.headers['X-Token'] = getToken();
      console.log(config);
      return config;
    },
    error => {
      Promise.reject(error);
    },
);

// 添加响应拦截器
axios.interceptors.response.use(
    response => {
      // console.log(response)
      if (response.data.code !== 0) {
        Modal.warn({ content: response.data.message, onOk: ()=>{
          if (response.data.code === 403) {
            console.log("tools.js+++++++++++++ 403 not login")
            localStorage.removeItem('user');
            localStorage.removeItem("token")
            window.location.href = "/";
          }
          } });
        // Notify.warn(response.data.message);
      }
      return response;
    },
    error => {
      console.log('TCL: error', error);
      const msg = error.Message !== undefined ? error.Message : '';
      Modal.error({ content: '网络错误：' + msg });
      return Promise.reject(error);
    },
);

/**
 * 公用get请求
 * @param url       接口地址
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const get = ({ url, msg = '接口异常', config }) =>
    axios.get(url, config).then(res => res.data).catch(err => {
      console.log(err);
      message.warn(msg);
    });

// 获取奖品发放图表数据
export async function getTotalActivityStatistics(data) {
  const res = await axios.post('youzan/admin/getTotalActivityStatistics', data);
  return res.data.data
}

// 获取奖品发放图表数据
export async function getSearchActivities(data) {
  const res = await axios.post('youzan/admin/getSearchActivities', data);
  return res.data.data
}
// 获取奖品发放图表数据
export async function getGiftStatistics(data) {
  const res = await axios.post('youzan/admin/getGiftStatistics', data);
  return res.data.data
}
// 获取Pv图表数据
export async function getPvStatistics(data) {
  const res = await axios.post('youzan/admin/getPvStatistics', data);
  return res.data.data
}
// 获取Uv图表数据
export async function getUvStatistics(data) {
  const res = await axios.post('youzan/admin/getUvStatistics', data);
  return res.data.data
}
// 获取参与活动次数图表数据
export async function getActivityCountStatistics(data) {
  const res = await axios.post('youzan/admin/getActivityCountStatistics', data);
  return res.data.data
}
// 获取裂变用户图表数据
export async function getInviteStatistics(data) {
  const res = await axios.post('youzan/admin/getInviteStatistics', data);
  return res.data.data
}
// 获取活跃用户图表数据
export async function getActiveUserStatistics(data) {
  const res = await axios.post('youzan/admin/getActiveUserStatistics', data);
  return res.data.data
}
// 获取登录时长图表数据
export async function getStayStatistics(data) {
  const res = await axios.post('youzan/admin/getStayStatistics', data);
  return res.data.data
}
// 新用户统计
export async function getNewUserStatistics(data) {
  const res = await axios.post('youzan/admin/getNewUserStatistics', data);
  return res.data.data
}

export async function getAccountInfo() {
  const res = await axios.post('youzan/admin/account');
  localStorage.setItem('user', JSON.stringify(res.data.data));
  return res.data.data || {};
}

// 获取微信授权二维码
export async function getWechatAuthQrCode() {
  const res = await axios.post('youzan/admin/wechat/getAuthUrl');
  return res.data.data;
}

// 获取充值金币二维码
export async function getChargeCoins(data) {
  const res = await axios.post('youzan/admin/order/chargeCreate', data);
  return res.data.data;
}

// 获取充值金币二维码
export async function wechatChargeCoins(data) {
  const res = await axios.post('youzan/admin/order/wechatCharge', data);
  return res.data.data;
}
// 更新微信支付参数
export async function wechatUpdateConfig(data) {
  const res = await axios.post('fenwo/wechat/updateConfig', data);
  return res.data;
}

// 获取充值订单
export async function getChargeCoinsOrder(data) {
  const res = await axios.post(`youzan/admin/order/get`, data);
  return res.data.data;
}

// 发布新活动
export async function createActivity(data) {
  let token = getToken();
  return await axios.post('youzan/admin/activity/create?token=' + token,
      data, {});
}

export async function updateActivity(SendData) {
  let token = getToken();
  return await axios.post('youzan/admin/activity/update?token=' + token,
      SendData);
}

export async function getMessages() {
  const res = await axios.post('youzan/admin/index/marquee');
  return res.data.data;
}

export async function getIndexAdImgs() {
  const res = await axios.post('youzan/admin/index/ad');
  return res.data.data;
}

export async function getCoinsProductList() {
  console.log('getCoinsProductList.....................');
  const res = await axios.post('youzan/admin/order/chargeProductList');
  return res.data.data;
}

export async function getList(
    activityType,
    page,
) {
  const res = await axios.post(
      'youzan/admin/activities?activityType=' + activityType +
      '&page=' + page,
      {
        activityType: activityType,
        page: page,
      },
  );
  // commit("list", res.data.data.lists);
  return res.data.data;
}
export async function getProductList(
    page,
) {
  const res = await axios.post(
      'youzan/admin/products?page=' + page,
      {
        page: page,
      },
  );
  // commit("list", res.data.data.lists);
  return res.data.data;
}
export async function getVouchers(
    page, state
) {
  const res = await axios.get(
      'fenwo/wechat/vouchers?page=' + page + "&state=" + state,
  );
  // commit("list", res.data.data.lists);
  return res.data.data;
}
// 获取有赞优惠券
export async function getCouponsUnfinished() {
  const res = await axios.post('youzan/admin/coupon/unfinished');
  return res.data.data;
}
export async function getActivityReport(
    activityID,
) {
  let token = getToken();
  window.location.href = `${process.env.API_BASE_URL}youzan/admin/activity/report?activityID=${activityID}&token=${token}`;
}

export async function getActivity(activityID) {
  let token = getToken();
  const res = await axios.post(
      `youzan/admin/activity/get?activityID=${activityID}&token=${token}`, {
        activityID: activityID,
      });
  return res.data.data;
}

export async function stopActivity(activityID) {
  const res = await axios.post(`youzan/admin/activity/stop`, {
    activityID: activityID,
  });
  return res.data;
}

// 解绑服务号
export async function removeWechatAuth() {
  const res = await axios.post(`youzan/admin/wechat/remove`);
  return res.data;
}

export async function getArticle(articleID) {
  const res = await axios.get(`/article/getArticle?articleID=${articleID}`);
  return res.data;
}

export async function login(params) {
  const res = await axios.post(`/fenwo/login`, params);
  token = res.data.data
  console.log(token);
  localStorage.setItem('token', token);
  return res.data;
}

export async function register(params) {
  const res = await axios.post(`/fenwo/register`, params);
  token = res.data.data
  console.log(token);
  localStorage.setItem('token', token);
  return res.data;
}

export async function permission(params) {
  const res = await axios.post(`/fenwo/permission`, params);
  const u = res.data.data || {};
  localStorage.setItem('user', JSON.stringify(u));
  return u;
}

export async function productList(params) {
  const res = await axios.post(`/youzan/admin/product/list`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function productAdd(product) {
  const res = await axios.post(`/youzan/admin/product/add`, product, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function productUpdate(product) {
  const res = await axios.post(`/youzan/admin/product/update`, product, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function productDetail(product) {
  const res = await axios.post(`/youzan/admin/product/get`, product, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function shipments(params) {
  const res = await axios.post(`/youzan/admin/product/getOfflineRewardsForShipping`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function shipmentsAddress(params) {
  const res = await axios.post(`/youzan/admin/product/getShipAddress`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function ship(params) {
  const res = await axios.post(`/youzan/admin/product/ship`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function usersList(params) {
  const res = await axios.post(`/youzan/admin/user/list`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function tagGroups(params) {
  const res = await axios.post(`/youzan/admin/tag_group/list`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function tagGroupAdd(params) {
  const res = await axios.post(`/youzan/admin/tag_group/add`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function tagAdd(params) {
  const res = await axios.post(`/youzan/admin/tag/add`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function tagGroupUpdate(params) {
  const res = await axios.post(`/youzan/admin/tag_group/update`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function tagGroupDelete(params) {
  const res = await axios.post(`/youzan/admin/tag_group/delete`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function getUserByOpenID(params) {
  const res = await axios.post(`/youzan/admin/user/getByOpenID`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function getUserJoinedActivities(params) {
  const res = await axios.post(`/youzan/admin/user/activities`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function getRelations(params) {
  const res = await axios.post(`/youzan/admin/user/relations`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function getUserActivityTypeTag(params) {
  const res = await axios.post(`/youzan/admin/user_tag/activities`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function getUserTags(params) {
  const res = await axios.post(`/youzan/admin/user_tag/list`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function updateUserTags(params) {
  const res = await axios.post(`/youzan/admin/user_tag/update`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function linkList(params) {
  const res = await axios.post(`/youzan/admin/link/list`, params, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function linkAdd(product) {
  const res = await axios.post(`/youzan/admin/link/add`, product, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function linkUpdate(product) {
  const res = await axios.post(`/youzan/admin/link/update`, product, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

export async function linkDetail(product) {
  const res = await axios.post(`/youzan/admin/link/get`, product, {
    headers: {'Content-Type': 'application/json'}
  });
  return res.data;
}

