//引入react jsx写法的必须
import React from 'react';
import {Form, Input, Upload, DatePicker, Select, Button} from 'antd';

import {
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import {Dialog, Notify} from 'zent';

import moment from 'moment';
import {
  getToken,
  getApiBase,
} from '../../axios/tools';
import Modal from 'antd/es/modal';

moment.locale('zh-cn'); // zh-cn
const {Option} = Select;
const {TextArea} = Input;

const uploadURI = getApiBase() + 'youzan/admin/upload';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    Notify.error('只可以上传jpg/png图片!');
  }
  const isLt500K = file.size / 1024 < 500;
  if (!isLt500K) {
    Notify.error('图片大小必须小于 500KB!');
  }
  return isJpgOrPng && isLt500K;
}

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 20},
};

function Repeat(props) {
  let items = [];
  console.log(props.numTimes);
  for (let i = 0; i < props.numTimes; i++) {
    console.log(i);
    items.push(props.children(i));
  }
  return <div>{items}</div>;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && (current < moment() || current > moment().add(90, 'days'));
}

export default class BasicInfoEdit extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    let formRef = React.createRef();
    let m = moment();
    if (props.activityDetail.end_time) {
      m = moment(props.activityDetail.end_time);
    }
    this.state = {
      ...props,
      uploading: false,
      formRef: formRef,
      endTimePicker: m,
      showRewardTypeTips: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    let m = moment();
    if (nextProps.activityDetail.end_time) {
      m = moment(nextProps.activityDetail.end_time);
      console.log(m);
    }

    nextProps.activityDetail.endTimePicker = m;
    this.setState({
      ...nextProps,
      endTimePicker: m,
    });
    // this.state.formRef.current.setFieldsValue({
    //   ...nextProps.activityDetail,
    // });
  }

  updateActivityField(field, value) {
    if (field === "end_time") {
      value = moment(value).format("YYYY-MM-DDTHH:mm:ssZ")
    }
    let ac = this.state.activityDetail;
    ac[field] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  updateActivityWorldRankRewards(field, index, type, value) {
    let ac = this.state.activityDetail;
    ac[field][index][type] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  handleChange(info) {
    if (info.file.status === 'uploading') {
      this.setState({uploading: true});
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      let res = info.file.response;
      if (res.code !== 0) {
        return Notify.error(res.message);
      }
      let imageUrl = res.data.image_url;
      this.updateActivityField('qrcode', imageUrl);
      this.setState({uploading: false});
    }
  };

  render() {
    const uploadExtra = {
      token: getToken(),
    };

    const uploadButton = (
        <div>
          {this.state.uploading ? <LoadingOutlined/> : <PlusOutlined/>}
          <div style={{marginTop: 8}}>上传</div>
        </div>
    );
    return (
        <Form
            {...layout}
            name="basic"
            ref={this.state.formRef}
            initialValues={this.state.activityDetail}
        >
          <Form.Item
              label="活动名称："
              name="title"
              initialValue={this.state.activityDetail.title}
          >
            <Input
                defaultValue={this.state.activityDetail.title}
                style={{width: '480px'}}
                onChange={(e) => {
                  console.log('onchange............');
                  this.updateActivityField('title', e.target.value);
                }}
                onBlur={(e) => {
                  console.log('onblur............');
                  this.updateActivityField('title', e.target.value);
                }}
            />
          </Form.Item>
          <Form.Item
              label="结束时间："
              name="endTimePicker"
              value={this.state.endTimePicker}
              initialValue={this.state.endTimePicker}
          >
            <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime
                defaultValue={this.state.endTimePicker}
                disabledDate={disabledDate}
                onChange={(d, ds) => {
                  this.updateActivityField('end_time', ds);
                }}
            />
          </Form.Item>
          <Form.Item
              label="公众号二维码："
              className="uploader"
              valuePropName="fileList"
          >
            <Upload
                name="file"
                listType="picture-card"
                className="qrcode-uploader"
                showUploadList={false}
                action={uploadURI}
                data={uploadExtra}
                beforeUpload={beforeUpload}
                onChange={(info) => {
                  this.handleChange(info);
                }}
            >
              {this.state.activityDetail.qrcode ?
                  <img src={this.state.activityDetail.qrcode} alt="avatar"
                       style={{width: '100%'}}
                  /> :
                  uploadButton}
            </Upload>
            <div className="uploader-tips">
              * 公众号二维码(258x258，≤500k)
              <br/>* 拖曳文件或点击上传
            </div>
          </Form.Item>
          <Form.Item
              label="活动规则："
              name="rule_link"
              initialValue={this.state.activityDetail.rule_link}
          >
            <TextArea
                rows="4"
                style={{width: '680px'}}
                defaultValue={this.state.activityDetail.rule_link}
                onChange={(e) => {
                  console.log('onchange............');
                  this.updateActivityField('rule_link', e.target.value);
                }}
                onBlur={(e) => {
                  console.log('onblur............');
                  this.updateActivityField('rule_link', e.target.value);
                }}
            />
          </Form.Item>

          <Form.Item
              label="奖励结算方式："
              initialValue={this.state.activityDetail.need_exchange_coins}
          >
            <Select
                defaultValue={this.state.activityDetail.need_exchange_coins}
                style={{width: 240}} onChange={
              (v) => {
                this.updateActivityField('need_exchange_coins', v);
              }
            }
            >
              <Option value="0">线下结算</Option>
              {/*<Option value="1">积分兑换</Option>*/}
              <Option value="2">
                优惠券发放
              </Option>
            </Select>
            <span style={{cursor: 'pointer'}} onClick={() => {
              this.setState({showRewardTypeTips: true});
            }}
            >
              <QuestionCircleOutlined
                  style={{color: '#FC8C03', paddingLeft: '14px'}}
              /><span
                style={{}}
                >奖励结算方式</span>
            </span>
          </Form.Item>
          {
            this.state.activityDetail.need_exchange_coins === '1' &&
            <Form.Item
                label="积分兑换比例："
                initialValue={this.state.activityDetail.exchange_ratio}
            >
              每积分&nbsp;=&nbsp;
              <Input
                  defaultValue={this.state.activityDetail.exchange_ratio}
                  style={{width: '120px'}}
                  onChange={(e) => {
                    console.log('onchange............');
                    this.updateActivityField('exchange_ratio', e.target.value);
                  }}
                  onBlur={(e) => {
                    console.log('onblur............');
                    this.updateActivityField('exchange_ratio', e.target.value);
                  }}
              />&nbsp;金币&nbsp;（请输入大于0的整数）
            </Form.Item>
          }
          {
            this.state.activityDetail.need_exchange_coins === '2' &&
            <>
              <Repeat numTimes={this.state.activityDetail.max_rank}>
                {(index) => <div key={index}><Form.Item
                    label={`第${index + 1}名优惠券：`}
                    name={`worldRankRewards${index}`}
                                             >
                  <Input
                      defaultValue={this.state.activityDetail.world_rank_rewards[index + 1]['link']}
                      onChange={(e) => {
                        console.log('onchange............');
                        this.updateActivityWorldRankRewards(
                            'world_rank_rewards',
                            index + 1,
                            'link', e.target.value);
                      }}
                  />
                  <span>请输入http://或https://开头的链接</span>
                </Form.Item></div>}
              </Repeat>
              <Form.Item>
                <Button type="primary" icon="plus" onClick={() => {
                  let worldRankRewards = this.state.activityDetail.world_rank_rewards;
                  worldRankRewards[this.state.activityDetail.max_rank + 1] = {
                    link: '',
                  };

                  this.updateActivityField('world_rank_rewards',
                      worldRankRewards);
                  this.updateActivityField('max_rank',
                      this.state.activityDetail.max_rank + 1);
                }}
                >设置第{this.state.activityDetail.max_rank + 1}名优惠券</Button>
                <Button style={{marginLeft:"16px"}} type="danger" icon="close" onClick={() => {
                  if (this.state.activityDetail.max_rank <= 3) {
                    Modal.info({content: '至少设置前3名奖励'});
                    return;
                  }
                  let worldRankRewards = this.state.activityDetail.world_rank_rewards;
                  delete worldRankRewards[this.state.activityDetail.max_rank];

                  this.updateActivityField('world_rank_rewards',
                      worldRankRewards);
                  this.updateActivityField('max_rank',
                      this.state.activityDetail.max_rank - 1);
                }}
                >删除第{this.state.activityDetail.max_rank}优惠券</Button>
              </Form.Item>
              <Form.Item
                  label="阳光普照优惠券："
                  name="worldRankRewards50+"
                  initialValue={this.state.activityDetail.world_rank_rewards[-1]['link']}
              >
                <Input
                    defaultValue={this.state.activityDetail.world_rank_rewards[-1]['link']}
                    onChange={(e) => {
                      console.log('onchange............');
                      this.updateActivityWorldRankRewards('world_rank_rewards',
                          -1,
                          'link', e.target.value);
                    }}
                />
              </Form.Item>
            </>
          }
          <Dialog
              visible={this.state.showRewardTypeTips}
              title="奖励结算方式的区别"
              style={{width: '380px'}}
              onClose={(e) => this.setState({showRewardTypeTips: false})}
          >
            <div style={{marginBottom: '24px'}}>
              <p>线下结算</p>
              <p style={{color: 'red'}}>【商家需要在游戏规则中补充：获奖用户需要主动填写手机号，否则视为放弃奖品】</p>
              <p>活动结束后，活动会弹框提示用户填写手机号。商家根据排行榜以及用户填写的手机号自行联系用户，发放奖励。</p>
              <p>优惠券发放</p>
              <p style={{color: 'red'}}>【优惠券会比涉及到资金或货物，商家设置请一定要设置门槛和领取次数，否则造成的不良后果由商家自行承担】</p>
              <p> 活动在发出前，需要先设置好优惠券，并且把优惠券链接填写到对应的名次中。活动结束后，会根据金币排行榜自动的对对应名次的用户发放优惠券。建议优惠券设置成有门槛优惠券，并且设置可领取次数，以免被用户领爆。</p>

              <p>备注：</p>
              <p>所有奖品以及奖品如何发放，建议商家在游戏规则中进行添加，用于展示给用户。</p>

            </div>
          </Dialog>
        </Form>
    );
  }
}