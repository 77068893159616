import React, {useEffect, useState} from 'react';
import {
  PageHeader,
  Card,
  Button,
  Form,
  Input,
  InputNumber,
  Table,
  Space, Modal,
} from 'antd';
import 'antd/dist/antd.css';
import './index.scss';
import {productList} from '../../../axios/tools';
import CreatePhysical from './CreatePhysical';

const data = [];
function Physical(props) {
  const [form] = Form.useForm();
  const [tableList, setTableList] = useState([]);
  const [page, setPage] = useState({pageSize: 20, current: 1, total: 0});
  const [showCreate, setShowCreate] = useState(false);
  const [editProductID, setEditProductID] = useState("");
  const columns = [
    {
      title: '奖品id',
      dataIndex: 'product_id',
      key: 'product_id',
    },
    {
      title: '奖品名称',
      dataIndex: 'product_title',
      key: 'product_title',
    },
    {
      title: '奖品库存',
      dataIndex: 'stock',
      key: 'stock',
    },
    {
      title: '原价(￥)',
      dataIndex: 'price',
      key: 'price',
      render: (text) => (
          <span>{text/100}</span>
      )
    },
    {
      title: '创建日期',
      dataIndex: 'created_at_format',
      key: 'created_at_format',
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => onEditPhysical(record)}>编辑</Button>
          {
            props.chooseDialog &&
            <Button disabled={props.chosenProductID===record.product_id} type="primary" onClick={() => props.chooseCallback(record)}>{props.chosenProductID === record.product_id?'已选':'选择'}</Button>
          }
        </Space>
      ),
    },
  ];

  useEffect(() => {
    productList({
      page: 1,
      pageSize: 20,
    }).then(res => {
      if (res.code === 0) {
        setTableList(res.data.items)
        setPage({
          current: res.data.page * 1,
          pageSize: res.data.page_size * 1,
          total: res.data.total * 1
        });
      }
    });
  }, []);

  function onEditPhysical(record) {
    setEditProductID(record.product_id);
    setShowCreate(true);
  }

  function onReset() {
    setTableList(data);
    form.resetFields();
  }

  function onCreatePhysical() {
    setEditProductID("");
    setShowCreate(true);
  }

  function onTableChange(p) {
    setPage(p.pagination)
    productList({
      page: page.current,
      pageSize: page.pageSize,
      ...form.getFieldsValue(true)
    }).then(res => {
      if (res.code === 0) {
        setTableList(res.data.items)
      }
    });
  }
  function onFinish() { // search
    setPage({
      current: 1,
      pageSize: 20,
      total: 0,
    })
    productList({
      page: 1,
      pageSize: 20,
      ...form.getFieldsValue(true)
    }).then(res => {
      if (res.code === 0) {
        setTableList(res.data.items)
        setPage({
          current: 1,
          pageSize: res.data.page_size * 1,
          total: res.data.total
        })
      }
    });
  }

  return (<div className="physical">
    <PageHeader title={props.chooseDialog ? '' : '实物奖品'} className="site-page-header">
      <Form form={form} onFinish={onFinish}>
        <div className="physical_flex">
          <div className="physical_flex_left physical_flex">
            <Form.Item name="product_title" label="奖品名称">
              <Input placeholder="请输入奖品名称"/>
            </Form.Item>
            <Form.Item label="库存范围">
              <div className="physical_flex">
                <Form.Item name='min_stock'>
                  <InputNumber placeholder="最少"/>
                </Form.Item>
                <Form.Item>至</Form.Item>
                <Form.Item name='max_stock'>
                  <InputNumber placeholder="最多"/>
                </Form.Item>
              </div>
            </Form.Item>
          </div>
          <div className="physical_flex_right">
            <Form.Item>
              <Button type="primary" htmlType="submit">搜索</Button>
              <Button htmlType="button" onClick={onReset}>重置</Button>
              <Button type="primary" onClick={onCreatePhysical}>新建奖品</Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </PageHeader>
    <Card>
      <Table rowKey="product_id" columns={columns} dataSource={tableList} pagination={page} onChange={onTableChange}/>
    </Card>
    <Modal
        visible={showCreate}
        title="新建/编辑实物奖品"
        width={1200}
        onCancel={() => setShowCreate(false)}
        onOk={() => setShowCreate(false)}
        footer={[
        ]}
    >
      {
        showCreate &&
        <CreatePhysical productID={editProductID} success={() => {
          setShowCreate(false);
          onReset();
          onFinish();
        }}
        />
      }
    </Modal>
  </div>);
}

export default Physical;
