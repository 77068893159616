import "core-js"
import 'core-js/es'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Page from './Page';
import * as apis from './axios';
import { AlitaProvider, setConfig } from 'redux-alita';
import { ConfigProvider } from 'antd'
import locale from 'antd/es/locale/zh_CN';
// 引入样式
import 'zent/css/index.css';
import 'antd/dist/antd.css';
import './style/lib/animate.css';
import './style/antd/index.less';
import './style/index.less';
import 'moment/locale/zh-cn';

setConfig(apis);

ReactDOM.render(
    // <AppContainer>
    <ConfigProvider locale={locale}>
        <AlitaProvider>
            <Page />
        </AlitaProvider>
    </ConfigProvider>,
    // </AppContainer>
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
