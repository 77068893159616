import React, { useState, useEffect} from 'react'
import {Row, Col, Tag, PageHeader, Input} from 'antd';
import './index.scss'
import { useAlita } from 'redux-alita'

import TagAddGroupDialog from '../TagAddGropDialog/index'
import TagAddDialog from '../TagAddDialog/index'
import {tagAdd} from '../../../../axios/tools';

const TagItem = (props) => {
    const [item] = useState(props.item ? props.item : {})
    const [userTags = [], setAlita] = useAlita('userTags', { light: true })
    const [showInputNewTag, setShowInputNewTag] = useState(false);
    const [newTagName, setNewTagName] = useState("");

    useEffect(() => {
    }, [])

    function onUserAddTag (item, e) {
        if (props.type !== 'add') {
            return
        }
        let exist = false;
        let tagsTemp = userTags;
        for (let i = 0; i < tagsTemp.length; i++) {
            if (tagsTemp[i] === e) {
                exist = true;
                tagsTemp.splice(i, 1)
                break;
            }
        }
        if (!exist) {
            setAlita('userTags', [...userTags, e])
        } else {
            setAlita('userTags', [...tagsTemp])
            console.log("onUserAddTag+++++++++++++++",tagsTemp, userTags);
        }
    }
    function saveNewTag() {
        tagAdd({
            group_id: props.item.group_id,
            tag_name: newTagName,
        }).then(res => {
            if (res.code === 0) {
                props.refresh();
            }
        })
    }
    return (<div className="tag_item" key={item.group_id}>
        <Row gutter={24}>
            <Col span={4}>
                <PageHeader className="site-page-header" title={item.group_name}>
                    <div>共{item.tags ? item.tags.length : 0}个标签</div>
                </PageHeader>
            </Col>
            <Col span={20}>
                <PageHeader className="site-page-header" subTitle={item.root}
                    extra={[ props.type !== 'add' && props.type !== "userList" ? <TagAddGroupDialog refresh={props.refresh} key="button" text="编辑" item={item}/> : '']}
                >
                    <div>
                        <Tag className="site-tag-plus" visible={!showInputNewTag && props.type !== 'add' && props.type !== "userList"} onClick={e => {
                            setShowInputNewTag(true)
                        }}
                        >+新标签</Tag>
                        {showInputNewTag &&

                        <Input type="text"
                               size="small"
                               style={{width: "78px",marginRight:"8px"}}
                               onPressEnter={() => {
                                   saveNewTag();
                               }}
                               onBlur={() => {
                                   saveNewTag()
                               }}
                               onChange={e => {
                                   setNewTagName(e.target.value)
                               }}

                        />
                        }
                        {
                            item.tags && item.tags.map((e, index) => {
                                console.log("userTags.....................",userTags);
                                return (
                                    props.type === 'edit' ?
                                    <TagAddDialog key={'tag' + index} group={item} text={e.tag_name} item={e} type="edit"/> :
                                    <Tag key={'tag' + index} color={userTags.includes(e.tag_id) && props.type === "add" ? '#108ee9' : ''} className={`site-tag-plus`} onClick={() => onUserAddTag(item, e.tag_id)}>{e.tag_name}</Tag>
                                )
                            })
                        }
                    </div>
                </PageHeader>
            </Col>
        </Row>
    </div>)
}
export default TagItem
