//引入react jsx写法的必须
import React from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons"

import moment from 'moment';

moment.locale('zh-cn'); // zh-cn

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export default class CoinsAndNoticeEdit extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    let formRef = React.createRef();
    let m = moment();
    if (props.activityDetail.end_time) {
      m = moment(props.activityDetail.end_time);
    }
    this.state = {
      ...props,
      uploading: false,
      formRef: formRef,
      endTimePicker: m,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    let m = moment();
    if (nextProps.activityDetail.end_time) {
      m = moment(nextProps.activityDetail.end_time);
      console.log(m);
    }

    nextProps.activityDetail.endTimePicker = m;
    this.setState({
      ...nextProps,
      endTimePicker: m,
    });
    // this.state.formRef.current.setFieldsValue({
    //   ...nextProps.activityDetail,
    // });
  }

  updateActivityField(field, value) {
    let ac = this.state.activityDetail;
    ac[field] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  render() {
    const defaultPowerName = {
      1: "默认体力:",
      5:"默认银币:",
      6:"默认银币:",
    }
    return (
        <Form
            {...layout}
            name="coins-and-score"
            ref={this.state.formRef}
        >
          <Form.Item
              label="本期金币总数："
              name="titleCoins"
          >
            <span>{this.state.activityDetail.total_coins}</span>
          </Form.Item>
          <Form.Item
              label="本期剩余金币："
              name="coins"
          >
            <span>{this.state.activityDetail.coins}</span>
          </Form.Item>
          <Form.Item
              label="金币待新增："
              initialValue={this.state.activityDetail.add_coins}
          >
            <Button type="primary" onClick={() => {
              let coinsAdd = this.state.activityDetail.add_coins;
              if (!coinsAdd) {
                coinsAdd = 0;
              }
              coinsAdd += 5000;
              this.updateActivityField('add_coins', coinsAdd);
            }}
            ><PlusCircleOutlined/></Button>
            <span>&nbsp;{this.state.activityDetail.add_coins || 0}&nbsp;</span>
            <Button type="primary" onClick={() => {
              let coinsAdd = this.state.activityDetail.add_coins;
              if (coinsAdd <= 5000) {
                coinsAdd = 0;
              } else {
                coinsAdd -= 5000;
              }
              this.updateActivityField('add_coins', coinsAdd);
            }}
            ><MinusCircleOutlined/></Button>
          </Form.Item>
          <Form.Item
              label={defaultPowerName[this.state.activityDetail.activity_type]}
              name="defaultPower"
              initialValue={this.state.activityDetail.default_power}
          >
            <Input
                defaultValue={this.state.activityDetail.default_power}
                style={{ width: '480px' }}
                onChange={(e) => {
                  console.log('onchange............');
                  this.updateActivityField('default_power', e.target.value);
                }}
                onBlur={(e) => {
                  console.log('onblur............');
                  this.updateActivityField('default_power', e.target.value);
                }}
            />
          </Form.Item>
          <Form.Item
              label="金币不足通知："
              name="need_coins_notice"
          >
            <Checkbox
                checked={this.state.activityDetail.need_coins_notice === 1}
                name="need_coins_notice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_coins_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            />
          </Form.Item>
          {
            this.state.activityDetail.need_coins_notice === 1 &&
            <Form.Item
                label="通知手机号："
                name="notifyMobile"
                initialValue={this.state.activityDetail.notify_mobile}
            >
              <Input
                  defaultValue={this.state.activityDetail.notify_mobile}
                  style={{ width: '120px' }}
                  onChange={(e) => {
                    console.log('onchange............');
                    this.updateActivityField('notify_mobile', e.target.value);
                  }}
                  onBlur={(e) => {
                    console.log('onblur............');
                    this.updateActivityField('notify_mobile', e.target.value);
                  }}
              />
            </Form.Item>
          }
          <Form.Item label="模板消息通知">
            <Checkbox
                checked={this.state.activityDetail.need_twenty_clock_notice ===
                1}
                name="needTwentyClockNotice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_twenty_clock_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >每日20点任务刷新通知</Checkbox>
            <Checkbox
                checked={this.state.activityDetail.need_follow_wechat_notice ===
                1}
                name="needFollowWechatNotice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_follow_wechat_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >关注公众号通知</Checkbox>
            <Checkbox
                checked={this.state.activityDetail.need_new_friends_notice ===
                1}
                name="needNewFriendsNotice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_new_friends_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >获得新的好友通知</Checkbox>
            <Checkbox
                checked={this.state.activityDetail.need_game_end_notice === 1}
                name="needGameEndNotice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_game_end_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >活动结束通知</Checkbox>
            {this.state.activityDetail.activity_type === 1 &&
            <Checkbox
                checked={this.state.activityDetail.need_battle_overstep_notice ===
                1}
                name="battleOverstepNotice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_battle_overstep_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >连击记录被超越通知</Checkbox>
            }
            {this.state.activityDetail.activity_type === 1 &&
            <Checkbox
                checked={this.state.activityDetail.need_battle_calc_notice ===
                1}
                name="battleCalcNotice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_battle_calc_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >连击记录奖励结算通知</Checkbox>
            }
            {this.state.activityDetail.activity_type === 2 &&
            <Checkbox
                checked={this.state.activityDetail.need_first_cat_notice === 1}
                name="needFirstCatNotice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_first_cat_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >首次找到猫通知</Checkbox>
            }
            {this.state.activityDetail.activity_type === 2 &&
            <Checkbox
                checked={this.state.activityDetail.need_tomorrow_task_finish_notice ===
                1}
                name="need_tomorrow_task_finish_notice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_tomorrow_task_finish_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >预定任务倒计时结束-任务完成</Checkbox>
            }
            {this.state.activityDetail.activity_type === 2 &&
            <Checkbox
                checked={this.state.activityDetail.need_tomorrow_task_end_notice ===
                1}
                name="need_tomorrow_task_end_notice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_tomorrow_task_end_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >线索加速时间结束</Checkbox>
            }
            {this.state.activityDetail.activity_type === 2 &&
            <Checkbox
                checked={this.state.activityDetail.need_tomorrow_task_will_fail_notice ===
                1}
                name="need_tomorrow_task_will_fail_notice"
                onChange={
                  (e) => {
                    this.updateActivityField(
                        'need_tomorrow_task_will_fail_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >预定任务倒计时还差一小时结束-任务未完成</Checkbox>
            }
            {this.state.activityDetail.activity_type === 5 &&
            <Checkbox
                checked={this.state.activityDetail.need_dmm_rewards_full_notice ===
                1}
                name="need_dmm_rewards_full_notice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_dmm_rewards_full_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >挂机收益上限值满额</Checkbox>
            }
            {this.state.activityDetail.activity_type === 5 &&
            <Checkbox
                checked={this.state.activityDetail.need_dmm_pk_end_notice === 1}
                name="need_dmm_pk_end_notice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_dmm_pk_end_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >PK赛结束</Checkbox>
            }
            {this.state.activityDetail.activity_type === 5 &&
            <Checkbox
                checked={this.state.activityDetail.need_eight_clock_notice ===
                1}
                name="need_eight_clock_notice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_eight_clock_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >PK赛开始</Checkbox>
            }
            {this.state.activityDetail.activity_type === 6 &&
            <Checkbox
                checked={this.state.activityDetail.need_dmm_rewards_full_notice ===
                1}
                name="need_dmm_rewards_full_notice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_dmm_rewards_full_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >挂机收益上限值满额</Checkbox>
            }
            {this.state.activityDetail.activity_type === 6 &&
            <Checkbox
                checked={this.state.activityDetail.need_dmm_pk_end_notice === 1}
                name="need_dmm_pk_end_notice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_dmm_pk_end_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >PK赛结束</Checkbox>
            }
            {this.state.activityDetail.activity_type === 6 &&
            <Checkbox
                checked={this.state.activityDetail.need_eight_clock_notice ===
                1}
                name="need_eight_clock_notice"
                onChange={
                  (e) => {
                    this.updateActivityField('need_eight_clock_notice',
                        e.target.checked ? 1 : 0);
                  }
                }
            >PK赛开始</Checkbox>
            }
          </Form.Item>
        </Form>
    );
  }
}