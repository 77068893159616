/**
 * Created by hao.cheng on 2017/4/16.
 */
import React from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { PwaInstaller } from '../components/widget';
import { connectAlita } from 'redux-alita';
import {permission, register} from '../axios/tools';
import {AppleFilled, LockFilled} from '@ant-design/icons';

const FormItem = Form.Item;

class Register extends React.Component {
    componentDidMount() {
        const { setAlitaState } = this.props;
        setAlitaState({ stateName: 'auth', data: null });
    }
    componentDidUpdate(prevProps) { // React 16.3+弃用componentWillReceiveProps
        const { auth: nextAuth = {}, history } = this.props;
        // const { history } = this.props;
        if (nextAuth.data && nextAuth.data.uid) { // 判断是否登陆
            localStorage.setItem('user', JSON.stringify(nextAuth.data));
            history.push('/');
        }
    }
    form = React.createRef()
    handleSubmit = (e) => {
        e.preventDefault();
        let that = this;
        this.form.current.validateFields().then((values) => {
                console.log('Received values of form: ', values);
                const { setAlitaState } = this.props;
                register({
                    username: values.userName,
                    password: values.password,
                    merchantName: values.merchantName,
                }).then((loginRet) => {
                    if (loginRet.code === 0) {
                        Modal.info({
                            title: "注册成功",
                            content: "注册成功",
                        })
                        localStorage.setItem("token", loginRet.data)
                        permission().then((u)=>{
                            setTimeout(function(){
                                setAlitaState({stateName: "token", data: loginRet.data})
                                setAlitaState({stateName: 'auth', data: u});
                                that.props.history.push("/login")
                            }, 3000)
                        })
                    } else {
                        Modal.info({
                            title: "注册失败",
                            content: loginRet.message,
                        })
                    }
                });
        });
    };
    render() {
        console.log(this.form)
        return (
            <div className="login">
                <div className="login-form" >
                    <div className="login-logo">
                        <span>游戏营销平台</span>
                        <PwaInstaller />
                    </div>
                    <Form style={{maxWidth: '300px'}} ref={this.form}>
                        <FormItem rules={[{ required: true, message: '请输入商户名!' }]} name={'merchantName'}>
                            <Input prefix={<AppleFilled style={{ fontSize: 13 }} />} placeholder="请输入商户名，长度5-100字符" />
                        </FormItem>
                        <FormItem name={'userName'} rules={[{ required: true, message: '请输入用户名!' }]}>
                            <Input prefix={<AppleFilled style={{ fontSize: 13 }} />} placeholder="请输入用户名，长度5-20字符" />
                        </FormItem>
                        <FormItem name={'password'} rules={[{ required: true, message: '请输入密码!' }]}>
                            <Input prefix={<LockFilled style={{ fontSize: 13 }} />} type="password" placeholder="请输入密码，长度6-20位" />
                        </FormItem>
                        <FormItem>
                            <Button type="primary" onClick={(e)=>{
                                this.handleSubmit(e)
                            }} className="login-form-button" style={{width: '100%'}}
                            >
                                注册
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
        );
    }
}

export default connectAlita(['auth'])(Register);
