import React, {useState} from 'react';
import {Card, Button, Divider, Typography, Input, Form, Modal} from 'antd';
import './index.scss'
import Link from '../../../link';
function EnvironmentModule (props) {
    const [showChooseLink, setShowChooseLink] = useState(false);
    const [linkType, setLinkType] = useState("")
    function onJump () {
        window.open('https://mp.weixin.qq.com/wxopen/plugindevdoc?appid=wx8e0f3ffa85ee9967&token=&lang=zh_CN')
    }
    function onGoBack () {
        props.updateState({
            pathName: 'basicModule'
        })
    }
    function chooseLink(row) {
        let ac = props.activityDetail;
        if (!ac["extra_config"]) {
            ac["extra_config"] = {};
        }
        ac["extra_config"][linkType] = row;
        props.updateState({
            activityDetail: ac,
        })
    }
    return (<>
        <Card title="对接配置">
            <div className="environment_module_box">
                <header>
                    <span>微信小程序添加插件</span>
                    <span className="environment_module_detail" onClick={onJump}>详细信息</span>
                </header>
                <main>
                    <div>本活动以小程序插件形式配置，您需要发布活动前，请前往微信小程序管理后台
                    “<span>设置-第三方服务-插件管理</span>”
                    中添加插件</div>
                    <br/>
                    <div>开发者登录小程序管理后台，通过appid：wx8e0f3ffa85ee9967查找并添加营销插件。</div>
                    <div>开发者登录小程序管理后台，通过appid：wxf3f436ba9bd4be7b查找并添加小程序发券插件。</div>
                </main>
            </div>
            <div className="environment_module_box">
                <header>
                    <span>引入插件代码包</span>
                </header>
                <main>
                    使用插件前，使用者要在app.json中声明需要使用的插件
                </main>
            </div>
            <div className="environment_module_box">
                <header>
                    <span>申明插件功能</span>
                </header>
                <main>
                    由于插件需要按获取用户授权昵称、头像位置等隐私设置信息，需要小程序开发者申明插件功能页开启
                </main>
            </div>
            <Divider/>
            <Typography>
                <Typography.Title level={2}>“回商城”跳转地址</Typography.Title>
                <Typography.Paragraph>用户在活动页面，可以点击回商城按钮跳转到此处指定地址</Typography.Paragraph>
                <Typography.Paragraph>
                    <Form.Item>
                        <div style={{display:"flex"}}>
                            <Form.Item name={["extra_config", "home_link", "mini_path"]}>
                                <Input onClick={() => {
                                    setLinkType("home_link");
                                    setShowChooseLink(true);
                                }} placeholder="请选择地址"
                                />
                            </Form.Item>
                            <Button type="primary" style={{marginLeft:"16px"}} onClick={() => {
                                setLinkType("home_link");
                                setShowChooseLink(true);
                            }}
                            >选择地址</Button>
                        </div>
                    </Form.Item>
                </Typography.Paragraph>
            </Typography>
            <Typography>
                <Typography.Title level={2}>“领券”去使用地址</Typography.Title>
                <Typography.Paragraph>若您设置了优惠券奖励，则用户需求使用优惠券时，点击去使用跳转到该链接</Typography.Paragraph>
                <Typography.Paragraph>
                    <Form.Item>
                        <div style={{display:"flex"}}>
                            <Form.Item name={["extra_config", "coupon_use_link", "mini_path"]}>
                                <Input onClick={() => {
                                    setLinkType("coupon_use_link");
                                    setShowChooseLink(true);
                                }} placeholder="请选择地址"
                                />
                            </Form.Item>
                            <Button type="primary" style={{marginLeft:"16px"}} onClick={() => {
                                setLinkType("coupon_use_link");
                                setShowChooseLink(true);
                            }}
                            >选择地址</Button>
                        </div>
                    </Form.Item>
                </Typography.Paragraph>
            </Typography>
            <Typography>
                <Typography.Title level={2}>“领券”去授权地址</Typography.Title>
                <Typography.Paragraph>小程序插件无法获取用户在您的小程序内的授权状态，用户在领券时引导用户前往该地址完成用户信息授权</Typography.Paragraph>
                <Typography.Paragraph>
                    <Form.Item>
                        <div style={{display:"flex"}}>
                            <Form.Item name={["extra_config", "login_link", "mini_path"]}>
                                <Input onClick={() => {
                                    setLinkType("login_link");
                                    setShowChooseLink(true);
                                }} placeholder="请选择地址"
                                />
                            </Form.Item>
                            <Button type="primary" style={{marginLeft:"16px"}} onClick={() => {
                                setLinkType("login_link");
                                setShowChooseLink(true);
                            }}
                            >选择地址</Button>
                        </div>
                    </Form.Item>
                </Typography.Paragraph>
            </Typography>
        </Card>
        <div className='create_active_footer'>
            <Button onClick={onGoBack}>上一步</Button>
            <Button type="primary" htmlType="submit">保存</Button>
        </div>
        <Modal
            visible={showChooseLink}
            title="选择链接"
            width={1200}
            onCancel={() => setShowChooseLink(false)}
            onOk={() => setShowChooseLink(false)}
        >
            {
                showChooseLink &&
                <Link chooseDialog chooseCallback={(data) => {
                    chooseLink(data);
                    setShowChooseLink(false);
                }}
                />
            }
        </Modal>
    </>)
}

export default EnvironmentModule
