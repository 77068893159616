import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Register from './pages/Register';
import App from './App';

export default function Page() {
  return <Router>
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/app/home/index" push/>}/>
      <Route path="/app" component={App}/>
      <Route path="/404" component={NotFound}/>
      <Route path="/login" component={Login}/>
      <Route path="/register" component={Register}/>
      <Route component={NotFound}/>
    </Switch>
  </Router>
}
