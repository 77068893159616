
import React from 'react'
import { Card, Input, Form, Table } from 'antd'
import './index.scss'

const columns = [
    {
        title: '参数名',
        dataIndex: 'name',
        key: 'name',
        render: text => <span>{text}</span>,
    },
    {
        title: '变量',
        dataIndex: 'variate',
        key: 'variate',
    },
    {
        title: '类型[长度限制]',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: '必填',
        dataIndex: 'required',
        key: 'required',
    },
    {
        title: '描述',
        dataIndex: 'description',
        key: 'description',
        width: 200,
        render: text => <pre style={{width: '200px'}}>{text}</pre>,
    }
];

const data = [
    {
        key: '1',
        name: '批次名称',
        variate: 'stock_name',
        type: 'string[1,20]',
        required: '是',
        description: 'body 批次名称\n校验规则：\n1、批次名称最多9个中文汉字\n2、批次名称最多20个字母\n3、批次名称中不能包含不当内容和特殊字符 _ , ; |\n示例值：微信支付代金券批次'
    },
];


function TypePort () {
    return (<>
        <Card className='type_wechat' title="什么是券接口对接？">
            <div>通过接入商户已有电商商城项目的券API，在活动中进行派发</div>
        </Card>
        <Form.Item label="券列表的接口地址" name="url1" rules={[{ required: true, message: '请输入券名称' }]}>
            <Input/>
        </Form.Item>
        <Form.Item label="发券接口的地址" name="url2" rules={[{ required: true, message: '请选择业务类型' }]}>
            <Input/>
        </Form.Item>
        <Card>
            <Table columns={columns} dataSource={data} />
        </Card>
    </>)
}

export default TypePort
