/**
 * Created by hao.cheng on 2017/4/13.
 */
import React, {Component} from 'react';
import SiderCustom from './SiderCustom';
import {Layout, Popover} from 'antd';
import {BarsOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import {NavLink, withRouter} from 'react-router-dom';
import {connectAlita} from 'redux-alita';
import QrCode from "qrcode.react";
import {
    Dialog,
    Radio,
    FormRadioGroupField,
    Form,
    FormStrategy, Notify,
} from 'zent';
import {
    getCoinsProductList,
    wechatChargeCoins,
    getChargeCoinsOrder, permission,
} from '../axios/tools';

import "./HeaderCustom.css"

const {Header} = Layout;

function Recharge(props) {
    const form = Form.useForm(FormStrategy.View);
    return (
        <Form layout="horizontal" form={form}>
            <FormRadioGroupField
                name="recharge"
                withoutLabel
            >
                {props.productList.map((product) => {
                    return (<Radio key={product.id} value={product.id}
                                   onClick={() => props.change(product.id)}
                            >{product.name} {product.price / 100}元</Radio>
                            );
                })}
            </FormRadioGroupField>
        </Form>
    );
}

class HeaderCustom extends Component {
    constructor(props) {
        console.log("HeaderCustom+++++++++++++++++",props);
        super(props);
        this.state = {
            ...props,
            visible: false,
            showTryCoinsTips: false,
            showRewardTips: false,
            defaultShowTips: true,
            defaultShowRewardTips: true,
            productID: null,
            productList: [],
            chargeCoins: {
                cashierUrl: '',
                orderNo: '',
            },
        };
    }

    componentDidMount() {
        this.request()
    };

    UNSAFE_componentWillReceiveProps(nextProps, _) {
        console.log("header:", nextProps)
        this.setState({
            ...nextProps,
        });
        if (nextProps.showRecharge && nextProps.showRecharge.data === true) {
            this.buyCoin().then();
        }
    };
    timeInterval = null;
    logout = () => {
        console.log("header custom logout--------------")
        localStorage.removeItem('user');
        localStorage.removeItem("token");
        this.props.history.push('/login');
    };
    popoverHide = () => {
        this.setState({
            visible: false,
        });
    };
    handleVisibleChange = (visible) => {
        this.setState({visible});
    };

    closeTryCoinsTips() {
        this.setState({
            showTryCoinsTips: false,
        });

        const date = new Date();
        let key = date.getFullYear() + '_' + date.getMonth() + '_' + date.getDate();
        window.localStorage.setItem(key, '1');
    }

    closeRewardTips() {
        this.setState({
            showRewardTips: false,
        });

        let key = 'show_reward_tips';
        window.localStorage.setItem(key, '1');
    }

    request() {
        let that = this;

        function isShowTips(user) {
            const date = new Date();
            let key =
                date.getFullYear() + '_' + date.getMonth() + '_' + date.getDate();
            console.log(key);
            let flag = window.localStorage.getItem(key);

            return (
                user.rewards_coins_left > 0 &&
                user.coins &&
                !flag &&
                that.state.defaultShowTips
            );
        }

        function isShowRewardTips(user) {
            let key = 'show_reward_tips';
            let flag = window.localStorage.getItem(key);

            return (
                that.state.defaultShowRewardTips && user.rewards_one_million === 1 &&
                !flag
            );
        }
        this.setState({
            showTryCoinsTips: isShowTips(this.props.auth.data),
            showRewardTips: isShowRewardTips(this.props.auth.data),
        });
    }

    coinText() {
        const time = this.props.auth.data.rewards_time_left || 0;

        if (time < 0) {
            return this.props.auth.data.coins || 0;
        } else {
            let day = Math.floor(time / 86400);

            return day ? day + '天' : '0天' + Math.floor(time / 3600) + '小时';
        }
    }

    closeRecharge() {
        clearInterval(this.timeInterval);
        this.props.setAlitaState({stateName:'showRecharge', data: false});
    }

    async selectCharge(id) {
        let chargeCoins = await wechatChargeCoins({
            productID: id,
        });
        this.setState({
            productID: id,
            chargeCoins: chargeCoins || {},
        });
    }

    async buyCoin() {
        if (!this.props.auth.data.is_auth_wechat) {
            Notify.error('请先授权绑定已认证微信服务号');
            this.closeRecharge();
            return
        }
        this.closeRecharge();
        const list = await getCoinsProductList();
        let arr = [];
        Object.keys(list).forEach(item => {
            arr.push({
                id: item,
                price: list[item].price,
                show: list[item].show,
                coins: list[item].coins,
                name: list[item].name,
            });
        });

        let productList = arr;
        let productID = productList[0].id;
        let chargeCoins = await wechatChargeCoins({
            productID: productID,
        });
        if (!chargeCoins) {
            chargeCoins = {
                cashierUrl: '',
                orderNo: '',
            };
        }
        console.log(productList);
        this.setState({
            productList: productList,
            productID: productID,
            chargeCoins: chargeCoins || {},
        });
        this.props.setAlitaState({stateName:'showRecharge', data: true});
        this.timeInterval = setInterval(async () => {
            if (this.state.chargeCoins.orderNo) {
                let res = await getChargeCoinsOrder({
                    orderNo: this.state.chargeCoins.orderNo,
                });
                if (
                    res.state === 8
                ) {
                    Notify.success('充值成功！如金币数额不正确，请刷新页面~');
                    const user = await permission();
                    this.props.setAlitaState({stateName:'showRecharge', data:false});
                    this.props.setAlitaState({stateName:'auth', data: user});
                    await this.request();
                    this.closeRecharge();
                }
            }
        }, 8000);
    }

    render() {
        console.log(this.props.responsive);
        return (
            <Header className="custom-theme header" style={{display:"flex"}}>
                {
                    this.props.responsive && this.props.responsive.data && this.props.responsive.data.isMobile ? (
                        <Popover content={<SiderCustom path={this.props.match.path} popoverHide={this.popoverHide}/>} trigger="click"
                                 placement="bottomLeft" visible={this.state.visible}
                                 onVisibleChange={this.handleVisibleChange}
                        >
                            <BarsOutlined className="header__trigger custom-trigger" />
                        </Popover>
                    ) : ( !this.props.collapsed ? (<MenuFoldOutlined className="header__trigger custom-trigger" onClick={this.props.toggle} />) : (<MenuUnfoldOutlined className="header__trigger custom-trigger" onClick={this.props.toggle} />)
                    )
                }
                <div className="top-nav">
                    <div>
                        {/* 您好！{this.state.user.nick_name} */}
                    </div>
                    <div className="coins">
                        {this.props.auth.data.rewards_time_left > 0 &&
                        <span>体验金币：{this.props.auth.data.rewards_coins_left}
                            <span
                                style={{color: '#FFCC00'}}
                                className="coin-title"
                            >（剩余体验时间：{this.coinText()}）</span>
                            </span>
                        }
                        <span>总金币：{this.props.auth.data.coins}</span>
                        <span className="charge-now"
                              onClick={(e) => this.buyCoin(e)}
                        >立即充值</span>
                        <span className="wechat">公众号：<NavLink to="/app/wechat"><span
                            className="wechat-name"
                                                                               >{this.props.auth.data.is_auth_wechat ?
                            this.props.auth.data.wechat_nick_name :
                            '未授权'}</span></NavLink></span>
                    </div>
                </div>
                <Dialog
                    visible={this.props.showRecharge.data}
                    onClose={() => this.closeRecharge()}
                    title="金币充值-微信扫码支付"
                    style={{width: '240px'}}
                >
                    <Recharge productList={this.state.productList}
                              change={(id) => this.selectCharge(id)}
                    />
                    <div style={{textAlign: 'center'}}><QrCode
                        value={this.state.chargeCoins.cashierUrl}
                                                       /></div>
                </Dialog>
                <Dialog
                    visible={this.state.showTryCoinsTips}
                    onClose={() => this.closeTryCoinsTips(false)}
                    title="体验金币剩余时间提醒"
                >
                    <div
                        className="title"
                    >体验金币剩余：{this.props.auth.data.rewards_coins_left}</div>
                    <div className="text">体验金币将在体验期后无法使用</div>
                    <div className="time">剩余使用时间：{this.coinText()}</div>
                </Dialog>
                <Dialog
                    visible={this.state.showRewardTips}
                    onClose={() => this.closeRewardTips(false)}
                    title="金币奖励提醒"
                >
                    <div className="title">恭喜！“活动名称”发布后24日内用户超过1w人</div>
                    <div className="text">奖励100万体验金币，请刷新页面查看</div>
                </Dialog>
            </Header>
        )
    }
}

export default withRouter(connectAlita(['responsive', 'showRecharge', 'auth'])(HeaderCustom));
