//引入react jsx写法的必须
import React from 'react';
import { Form, Input, Upload, Card } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { Notify } from 'zent';

import fanfanleBannerExample from '../../assets/bannertip.gif';
import catCoffeeExample from '../../assets/喵呜banner.png';
import guessPriceExample from '../../assets/guess-example/广告图-banner图示例-商品图示例.png';
import diemaomaoExample from '../../assets/diemaomao/mainshow.jpg';
import funiuExample from '../../assets/funiu/mainshow.png';

import { getToken, getApiBase } from '../../axios/tools';
import explainPic from '../../assets/hdzt2.png';

const uploadURI = getApiBase() + 'youzan/admin/upload';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    Notify.error('只可以上传jpg/png图片!');
  }
  const isLt500K = file.size / 1024 < 500;
  if (!isLt500K) {
    Notify.error('图片大小必须小于 500KB!');
  }
  return isJpgOrPng && isLt500K;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export default class BannerEdit extends React.Component {
  constructor(props) {
    super(props);
    console.log('init...............', props);
    let formRef = React.createRef();

    this.state = {
      ...props,
      uploading: false,
      formRef: formRef,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('next props.............', nextProps);

    this.setState({
      ...nextProps,
    });
    console.log(nextProps.activityDetail.banners[0]['link']);
    // this.state.formRef.current.setFieldsValue({
    //   ...nextProps.activityDetail,
    // });
  }
  updateActivityBanner(field, index, type, value) {
    let ac = this.state.activityDetail;
    ac[field][index][type] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  handleChange(info, index) {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      let res = info.file.response;
      if (res.code !== 0) {
        return Notify.error(res.message);
      }
      let imageUrl = res.data.image_url;
      this.updateActivityBanner('banners', index, 'image', imageUrl);
      this.setState({ uploading: false });
    }
  };

  render() {
    const uploadExtra = {
      token: getToken(),
    };
    const bannerSize = {
      1: '556X634',
      2: '608X390',
      3: '566X644',
      4: '566X644',
      5: '608X390',
      6: '608X390',
    };
    const bannerExample = {
      1: fanfanleBannerExample,
      2: catCoffeeExample,
      3: guessPriceExample,
      4: guessPriceExample,
      5: diemaomaoExample,
      6: funiuExample,
    };
    const uploadButton = (
      <div>
        {this.state.uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传</div>
      </div>
    );
    return (
      <Form
        {...layout}
        name="banner"
        ref={this.state.formRef}
      >
        <Card style={{ marginBottom: '24px' }}>
          <div style={{
            display: 'flex',
            justifyContent: 'left',
          }}
          >
            <div><img alt="" src={explainPic} /></div>
            <div style={{ marginLeft: '24px' }}>
              <div>设置建议：</div>
              <div>1、明确沟通活动优惠信息，比如“一等奖 免费送”<br />
                  2、图片突出产品质感和价值，激发用户夺取大奖的欲望
                </div>
            </div>
          </div>
        </Card>
        <div style={{
          display: 'flex',
          justifyContent: 'left',
          flexWrap: 'wrap',
        }}
        >
          {
            this.state.activityDetail.banners.map((item, index) => {
              return (
                <Card className="card-half-width" style={{
                  marginBottom: '24px',
                  marginLeft: index % 2 === 1 ? '24px' : '0px',
                }} key={index}
                >
                  <Form.Item
                    label={`链接${index + 1}：`}
                    name={`bannerlink${index}`}
                  >
                    <Input
                        defaultValue={item.link}
                        onChange={(e) => {
                          console.log('onchange............');
                          this.updateActivityBanner('banners', index,
                            'link', e.target.value);
                        }}
                    />
                    <span>请输入http://或https://开头的链接</span>
                  </Form.Item>
                  <Form.Item
                    className="uploader"
                    label={'图片' + (index + 1) + '：'}
                    valuePropName="fileList"
                    style={{ marginBottom: 0 }}
                    key={index}
                  >
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="banner-uploader"
                      showUploadList={false}
                      action={uploadURI}
                      data={uploadExtra}
                      beforeUpload={beforeUpload}
                      onChange={(info) => {
                        this.handleChange(info, index);
                      }}
                    >
                      {this.state.activityDetail.banners[index]['image'] ?
                        <img
                          src={this.state.activityDetail.banners[index]['image']}
                          alt="avatar" style={{ width: '100%' }}
                        /> :
                        uploadButton}
                    </Upload>
                    <div className="uploader-tips">
                      * {bannerSize[this.state.activityDetail.activity_type]}，≤500k
                          <br />* 点击上传
                        </div>
                  </Form.Item>
                </Card>);
            })
          }
        </div>
        <Card>
          <div className="banner-example">
            <div className="example-title">活动主题示例</div>
            <img alt=""
              src={bannerExample[this.state.activityDetail.activity_type]}
            />
          </div>
        </Card>
      </Form>
    );
  }
}