// 引入react jsx写法的必须
import React from 'react';
import {Grid} from 'zent';
import moment from 'moment';
import {
  getVouchers,
} from '../../axios/tools';

import './list.css';
import { Select, Button } from 'antd';
import CreateVouchers from './createVouchers';
import {connectAlita} from 'redux-alita';

moment.locale('zh-cn'); // zh-cn

class Vouchers extends React.PureComponent {
  constructor(props) {
    console.log("constructor...........");
    super(props);
    this.state = {
      productsTotal: 0,
      products: [],
      pageSize: 20,
      page: 1,
      currentRow: {},
      userInfo: {},
      status: "running",
      chooseDialog: props.chooseDialog || false,
      chooseCallback: props.chooseCallback,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    console.log("Vouchers componentDidMount...........");
    this.getVouchersList().then(() => {});
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    this.setState({
      ...nextProps,
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  _isMounted = false;

  async getVouchersList(status) {
    let listRet = await getVouchers(this.state.page, status ? status : this.state.status);
    if (listRet && this._isMounted) {
      this.setState({
        products: listRet.items || [],
        productsTotal: listRet.total,
        pageSize: listRet.page_size,
      });
    }
  }

  gridChange(conf) {
    let that = this;
    setTimeout(function () {
      that.setState({
        page: conf.current,
      });
      that.getVouchersList().then(() => {});
    }, 1);
  }
  render() {
    let that = this;
    let columns = [
      {
        'title': '批次号',
        'name': 'stock_id',
      }, {
        'title': '券名称',
        'name': 'stock_name',
      }, {
        'title': '面额(元)',
        'name': 'stock_use_rule',
        'bodyRender': function (data) {
            return (<span>{data.stock_use_rule.fixed_normal_coupon ? data.stock_use_rule.fixed_normal_coupon.coupon_amount / 100 : '-'}</span>)
        }
      }, {
        'title': '批次状态',
        'name': 'status',
        'bodyRender': function (data) {
          switch (data.status) {
            case 'running':
              return (<span>进行中</span>)
            case 'unactivated':
              return (<span>未激活</span>)
            case 'audit':
              return (<span>审核中</span>)
            case 'stoped':
              return (<span>已停止</span>)
            case 'paused':
              return (<span>暂停发放</span>)
            default:
              return ''
          }
        }
      }, {
        'title': '有效时间',
        'name': 'available_begin_time',
        'bodyRender': function (data) {
          return (<span>
            {
              moment(data.available_begin_time)
              .format('YYYY-MM-DD HH:mm')
            } - {
            moment(data.available_end_time)
            .format('YYYY-MM-DD HH:mm')
          }
          </span>);
        },
      }, {
        'title': '批次类型',
        'name': 'stock_type',
      }, {
        'title': '创建时间',
        'name': 'create_time',
        'bodyRender': function (data) {
          return (<span>
            {
              moment(data.create_time)
                .format('YYYY-MM-DD HH:mm')
            }
          </span>);
        },
      },];
    if (that.props.chooseDialog) {
      columns.push({
        'title': '选择',
        'name': 'action',
        'bodyRender': function (data) {
          return <Button disabled={this.props.chosenVoucherID === data.stock_id} type="primary" onClick={() => {
            that.props.chooseCallback(data);
          }}
                 >{this.props.chosenVoucherID === data.stock_id?'已选':'选择'}</Button>
        }
      });
    }
    const states = [{
      label: "进行中",
      value: "running"
    }, {
      label: "未激活",
      value: "unactivated",
    }, {
      label: "审核中",
      value: "audit",
    }, {
      label: "已停止",
      value: "stoped",
    }, {
      label: "暂停发放",
      value: "paused",
    }]
    return (
      <div style={{ width: '100%', overflowX: 'hidden', zIndex: 100000 }}>
        <div className="newActivity">
          <div
            style={{
              width: '460px',
              flexBasis: '460px',
              display: 'flex',
              padding: '24px 0'
            }}
          >
            <Select
              style={{marginRight: "16px"}}
              defaultValue={this.state.status}
              onChange={(value) => {
                this.setState({
                  status: value
                });
                this.getVouchersList(value).then();
              }}
            >
              {states.map((item) => {
                return <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
              })}
            </Select>
            <Button
              type="primary"
              onClick={() => {
                this.props.setAlitaState({stateName: 'showCreateVoucher', data: true});
              }}
              style={{
                display: 'block',
                width: '180px',
              }}
            >
              微信支付代金券授权
            </Button>
          </div>
        </div>
        <div>
          <span>微信商家券需要进行“</span>
          <span style={{color: '#70B603'}}>微信支付商家号API授权</span>
          <span>”才能发放微信券，请先完成</span>
          <span style={{color: '#00a4fd'}} onClick={() => {
            this.props.setAlitaState({stateName: 'showCreateVoucher', data: true});
          }}
          >授权</span>
        </div>
        <div>
          <Grid
            rowKey={'stock_id'}
            columns={columns}
            datasets={this.state.products}
            pageInfo={{
              current: this.state.page,
              pageSize: this.state.pageSize,
              total: this.state.productsTotal,
            }}
            onChange={(conf) => {
              this.gridChange(conf);
            }}
            ellipsis
          />
        </div>
        <CreateVouchers/>
      </div>);
  }
}
export default connectAlita(["showCreateVoucher", 'auth'])(Vouchers)
