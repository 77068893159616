import React, { useEffect, useState } from 'react';
import Swiper from 'swiper';
import { useAlita } from 'redux-alita'
import 'swiper/css/swiper.css';
import './index.css'

import renrenjika from '../../../assets/renrenjika.png'
import tiantianhongbaoyu from '../../../assets/tiantianhongbaoyu.png'
import diediemao from '../../../assets/diediemao.png'

function SwiperApp (props) {
    const [ ,setAlita ] = useAlita('swiperTitle', { light: true })
    const [ list, setList ] = useState([])
    const [ , setSwiperObj ] = useState([])
    useEffect(() => {
        const lists = props.list || [
            {
                key: '人人集卡',
                url: renrenjika
            },
            {
                key: '叠叠猫',
                url: diediemao
            },
            {
                key: '天天红包雨',
                url: tiantianhongbaoyu
            },
        ]
        setList(lists)
        setTimeout(() => { // 使用setTimeout是为了确保dom执行完成执行 Swiper 否则容易出现样式问题
            const swiper = new Swiper('.swiper-container', {
                slidesPerView: 3,
                // effect : 'coverflow',
                loop: false,
                autoplay: {// 自动滑动
                    delay: 3000, //3秒切换一次
                    // stopOnLastSlide: false,
                    disableOnInteraction: true,//
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                centeredSlides: true,
                observer: true,//修改swiper自己或子元素时，自动初始化swiper    重要
                observeParents: true,//修改swiper的父元素时，自动初始化swiper  重要
                on: {
                    slideChangeTransitionStart: function () {
                        console.log(this, this.activeIndex)
                        setAlita({ stateName: 'swiperTitle', data: lists[this.activeIndex].key })
                    }
                }
            })
            setSwiperObj(swiper)
        }, 200)
        return () => { // 销毁swiper
            // if (swiperObj.destroy) {
            //     swiperObj.destroy();
            //     swiperObj = null;
            // }
        }
    }, [props.list, setAlita])

    return (
        <div className='swiper_father'>
            {/* /swiper-no-swiping关闭鼠标滑动  ${con}设置轮播组件的宽高*/}
            <div className={`swiper-container con`}>
                <div className={`swiper-wrapper con`}>
                    {
                        list && list.length > 0 && list.map((item, index) => {
                            return (
                                <div className="swiper-slide" key={`swiper${index}`}>
                                    <div className='swiper-slide-img' style={{width: '100%'}}>
                                        <div>
                                            <img src={item.url} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="swiper-button-prev" style={{
                        background: '#fff',
                        top: '10%',
                        left: '0',
                        padding: '0 20px',
                        height: '100%',
                    }}
                />
                <div className="swiper-button-next" style={{
                        background: '#fff',
                        top: '10%',
                        right: '0',
                        padding: '0 20px',
                        height: '100%',
                    }}
                />
            </div>
        </div>
    )
}
export default SwiperApp
