//引入react jsx写法的必须
import React from 'react';
import {NavLink} from 'react-router-dom'; //路由库

import './wechat.css';

import { Button, Dialog, Notify} from 'zent';
import {getWechatAuthQrCode, permission} from '../axios/tools';
import QrCode from 'qrcode.react';
import {connectAlita} from 'redux-alita';

class Wechat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      showAuth: false,
      wechatAuth: 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg==',
    };
    this.timeInterval = null;
  }

  componentDidMount() {

  }
  UNSAFE_componentWillReceiveProps(nextProps, _) {
    if (nextProps.user !== this.state.user) {
      this.setState({
        ...nextProps,
      });
    }
  }

  closeAuth() {
    clearInterval(this.timeInterval);
    this.setState({
      showAuth: false,
    });
  }

  async startAuth() {
    let that = this;
    let auth = await getWechatAuthQrCode();
    console.log(auth);
    if (auth) {
      this.setState({
        wechatAuth: auth,
        showAuth: true,
      });
      this.timeInterval = setInterval(async function() {
        let userRet = await permission();
        if (userRet.is_auth_wechat) {
          Notify.info('授权成功');
          that.closeAuth();
          that.props.setAlitaState({stateName: 'auth', data: userRet});
        }
      }, 5000);
    } else {
      Notify.error('获取二维码失败');
    }
  }


  render() {
    return (
        <div>
          {this.props.auth.data.is_auth_wechat === false &&
          <div style={{marginTop:"16px"}}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#edf4ff',
              border: '1px solid #9ebbed',
              padding: '8px 16px',
            }}
            >
              <div>请先绑定认证的微信服务号，详见<NavLink className="item"
                                           to="/app/wechat"
                                  >扫码授权说明</NavLink></div>
              <div><Button icon="wechat" type="primary" onClick={
                () => {
                  this.startAuth().then();
                }
              }
                   >开始授权</Button></div>
            </div>
          </div>
          }
          <Dialog
              visible={this.state.showAuth}
              title="扫码授权"
              style={{width: '264px'}}
              onClose={() => {
                this.closeAuth();
                this.setState({
                  showAuth: false,
                });
              }}
          >
            <div><QrCode value={this.state.wechatAuth} /></div>
            <div>请授权已认证的微信服务号</div>
          </Dialog>
        </div>
    );
  }
}
export default connectAlita(['auth'])(Wechat);
