//引入react jsx写法的必须
import React from "react";
import {Input, Upload, Modal, Button, Table, Row, Col, Divider} from 'antd';

import { Notify} from "zent";

import {getToken, getUploadURI} from '../../../axios/tools';
import Column from "antd/lib/table/Column";
import {beforeUpload} from "../../../utils/index"
import {DownOutlined, UpOutlined} from '@ant-design/icons';

const uploadURI = getUploadURI();

export default class CardEdit extends React.Component {
  constructor(props) {
    super(props);
    console.log("init...............", props);
    let formRef = React.createRef();
    props.activityDetail.cards = this.updateRatio(props.activityDetail.cards);
    this.state = {
      ...props,
      uploading: false,
      formRef: formRef,
      showProductCodeTips: false,
      showMore: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, _) {
    console.log("next props.............", nextProps);
    let activityDetail = nextProps.activityDetail;
    activityDetail.cards = this.updateRatio(nextProps.activityDetail.cards);
    activityDetail = this.state.updateRewardRatio(activityDetail);
    this.setState({
      ...nextProps,
      activityDetail,
    });
    if (this.state.formRef.current && this.state.formRef.current.setFieldsValue) {
      this.state.formRef.current.setFieldsValue({
        ...nextProps.activityDetail,
      });
    }
  }

  updateActivityField(field, value) {
    let ac = this.state.activityDetail;
    ac[field] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  updateActivityBanner(field, index, type, value) {
    let ac = this.state.activityDetail;
    ac[field][index][type] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }
  updateRatio(cards) {
    let totalNumber = 0;
    cards.map((item, index) => {
      if (index > 5) {
        return true;
      }
      console.log("item.number+++++++++++++++++", item.number);
      if (item.number) {
        totalNumber += item.number;
      }
      return true;
    });
    console.log("totalNumber++++++++++++++++++++", totalNumber);
    let totalRatio = 0;
    cards.map((item, index) => {
      if (index > 5) {
        return true;
      }
      let ratio = Math.round((item.number / totalNumber) * 10000) / 100;
      if (index === 5) {
        ratio = 100 - totalRatio;
      } else {
        totalRatio += ratio;
      }
      cards[index]["ratio"] = ratio.toFixed(2);
      return true;
    });
    return cards;
  }

  handleChange(info, index) {
    if (info.file.status === "uploading") {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      let res = info.file.response;
      if (res.code !== 0) {
        return Notify.error(res.message);
      }
      let imageUrl = res.data.image_url;
      this.updateActivityBanner("cards", index, "image", imageUrl);
      this.setState({ uploading: false });
    }
  }

  render() {
    const uploadExtra = {
      token: getToken(),
    };
    return (
      <div
        style={{
          position: "relative",
        }}
      >
          <Row gutter={[16, 16]} style={{textAlign:"center"}}>
            <Col span={3} style={{textAlign:"right"}}>位置：</Col>
            <Col span={3}>卡1</Col>
            <Col span={3}>卡2</Col>
            <Col span={3}>卡3</Col>
            <Col span={3}>卡4</Col>
            <Col span={3}>卡5</Col>
            <Col span={3}>卡6</Col>
            <Col span={3}/>
          {!this.state.showMore &&
          (<><Col span={3} style={{textAlign:"right"}}>效果图：</Col>
            {this.state.activityDetail.cards.map((item, index) => {
              return index < 6 ?
                  <Col span={3} key={index}>
                    <div className="card-preview">
                      <div className="title">{item.title}</div>
                      <div className="image">
                        <img src={item.image} alt=""/>
                      </div>
                    </div>
                  </Col> :
                  ""
            })
            }<Col span={3}/></>)
          }
            <Col span={3} style={{textAlign:"right"}}>可抽次数：</Col>
            {this.state.activityDetail.cards.map((item, index) => {
              return index < 6 ?
                  <Col span={3} key={index}>
                    <Input
                        size="small"
                        style={{width: "60px"}}
                        defaultValue={item.number}
                        onChange={(e) => {
                          console.log("onchange............");
                          let activityDetail = this.state.activityDetail;
                          activityDetail.cards[index]["number"] = e.target.value * 1;
                          activityDetail.cards = this.updateRatio(activityDetail.cards);
                          activityDetail = this.state.updateRewardRatio(activityDetail);
                          this.state.updateState({
                            activityDetail: activityDetail,
                          });
                        }}
                    />
                  </Col> :
                  ""
            })
            }
            <Col span={3}/>
            <Col span={3} style={{textAlign:"right"}}>概率：</Col>
            {this.state.activityDetail.cards.map((item, index) => {
              return index < 6 ?
                  <Col span={3} key={index}>
                    <span>{item.ratio}%</span>
                  </Col> :
                  ""
            })
            }
            <Col span={3}/>
            <Col span={3} style={{textAlign:"right"}}>已发放量：</Col>
            {this.state.activityDetail.cards.map((item, index) => {
              return index < 6 ?
                  <Col span={3} key={index}>
                    {index === 0 && <span>{this.state.activityDetail.activity_jika.sent_one}</span>}
                    {index === 1 && <span>{this.state.activityDetail.activity_jika.sent_two}</span>}
                    {index === 2 && <span>{this.state.activityDetail.activity_jika.sent_three}</span>}
                    {index === 3 && <span>{this.state.activityDetail.activity_jika.sent_four}</span>}
                    {index === 4 && <span>{this.state.activityDetail.activity_jika.sent_five}</span>}
                    {index === 5 && <span>{this.state.activityDetail.activity_jika.sent_six}</span>}
                  </Col> :
                  ""
            })
            }
            <Col span={3}/>
          </Row>
          <div style={{ padding: "16px 24px", background: "#e9f4ff", marginTop:"16px" }}>
            <div>
              <strong>获奖概率（该数值通过配置的卡牌可抽次数计算）</strong>
            </div>
            <div>一等奖：{this.state.activityDetail.firstRewardRatio}%</div>
            <div>二等奖：{this.state.activityDetail.secondRewardRatio}%</div>
            <div>三等奖：{this.state.activityDetail.thirdRewardRatio}%</div>
          </div>
          <Divider orientation="left" onClick={() => {
            this.setState({
              showMore: !this.state.showMore
            })
          }}
          >{!this.state.showMore && <>展开高级设置<DownOutlined size="small" /></>}{this.state.showMore && <>收起高级设置<UpOutlined size="small" /></>}</Divider>
          {this.state.showMore &&
          <Table
              dataSource={this.state.activityDetail.cards}
              pagination={false}
              rowKey={(row) => {
                return "row-" + row.id;
              }}
          >
            <Column
                className="card-edit-col"
                title=""
                dataIndex="id"
                key="id"
                render={(id) => <>{id < 7 && <span>卡{id}</span>}</>}
            />
            <Column
                className="card-edit-col"
                align="center"
                title="卡牌名称"
                dataIndex="title"
                key="title"
                render={(title, row, index) => (
                    <>
                      {index < 6 && (
                          <span>
                      <Input
                          defaultValue={title}
                          onBlur={(e) => {
                            if (e.target.value.length > 5) {
                              Modal.error({
                                title: "发布失败",
                                content: "卡牌名称不能超过5个字符",
                              });

                            }
                          }}
                          onChange={(e) => {
                            console.log("onchange............");
                            if (e.target.value.length > 5) {
                              Notify.info("卡牌名称不能超过5个字符");
                              return;
                            }
                            this.updateActivityBanner("cards", index, "title",
                                e.target.value);
                          }}
                      />
                    </span>
                      )}
                      {index === 6 && <span>卡牌背面</span>}
                    </>
                )}
            />
            <Column
                className="card-edit-col"
                align="center"
                title="效果图"
                dataIndex="image"
                key="image"
                render={(image, row, index) => (
                    <>
                      {index < 6 && (
                          <div className="card-preview">
                            <div className="title">{row.title}</div>
                            <div className="image">
                              <img src={image} alt=""/>
                            </div>
                          </div>
                      )}
                      {index === 6 && (
                          <span>
                      <img width="85" height="114" src={image} alt=""/>
                    </span>
                      )}
                    </>
                )}
            />
            <Column
                className="card-edit-col"
                align="center"
                title="操作"
                key="index"
                render={(action, row, index) => (
                    <>
                      {index < 7 && (
                          <span>
                      <Upload
                          name="file"
                          showUploadList={false}
                          action={uploadURI}
                          data={uploadExtra}
                          beforeUpload={beforeUpload}
                          onChange={(info) => {
                            this.handleChange(info, index);
                          }}
                      >
                        <Button>上传图片310X344</Button>
                      </Upload>
                      <Button
                          style={{marginLeft: "24px"}}
                          type="secondary"
                          onClick={() => {
                            switch (index) {
                              case 0:
                                this.updateActivityBanner(
                                    "cards",
                                    index,
                                    "image",
                                    "https://oss.static.rrfenwo.com/jika/人间锦鲤-卡面.png"
                                );
                                break;
                              case 1:
                                this.updateActivityBanner(
                                    "cards",
                                    index,
                                    "image",
                                    "https://oss.static.rrfenwo.com/jika/一夜暴富.png"
                                );
                                break;
                              case 2:
                                this.updateActivityBanner(
                                    "cards",
                                    index,
                                    "image",
                                    "https://oss.static.rrfenwo.com/jika/笑口常开.png"
                                );
                                break;
                              case 3:
                                this.updateActivityBanner(
                                    "cards",
                                    index,
                                    "image",
                                    "https://oss.static.rrfenwo.com/jika/福气满满.png"
                                );
                                break;
                              case 4:
                                this.updateActivityBanner(
                                    "cards",
                                    index,
                                    "image",
                                    "https://oss.static.rrfenwo.com/jika/健康平安.png"
                                );
                                break;
                              case 5:
                                this.updateActivityBanner(
                                    "cards",
                                    index,
                                    "image",
                                    "https://oss.static.rrfenwo.com/jika/加薪不加班.png"
                                );
                                break;

                              case 6:
                                this.updateActivityBanner(
                                    "cards",
                                    index,
                                    "image",
                                    "https://oss.static.rrfenwo.com/jika/卡背.png"
                                );
                                break;
                              default:
                                break;
                            }
                          }}
                      >
                        默认
                      </Button>
                    </span>
                      )}
                    </>
                )}
            />
          </Table>
          }
      </div>
    );
  }
}
