/**
 * 路由组件出口文件
 * yezi 2018年6月24日
 */
// import Loadable from 'react-loadable';
// import Loading from './widget/Loading';
// import BasicForm from './forms/BasicForm';
// import BasicTable from './tables/BasicTables';
// import AdvancedTable from './tables/AdvancedTables';
// import AsynchronousTable from './tables/AsynchronousTable';
// import Echarts from './charts/Echarts';
// import Recharts from './charts/Recharts';
// import Icons from './ui/Icons';
// import Buttons from './ui/Buttons';
// import Spins from './ui/Spins';
// import Modals from './ui/Modals';
// import Notifications from './ui/Notifications';
// import Tabs from './ui/Tabs';
// import Banners from './ui/banners';
// import Drags from './ui/Draggable';
import Dashboard from './dashboard/Dashboard'; // 原先的首页
// import Gallery from './ui/Gallery';
// import BasicAnimations from './animation/BasicAnimations';
// import ExampleAnimations from './animation/ExampleAnimations';
// import AuthBasic from './auth/Basic';
// import RouterEnter from './auth/RouterEnter';
// import Cssmodule from './cssmodule';
// import MapUi from './ui/map';
// import QueryParams from './extension/QueryParams';

import ActivityList from './activity/list';
import ActivityEdit from './activity/edit';
import Home from './home/index';
import Wechat from './wechat/index';
import Vouchers from './wechat/vouchers';
import LoginOut from './auth/LoginOut';

/**
 * 首页
 */
import HomePage from './homePage/index';

/**
 * 用户
 */
import UserList from './user/userList/index'; // 用户列表
import UserDetail from './user/userDetail/index'; // 用户详情
import TagManagement from './user/tagManagement/index'; // 标签管理

/**
 * 新建活动
 */
// import CreateActivity from './createActivity/index';

/**
 * 奖品
 */
import Physical from './prize/physical/index';
import CreatePhysical from './prize/physical/CreatePhysical/index';
import Convert from './prize/convert/index';
import Ticket from './prize/ticket/index';

/**
 * 链接管理
 */
import Link from './link';
import CreateLink from './link/CreateLink';
/**
 * 数据
 */
import ActivityInformation from './information/activityInformation/index';

// const WysiwygBundle = Loadable({
//     // 按需加载富文本配置
//     loader: () => import('./ui/Wysiwyg'),
//     loading: Loading,
// });


const Components = {
  ActivityEdit,
  ActivityList,
  Home,
  Wechat,
  LoginOut,
  Vouchers,
  Dashboard,
  HomePage,
  UserList,
  UserDetail,
  TagManagement,
  Physical,
  Convert,
  Ticket,
  CreatePhysical,
  ActivityInformation,
  Link,
  CreateLink,
};
export default Components;
