//引入react jsx写法的必须
import React from 'react';
import { Form, Input, Card } from "antd"

import { Notify } from "zent"

import fanfanleCardExample from "../../assets/分享文案示例.png";
import catCoffeeExample from "../../assets/cat分享文案示例.png"
import guessPriceExample from "../../assets/guess-example/分享.png"
import funiuExample from "../../assets/funiu/shuchu.png"

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export default class ShareEdit extends React.Component {
  constructor(props) {
    super(props);
    console.log('init...............', props)
    let formRef = React.createRef();

    this.state = {
      ...props,
      uploading: false,
      formRef: formRef,
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('next props.............', nextProps)

    this.setState({
      ...nextProps,
    });
  }
  updateActivityField(field, value) {
    let ac = this.state.activityDetail;
    ac[field] = value;
    this.state.updateState({
      activityDetail: ac
    })
  }
  updateActivityBanner(field, index, type, value) {
    let ac = this.state.activityDetail;
    ac[field][index][type] = value;
    this.state.updateState({
      activityDetail: ac
    })
  }
  handleChange(info, index) {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      let res = info.file.response;
      if (res.code !== 0) {
        return Notify.error(res.message)
      }
      let imageUrl = res.data.image_url;
      this.updateActivityBanner('shares', index, 'image', imageUrl)
      this.setState({ uploading: false });
    }
  };
  render() {
    const shareExample = {
      1: fanfanleCardExample,
      2: catCoffeeExample,
      3: guessPriceExample,
      4: guessPriceExample,
      5: guessPriceExample,
      6: funiuExample,
    }
    return (
        <Form
            {...layout}
            name="shares"
            ref={this.state.formRef}
        >
          <div style={{ display: "flex", justifyContent: "left", flexWrap: "wrap" }}>
            {
              this.state.activityDetail.shares.map((item, index) => {
                console.log(item);
                return (
                    <Card className="card-half-width" style={{ marginBottom: "24px", marginLeft: index % 2 === 1 ? '24px' : '0' }} key={index}>
                      <Form.Item
                          label={`标题${index + 1}：`}
                          name={`sharetitle${index}`}
                          initialValue={item.title}
                      >
                        <Input
                            defaultValue={item.title}
                            onChange={(e) => {
                              console.log('onchange............')
                              this.updateActivityBanner('shares', index, 'title', e.target.value);
                            }}
                        />
                      </Form.Item>
                      <Form.Item
                          label={`描述${index + 1}：`}
                          name={`sharedescription${index}`}
                          initialValue={item.description}
                          style={{ marginBottom: 0 }}
                      >
                        <Input
                            defaultValue={item.description}
                            onChange={(e) => {
                              console.log('onchange............')
                              this.updateActivityBanner('shares', index, 'description', e.target.value);
                            }}
                        />
                      </Form.Item>
                    </Card>)
              })
            }
            <Card>
              <div className="share-example">
                <div className="example-title">分享示例</div>
                <img alt="" src={shareExample[this.state.activityDetail.activity_type]} />
              </div>
            </Card>
          </div>
        </Form>

    )
  }
}