import React from 'react'
import './index.css'
class Calendar extends React.Component {
    constructor (props) {
        super(props)
        const { list } = props
        this.state = {
            activeIndex: 0,
            list: list || [
                { name: '妇女节', time: '03月08日', text: '三八女神节大促你开始准备了吗？《翻翻乐》+《人人集卡》，双管齐下，7天签到预热，集卡牌轻松达成裂变活跃转化，快跟上节奏做一波吸粉引流、沉淀用户、激活社群的活动吧！'},
                { name: '妇女节', time: '03月08日' },
                { name: '妇女节', time: '03月08日', text: '三八女神节大促你开始准备了吗？《翻翻乐》+《人人集卡》，双管齐下，7天签到预热，集卡牌轻松达成裂变活跃转化，快跟上节奏做一波吸粉引流、沉淀用户、激活社群的活动吧！' },
                { name: '妇女节', time: '03月08日' },
                { name: '妇女节', time: '03月08日', text: '三八女神节大促你开始准备了吗？《翻翻乐》+《人人集卡》，双管齐下，7天签到预热，集卡牌轻松达成裂变活跃转化，快跟上节奏做一波吸粉引流、沉淀用户、激活社群的活动吧！' },
            ]
        }
    }
    onTodayTabLt () {
        if (this.state.activeIndex <= 0) {
            return
        }
        this.setState({
            activeIndex: this.state.activeIndex <= 0? this.state.list.length - 1 : this.state.activeIndex - 1
        })
    }
    onTodayTabGt () {
        this.setState({
            activeIndex: this.state.activeIndex >= this.state.list.length - 1 ? 0 : this.state.activeIndex + 1
        })
    }
    render () {
        const { list, activeIndex } = this.state
        return (<div className='home_today_tab'>
            <header className='tody_tab_header'>
                <span className='tody_tab_title'>节日营销</span>
                <span className='tody_tab_lt' onClick={() => this.onTodayTabLt()}>&lt;</span>
                <span>三月</span>
                <span className='tody_tab_gt' onClick={() => this.onTodayTabGt()}>&gt;</span>
            </header>
            <main className='tody_tab_main'>
                <div className='tody_tab_main_box'>
                    {
                        list.map((item, index) => {
                            return (
                                <div className={`tody_tab_main_item ${index === activeIndex && 'active'}`} key={`tody_tab_main_item_${index}`}>
                                    <span className='tody_tab_main_item_name'><span>{ item.name }</span></span>
                                    <span className='tody_tab_main_item_time'>{ item.time }</span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='tody_tab_main_item_text'>
                    <span>{ list[activeIndex].text }</span>
                </div>
            </main>
        </div>)
    }
}
export default Calendar
