import moment from 'moment';

let miniCoins = 5000;
export default function activityDefault(appName, activityType) {
  let template = {
    '1': {
      'activity_id': '',
      'merchant_id': '',
      'create_time': 0,
      'state': 0,
      'begin_time': moment().format(),
      'end_time': moment().add(7, 'days').format(),
      'user_defined_task': 0,
      'notice_end': 0,
      'need_eight_clock_notice': 0,
      'need_twenty_clock_notice': 1,
      'need_new_friends_notice': 1,
      'need_follow_wechat_notice': 1,
      'need_game_end_notice': 1,
      'need_battle_overstep_notice': 1,
      'need_battle_calc_notice': 1,
      'need_first_cat_notice': 0,
      'need_tomorrow_task_end_notice': 0,
      'need_tomorrow_task_finish_notice': 0,
      'need_tomorrow_task_will_fail_notice': 0,
      'need_dmm_pk_end_notice': 0,
      'need_dmm_rewards_full_notice': 0,
      'notify_mobile': 0,
      'need_coins_notice': 0,
      'pk_need_buy': 0,
      'world_rank_rewards': {
        1: {
          'link': '',
          'image': '',
          'title': '',
        },
        2: {
          'link': '',
          'image': '',
          'title': '',
        },
        3: {
          'link': '',
          'image': '',
          'title': '',
        },
        4: {
          'link': '',
          'image': '',
          'title': '',
        },
        '-1': {
          'link': '',
          'image': '',
          'title': '',
        },
      },
      'max_rank': 4,
      'title': appName,
      'add_coins': miniCoins,
      'rule_link': '活动时间：【xxxx年xx月xx日结束，需商家自己改动】\n' +
          '如何获得最终大奖？\n' +
          '【xx商家】会按照排行榜发放奖励，排行榜按照用户的金币数额进行排行。\n' +
          '如何获得更多金币？\n' +
          '1.每次尽可能多的翻出正确的配对卡牌\n' +
          '2.努力连击获得更多金币\n' +
          '3.每天最高连击数的用户可获得额外2000金币\n' +
          '4.邀请用户玩游戏，被邀请好友获得的金币能够按一定比例奖励给邀请者。\n' +
          '一、配对卡牌&连击\n' +
          '每局开始翻出卡牌，按照对应的卡牌进行配对，连续配对则产生连击\n' +
          '二、体力任务\n' +
          '此活动默认体力为X点，体力不足时，用户可以点击活动首页下方的体力任务进行体力恢复，每天晚上8:00体力任务会刷新一次。如果点击体力任务后，体力值框未添加体力，请刷新游戏。\n' +
          '三、2000金币每日奖\n' +
          '每天8:00~22:00，最高连击记录的获得者可以获得2000金币的额外金币奖励。\n' +
          '四、增加时间\n' +
          '1.关注公众号每局可增加10秒游戏时间\n' +
          '2.邀请好友每局可增加2秒游戏时间\n' +
          '3.游戏时间上限为60秒，超出部分不再增加\n' +
          '五、排行榜\n' +
          '按照用户的金币数额进行排行，金币数额相同的按照获得金币的时间排序。\n' +
          '本次获得的解释权归【xx，商家名，需改动】所有',
      'default_power': 3,
      'activity_type': 1,
      banners: [
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/banner.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/banner.png',
        },
      ],
      cards: [
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/card1.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/card2.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/card3.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/card4.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/card5.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/card6.png',
        },
      ],
      shares: [
        {
          title: '免费送你3次机会，玩游戏还能拿大奖！',
          description: '宠粉福利，不要错过～',
          icon: '',
        },
        {
          title: '免费送你3次机会，玩游戏还能拿大奖！',
          description: '宠粉福利，不要错过～',
          icon: '',
        },
      ],
      power_task: [
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/target.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/target.png',
        },
      ],
      qrcode: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/qr.png',
      exchange_ratio: 1,
      coins: 0,
      total_coins: 0,
      need_exchange_coins: '0',
    },
    '2': {
      title: appName,
      ruleLink: 'https://mp.weixin.qq.com/s/7wdgi6uAY2YQAiLyToQiHg',
      defaultPower: 60,
      activityType: 2,
      banners: JSON.stringify([
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/cat-banner1.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/cat-banner2.png',
        },
      ]),
      cards: JSON.stringify([
        {
          link: '',
          coffee_id: 1,
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/signcard1.png',
        },
        {
          link: '',
          coffee_id: 2,
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/signcard2.png',
        },
        {
          link: '',
          coffee_id: 3,
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/signcard3.png',
        },
        {
          link: '',
          coffee_id: 4,
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/signcard4.png',
        },
        {
          link: '',
          coffee_id: 5,
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/signcard5.png',
        },
        {
          link: '',
          coffee_id: 6,
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/signcard6.png',
        },
        {
          link: '',
          coffee_id: 7,
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/signcard7.png',
        },
        {
          link: '',
          coffee_id: 8,
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/signcard8.png',
        },
      ]),
      shares: JSON.stringify([
        {
          title: '玩游戏还能免费拿大奖，还有这等好事！',
          description: '宠粉福利',
          icon: '',
        },
        {
          title: '玩游戏还能免费拿大奖，还有这等好事！',
          description: '宠粉福利',
          icon: '',
        },
      ]),
      powerTask: JSON.stringify([
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/target.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/target.png',
        },
      ]),
      qrCode: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/qr.png',
      exchangeRatio: 1,
      coins: miniCoins,
      needExchangeCoins: 0,
    },
    '3': {
      title: appName,
      ruleLink: 'https://shimo.im/docs/9KGXDPcWQdpKwxHX/',
      defaultPower: 18,
      activityType: 3,
      banners: JSON.stringify([
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/cat-banner1.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/cat-banner2.png',
        },
      ]),
      cards: JSON.stringify([
        {
          link: '',
          coffee_id: 1,
          price: '10.01',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/1.png',
        },
        {
          link: '',
          coffee_id: 2,
          price: '21.01',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/2.png',
        },
        {
          link: '',
          coffee_id: 3,
          price: '345.01',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/3.png',
        },
        {
          link: '',
          coffee_id: 4,
          price: '678.01',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/4.png',
        },
        {
          link: '',
          coffee_id: 5,
          price: '101.01',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/5.png',
        },
        {
          link: '',
          coffee_id: 6,
          price: '982.01',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/6.png',
        },
        {
          link: '',
          coffee_id: 7,
          price: '2310.01',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/1.png',
        },
        {
          link: '',
          coffee_id: 8,
          price: '1022.01',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/2.png',
        },
        {
          link: '',
          coffee_id: 9,
          price: '4342.01',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/3.png',
        },
        {
          link: '',
          coffee_id: 10,
          price: '9238.01',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/4.png',
        },
      ]),
      shares: JSON.stringify([
        {
          title: '玩游戏还能免费拿大奖，还有这等好事！',
          description: '宠粉福利',
          icon: '',
        },
        {
          title: '玩游戏还能免费拿大奖，还有这等好事！',
          description: '宠粉福利',
          icon: '',
        },
      ]),
      powerTask: JSON.stringify([
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/target.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/target.png',
        },
      ]),
      qrCode: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/qr.png',
      exchangeRatio: 10,
      coins: miniCoins,
      needExchangeCoins: 1,
    },
    '4': {
      title: appName,
      ruleLink: 'https://shimo.im/docs/9KGXDPcWQdpKwxHX/',
      defaultPower: 18,
      activityType: 4,
      banners: JSON.stringify([
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/cat-banner1.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/cat-banner2.png',
        },
      ]),
      cards: JSON.stringify([
        {
          link: '',
          coffee_id: 1,
          title: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/1.png',
        },
        {
          link: '',
          coffee_id: 2,
          title: '海飞丝',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/2.png',
        },
        {
          link: '',
          coffee_id: 3,
          title: '金龙鱼',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/3.png',
        },
        {
          link: '',
          coffee_id: 4,
          title: '头道原香',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/4.png',
        },
        {
          link: '',
          coffee_id: 5,
          title: '东北大米',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/5.png',
        },
        {
          link: '',
          coffee_id: 6,
          title: '威猛先生',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/6.png',
        },
        {
          link: '',
          coffee_id: 7,
          title: '红烧牛肉面',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/1.png',
        },
        {
          link: '',
          coffee_id: 8,
          title: '沙宣',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/2.png',
        },
        {
          link: '',
          coffee_id: 9,
          title: '香辣牛肉面',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/3.png',
        },
        {
          link: '',
          coffee_id: 10,
          title: '八杯水',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/img/guess/4.png',
        },
      ]),
      shares: JSON.stringify([
        {
          title: '玩游戏还能免费拿大奖，还有这等好事！',
          description: '宠粉福利',
          icon: '',
        },
        {
          title: '玩游戏还能免费拿大奖，还有这等好事！',
          description: '宠粉福利',
          icon: '',
        },
      ]),
      powerTask: JSON.stringify([
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/target.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/target.png',
        },
      ]),
      qrCode: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/qr.png',
      exchangeRatio: 10,
      coins: miniCoins,
      needExchangeCoins: 1,
    },
    '5': {
      'activity_id': '',
      'merchant_id': '',
      'create_time': 0,
      'state': 1,
      'begin_time': moment().format(),
      'end_time': moment().add(7, 'days').format(),
      'user_defined_task': 0,
      'notice_end': 0,
      'need_eight_clock_notice': 1,
      'need_twenty_clock_notice': 1,
      'need_new_friends_notice': 1,
      'need_follow_wechat_notice': 1,
      'need_game_end_notice': 1,
      'need_battle_overstep_notice': 0,
      'need_battle_calc_notice': 0,
      'need_first_cat_notice': 0,
      'need_tomorrow_task_end_notice': 0,
      'need_tomorrow_task_finish_notice': 0,
      'need_tomorrow_task_will_fail_notice': 0,
      'need_dmm_pk_end_notice': 1,
      'need_dmm_rewards_full_notice': 1,
      'notify_mobile': 0,
      'need_coins_notice': 0,
      'pk_need_buy': 0,
      'world_rank_rewards': {
        1: {
          'link': '',
          'image': '',
          'title': '',
        },
        2: {
          'link': '',
          'image': '',
          'title': '',
        },
        3: {
          'link': '',
          'image': '',
          'title': '',
        },
        4: {
          'link': '',
          'image': '',
          'title': '',
        },
        '-1': {
          'link': '',
          'image': '',
          'title': '',
        },
      },
      'max_rank': 4,
      'title': appName,
      'add_coins': miniCoins,
      'rule_link': '活动时间：【xxxx年xx月xx日结束，需商家自己改动】\n' +
        '\n' +
        '如何获得奖品？\n' +
        '【xx商家】会按照排行榜发放奖励，排行榜按照用户的金币数额进行排行。\n' +
        '\n' +
        '如何获得更多金币？\n' +
        '1.合成新的猫\n' +
        '2.创建战队pk获胜\n' +
        '\n' +
        '如何获得更多银币\n' +
        '1、召唤&合成更多猫，猫咪等级越高收益越高\n' +
        '2、点击银币任务，每天早晚8点，银币任务会自动刷新\n' +
        '3、参与战队pk\n' +
        '4、关注公众号提高储蓄罐限额\n' +
        '\n' +
        '一、召唤&合并猫\n' +
        '召唤猫咪获得挂机收益，合并猫咪得到更高级的猫咪和金币，更高级的猫咪银币挂机收益更高。\n' +
        '\n' +
        '二、储蓄罐\n' +
        '离线时的挂机收益会被存储到储蓄罐，储蓄罐的限额为5000银币，关注公众号能够提高限额至20000银币。\n' +
        '\n' +
        '三、猫咪战队pk\n' +
        '使用金币创建战队和其他人进行pk，获胜方能获得金币和银币，失败的用户也能获得银币。\n' +
        '\n' +
        '四、排行榜\n' +
        '按照用户的金币数额进行排行，金币数额相同的按照获得金币的时间排序。\n' +
        '\n' +
        '\n' +
        '本次获得的解释权归【xx，商家名，需改动】所有',
      'default_power': 10000,
      'activity_type': 5,
      'banners': [
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/diediemao/%E7%BB%8416767.png',
        },
      ],
      'cards': [
        {
          link: '',
          coffee_id: 1,
          product_id: '',
          title: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/funiu/%E5%95%86%E5%93%81%E9%85%8D%E7%BD%AE%E6%B5%B7%E6%8A%A5.png',
        }, {
          link: '',
          coffee_id: 1,
          product_id: '',
          title: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/funiu/%E5%95%86%E5%93%81%E9%85%8D%E7%BD%AE%E6%B5%B7%E6%8A%A5.png',
        }, {
          link: '',
          coffee_id: 1,
          product_id: '',
          title: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/funiu/%E5%95%86%E5%93%81%E9%85%8D%E7%BD%AE%E6%B5%B7%E6%8A%A5.png',
        }, {
          link: '',
          coffee_id: 1,
          product_id: '',
          title: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/funiu/%E5%95%86%E5%93%81%E9%85%8D%E7%BD%AE%E6%B5%B7%E6%8A%A5.png',
        },
      ],
      'shares': [
        {
          title: '玩游戏还能免费拿大奖，还有这等好事！',
          description: '宠粉福利',
          icon: '',
        },
        {
          title: '玩游戏还能免费拿大奖，还有这等好事！',
          description: '宠粉福利',
          icon: '',
        },
      ],
      'power_task': [
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/target.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/target.png',
        },
      ],
      qrcode: '',
      exchange_ratio: 10,
      coins: 0,
      total_coins: 0,
      need_exchange_coins: '0',
    },
    '6': {
      'activity_id': '',
      'merchant_id': '',
      'create_time': 0,
      'state': 1,
      'begin_time': moment().format(),
      'end_time': moment().add(7, 'days').format(),
      'user_defined_task': 0,
      'notice_end': 0,
      'need_eight_clock_notice': 1,
      'need_twenty_clock_notice': 1,
      'need_new_friends_notice': 1,
      'need_follow_wechat_notice': 1,
      'need_game_end_notice': 1,
      'need_battle_overstep_notice': 0,
      'need_battle_calc_notice': 0,
      'need_first_cat_notice': 0,
      'need_tomorrow_task_end_notice': 0,
      'need_tomorrow_task_finish_notice': 0,
      'need_tomorrow_task_will_fail_notice': 0,
      'need_dmm_pk_end_notice': 1,
      'need_dmm_rewards_full_notice': 1,
      'notify_mobile': 0,
      'need_coins_notice': 0,
      'pk_need_buy': 0,
      'world_rank_rewards': {
        1: {
          'link': '',
          'image': '',
          'title': '',
        },
        2: {
          'link': '',
          'image': '',
          'title': '',
        },
        3: {
          'link': '',
          'image': '',
          'title': '',
        },
        4: {
          'link': '',
          'image': '',
          'title': '',
        },
        '-1': {
          'link': '',
          'image': '',
          'title': '',
        },
      },
      'max_rank': 4,
      'title': appName,
      'add_coins': miniCoins,
      'rule_link': '活动时间：【xxxx年xx月xx日结束，需商家自己改动】\n' +
        '\n' +
        '如何获得奖品？\n' +
        '【xx商家】会按照排行榜发放奖励，排行榜按照用户的金币数额进行排行。\n' +
        '\n' +
        '如何获得更多金币？\n' +
        '1.合成新的猫\n' +
        '2.创建战队pk获胜\n' +
        '\n' +
        '如何获得更多银币\n' +
        '1、召唤&合成更多猫，猫咪等级越高收益越高\n' +
        '2、点击银币任务，每天早晚8点，银币任务会自动刷新\n' +
        '3、参与战队pk\n' +
        '4、关注公众号提高储蓄罐限额\n' +
        '\n' +
        '一、召唤&合并猫\n' +
        '召唤猫咪获得挂机收益，合并猫咪得到更高级的猫咪和金币，更高级的猫咪银币挂机收益更高。\n' +
        '\n' +
        '二、储蓄罐\n' +
        '离线时的挂机收益会被存储到储蓄罐，储蓄罐的限额为5000银币，关注公众号能够提高限额至20000银币。\n' +
        '\n' +
        '三、猫咪战队pk\n' +
        '使用金币创建战队和其他人进行pk，获胜方能获得金币和银币，失败的用户也能获得银币。\n' +
        '\n' +
        '四、排行榜\n' +
        '按照用户的金币数额进行排行，金币数额相同的按照获得金币的时间排序。\n' +
        '\n' +
        '\n' +
        '本次获得的解释权归【xx，商家名，需改动】所有',
      'default_power': 10000,
      'activity_type': 6,
      'banners': [
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/funiu/%E9%BB%98%E8%AE%A4%E5%B9%BF%E5%91%8A%E5%9B%BE.png',
        },
      ],
      'cards': [
        {
          link: '',
          coffee_id: 1,
          product_id: '',
          title: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/funiu/%E5%95%86%E5%93%81%E9%85%8D%E7%BD%AE%E6%B5%B7%E6%8A%A5.png',
        }, {
          link: '',
          coffee_id: 1,
          product_id: '',
          title: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/funiu/%E5%95%86%E5%93%81%E9%85%8D%E7%BD%AE%E6%B5%B7%E6%8A%A5.png',
        }, {
          link: '',
          coffee_id: 1,
          product_id: '',
          title: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/funiu/%E5%95%86%E5%93%81%E9%85%8D%E7%BD%AE%E6%B5%B7%E6%8A%A5.png',
        }, {
          link: '',
          coffee_id: 1,
          product_id: '',
          title: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/funiu/%E5%95%86%E5%93%81%E9%85%8D%E7%BD%AE%E6%B5%B7%E6%8A%A5.png',
        },
      ],
      'shares': [
        {
          title: '玩游戏还能免费拿大奖，还有这等好事！',
          description: '宠粉福利',
          icon: '',
        },
        {
          title: '玩游戏还能免费拿大奖，还有这等好事！',
          description: '宠粉福利',
          icon: '',
        },
      ],
      'power_task': [
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/funiu/%E9%93%B6%E5%B8%81%E4%BB%BB%E5%8A%A1.png',
        },
        {
          link: '',
          image: 'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/funiu/%E9%93%B6%E5%B8%81%E4%BB%BB%E5%8A%A1.png',
        },
      ],
      qrcode: '',
      exchange_ratio: 10,
      coins: 0,
      total_coins: 0,
      need_exchange_coins: '0',
    },
  };
  return template[activityType];
}
