/**
 * Created by hao.cheng on 2017/5/3.
 */
import React from 'react';
import {getIndexAdImgs, getMessages} from "../../axios/tools";
import { Carousel } from 'antd'

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            adImgs: [],
        };

        this.getSlideMessages().then(r => {});
        this.getAdImgs().then(r => {});
    }
    async getSlideMessages() {
        let msg = await getMessages();
        this.setState({
            messages: msg,
        });
    }

    async getAdImgs() {
        let ads = await getIndexAdImgs();
        this.setState({
            adImgs: ads,
        });
    }

    render() {
        return (
            <div className="gutter-example button-demo">
                <div style={{marginTop:"16px"}}>
                    <Carousel autoplay dots>
                        {
                            this.state.adImgs.map((item, index) => {
                                return <div onClick={() => {window.location.href = item.link;}} key={index}><img alt="" src={item.image}
                                                    style={{ width: '100%' }}
                                                                                                            />
                                </div>;
                            })
                        }
                    </Carousel>
                </div>
                <div style={{marginTop:"16px"}}>
                    <Carousel autoplay dots={false}>
                        {
                            this.state.messages.map((item, index) => {
                                return <div key={index}>{item.text}</div>;
                            })
                        }
                    </Carousel>
                </div>
            </div>
        )
    }
}

export default Dashboard;
