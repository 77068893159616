import React from 'react';
import { Calendar } from 'antd';
import './index.css'

class CalendarComponent extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor (props) {
        super(props)
    }
    onPanelChange(value, mode) {
        console.log(value, mode);
    }
    render () {
        return (<>
            <Calendar fullscreen={false} onPanelChange={this.onPanelChange.bind(this)} />
        </>)
    }
}
export default CalendarComponent
