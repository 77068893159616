import React, { useEffect, useState } from 'react'
import {
    Button,
    Form,
    Input,
    Select,
    Table,
    Space,
    InputNumber,
    Card,
} from 'antd';
import "antd/dist/antd.css";
import './index.scss'
// import moment from 'moment';
const data = [];
// for (let i = 0; i < 46; i++) {
//     data.push({
//         key: i,
//         name: `Edward King ${i}`,
//         stock: Math.round(Math.random()*10000),
//         date: moment(new Date()).format('YY-MM-DD HH:mm')+ '至' + moment(new Date() + Math.round(Math.random()*100000000000)).format('YY-MM-DD HH:mm'),
//         surplus: Math.round(Math.random()*10000),
//         portDate: moment(new Date() - Math.round(Math.random()*100000000000)).format('YY-MM-DD HH:mm'),
//         type: '人人集卡'+ i,
//     });
// }

function TicketImport (props) {
    const [ form ] = Form.useForm()
    const [ tableList, setTableList ] = useState([])
    const columns = [
        {
            title: '券名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '库存量',
            dataIndex: 'stock',
            key: 'stock',
        },
        {
            title: '业务类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '有效时间',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: '剩余库存',
            dataIndex: 'surplus',
            key: 'surplus',
        },
        {
            title: '导入时间',
            dataIndex: 'portDate',
            key: 'portDate',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={()=>onSendTicketImport(record)}>编辑</Button>
                    <Button onClick={()=>onDeleteTicketImport(record)}>删除</Button>
                </Space>
            ),
        },
    ];
    useEffect(() => {
        setTableList(data)
    }, [])
    function onDeleteTicketImport (record) {
        console.log('发送当前人')
    }
    function onSendTicketImport (record) {
        console.log('发送当前人')
    }
    function onReset () {
        setTableList(data)
        form.resetFields()
    }
    function onFinish (value) { // search
        console.log('onFinish', value)
        setTableList(data.filter(e=> {
            return true
        }))
    }


    return (<div className='ticket_import'>
        <Card style={{marginBottom:"24px"}}><div>功能开发中，敬请期待</div></Card>
        <Form form={form} onFinish={onFinish}>
            <div className='ticket_import_flex'>
                <div className='ticket_import_flex_left ticket_import_flex'>
                    <Form.Item name="note1" label="商品名称">
                        <Input placeholder='请输入商品名称' />
                    </Form.Item>
                    <Form.Item name="note2">
                        <Select placeholder='奖品库存' style={{width:'150px'}}>
                            <Select.Option value="全部"></Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="note3" label="库存范围">
                        <div className="ticket_import_flex">
                            <Form.Item name={['note3', 'min']}>
                                <InputNumber placeholder='最少'/>
                            </Form.Item>
                            <Form.Item>至</Form.Item>
                            <Form.Item name={['note3', 'max']}>
                                <InputNumber placeholder='最多'/>
                            </Form.Item>
                        </div>
                    </Form.Item>
                    <Form.Item name="note4">
                        <Select placeholder='全部' style={{width:'150px'}}>
                            <Select.Option value="全部"></Select.Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className='ticket_import_flex_right'>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button htmlType="button" onClick={onReset}>重置</Button>
                    </Form.Item>
                </div>
            </div>
        </Form>
        <Table columns={columns} dataSource={tableList} />
    </div>)
}
export default TicketImport
