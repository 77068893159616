import React from 'react';

import { Button, Card, Notify, Dialog } from "zent"
import { removeWechatAuth, permission } from "../../axios/tools"
import WechatAuth from "../wechat"

import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import {connectAlita} from 'redux-alita';

class Wechat extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      showAuth: false,
      wechatAuth: "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg==",
    }
    this.timeInterval = null
  }
  UNSAFE_componentWillReceiveProps(nextProps, _) {
    this.setState({
      ...nextProps,
    });
  }
  async getUser() {
    let userRet = await permission();
    this.props.setAlitaState({stateName: "auth", data: userRet})
  }
  async removeAuth() {
    let res = await removeWechatAuth();
    Notify.info(res.message);
    this.getUser().then();
  }
  updateState = (data) => {
    console.log('wechat update state......', data)
    this.setState({
      ...data
    })
    console.log("++++++++++++++++++",this.state)
    this.props.updateState({
      ...data
    })
  }
  render() {
    return (<div style={{ maxWidth: "1050px" }}>
      {this.props.auth.data.is_auth_wechat &&
      <Card style={{marginTop:"16px"}}>
        <div style={{ display: "flex" }}>
          <div><img alt="" src={this.props.auth.data.wechat_head_img} style={{ width: "124px", height: "124px" }} /></div>
          <div style={{ width: "16px" }}>&nbsp;</div>
          <div>
            <div>{this.props.auth.data.wechat_nick_name}</div>
            <div style={{height: "16px"}} />
            <div>模板消息：{this.props.auth.data.template_msg_state ? '正常' : '异常'}</div>
            <div style={{height: "16px"}} />
            <div><Button type="danger" onClick={() => {
              this.removeAuth().then()
            }}
                 >移除授权</Button></div>
          </div>
        </div>
      </Card>
      }
      {this.props.auth.data.merchant_id && !this.props.auth.data.is_auth_wechat &&
      <WechatAuth updateState={this.updateState} />
      }
      <div style={{height: "16px"}} />
      <div className="tips" style={{ lineHeight: 1.5 }}>
        <h6 style={{ fontWeight: "700", fontSize: "16px" }}>扫码授权说明：</h6>
        <p>1.请公众号管理员扫码并授权第三方服务权限，授权后可将活动与公众号绑定；</p>
        <img src={image2} alt="" style={{ width: "100%", marginBottom: "24px" }} />
        <p>2.请开通微信公众号的“模板消息”服务，开通后，活动进程将以“模板消息”的形式推送活动进程；</p>
        <img src={image1} alt="" style={{ width: "100%", marginBottom: "24px" }} />
        <p>3.用户关注了公众号后，才能收到关于活动的进程通知；</p>
        <img src={image3} alt="" style={{ width: "200px" }} />
      </div>
      <Dialog
          visible={this.state.showAuth}
          title="扫码授权"
          style={{ width: "264px" }}
          onClose={(e) => {
            this.closeAuth();
            this.setState({
              showAuth: false,
            })
          }}
      >
        <img alt="" src={`data:image/jpeg;base64,${this.state.wechatAuth}`} />
        <div>请授权已认证的微信服务号</div>
      </Dialog>
    </div>)
  }
}
export default connectAlita(['auth'])(Wechat);
