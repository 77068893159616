//引入react jsx写法的必须
import React from 'react';

import EditFanfanle from './editFanfanle';
// import EditCatCoffee from './editCatCoffee';
// import EditGuessPrice from './editGuessPrice';
// import EditGuessProduct from './editGuessProduct';
import EditDiemaomao from './editDiemaomao';
import EditFuniu from './editFuniu';
import EditJika from './jika/editJika';

import moment from 'moment';

import { getActivity } from '../../axios/tools';
import ActivityDefault from './default';
import JikaActivityDefault from './jika/default';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';

moment.locale('zh-cn'); // zh-cn

export default class Edit extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    let activityID = props.query['activityID'];
    let activityType = props.query['activityType'] * 1;
    if (activityID !== '' && activityID !== null && activityID !== '0') {
      this.state = {
        ...props,
        activityType: activityType,
        activityID: activityID,
        init: false,
      };
      this.getActivityDetail().then();
    } else {
      let activityData
      switch (activityType) {
        case 9:
          activityData = JikaActivityDefault('', activityType);
          break;
        default:
          activityData = ActivityDefault('', activityType);
          break;
      }
      console.log(activityData);
      this.state = {
        ...props,
        activityType: activityType,
        activityID: activityID,
        activityDetail: activityData,
        init: true,
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, _) {
    this.setState({
      ...nextProps,
    });
  }

  async getActivityDetail() {
    let ret = await getActivity(this.state.activityID);
    ret.need_exchange_coins = ret.need_exchange_coins + '';
    this.setState({
      activityDetail: ret,
      init: true,
    });
  }

  updateState = (data) => {
    this.setState({
      ...data
    });
  };

  render() {
    return (<div style={{ marginTop: '16px' }}>
      <ConfigProvider locale={zhCN}>
        {
          this.state.init && this.state.activityType === 1 &&
          <EditFanfanle activityType={this.state.activityType}
                        activityID={this.state.activityID}
                        activityDetail={this.state.activityDetail}
                        history={this.props.history}
                        user={this.state.user}
                        updateState={this.updateState}
          />
        }
        {
          this.state.init && this.state.activityType === 9 &&
          <EditJika activityType={this.state.activityType}
                        activityID={this.state.activityID}
                        activityDetail={this.state.activityDetail}
                        history={this.props.history}
                        user={this.state.user}
                        updateState={this.updateState}
          />
        }
        {/*{*/}
        {/*  this.state.init && this.state.activityType === 2 &&*/}
        {/*  <EditCatCoffee activityType={this.state.activityType}*/}
        {/*                 activityID={this.state.activityID}*/}
        {/*                 activityDetail={this.state.activityDetail}*/}
        {/*                 history={this.props.history}*/}
        {/*                 user={this.state.user}*/}
        {/*                 updateState={this.updateState}*/}
        {/*  />*/}
        {/*}*/}
        {/*{*/}
        {/*  this.state.init && this.state.activityType === 3 &&*/}
        {/*  <EditGuessPrice activityType={this.state.activityType}*/}
        {/*                  activityID={this.state.activityID}*/}
        {/*                  activityDetail={this.state.activityDetail}*/}
        {/*                  history={this.props.history}*/}
        {/*                  user={this.state.user}*/}
        {/*                  updateState={this.updateState}*/}
        {/*  />*/}
        {/*}*/}
        {/*{*/}
        {/*  this.state.init && this.state.activityType === 4 &&*/}
        {/*  <EditGuessProduct activityType={this.state.activityType}*/}
        {/*                    activityID={this.state.activityID}*/}
        {/*                    activityDetail={this.state.activityDetail}*/}
        {/*                    history={this.props.history}*/}
        {/*                    user={this.state.user}*/}
        {/*                    updateState={this.updateState}*/}
        {/*  />*/}
        {/*}*/}
        {
          this.state.init && this.state.activityType === 5 &&
          <EditDiemaomao activityType={this.state.activityType}
                         activityID={this.state.activityID}
                         activityDetail={this.state.activityDetail}
                         history={this.props.history}
                         user={this.state.user}
                         updateState={this.updateState}
          />
        }
        {
          this.state.init && this.state.activityType === 6 &&
          <EditFuniu activityType={this.state.activityType}
                     activityID={this.state.activityID}
                     activityDetail={this.state.activityDetail}
                     history={this.props.history}
                     user={this.state.user}
                     updateState={this.updateState}
          />
        }
      </ConfigProvider>
    </div>);
  }
}
