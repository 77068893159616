import {Notify} from 'zent';

/**
 * Created by hao.cheng on 2017/4/28.
 */
// 获取url的参数
export const queryString = () => {
    let _queryString = {};
    const _query = window.location.search.substr(1);
    const _vars = _query.split('&');
    _vars.forEach((v, i) => {
        const _pair = v.split('=');
        if (!_queryString.hasOwnProperty(_pair[0])) {
            _queryString[_pair[0]] = decodeURIComponent(_pair[1]);
        } else if (typeof _queryString[_pair[0]] === 'string') {
            const _arr = [ _queryString[_pair[0]], decodeURIComponent(_pair[1])];
            _queryString[_pair[0]] = _arr;
        } else {
            _queryString[_pair[0]].push(decodeURIComponent(_pair[1]));
        }
    });
    return _queryString;
};
export function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        Notify.error('只可以上传jpg/png图片!');
    }
    const isLt500K = file.size / 1024 < 500;
    if (!isLt500K) {
        Notify.error('图片大小必须小于 500KB!');
    }
    return isJpgOrPng && isLt500K;
}
export const ActivityTypes = {
    1: "翻翻乐",
    2: "喵呜咖啡馆",
    3: "猜价格",
    4: "猜产品名",
    5: "叠叠猫",
    6: "福牛",
    7: "夺宝奇兵",
    8: "红包雨",
    9: "集卡",
    10: "-",
    11: "免费抽",
    12: "0元购",
}
