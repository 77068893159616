import React, { useEffect, useState} from 'react'
import {Form, PageHeader, Button, Input, Card, List} from 'antd';
import { useAlita } from 'redux-alita';

import TagAddDialog from './TagAddGropDialog/index'
import {tagGroups} from '../../../axios/tools';
import TagItem from './TagItem';

const TagManagement = (props) => {
    const [ form ] = Form.useForm();
    const [tagList, setAlita] = useAlita('tagList', { light: true });
    const [page, setPage] = useState({pageSize: 5, current: 1, total: 0});
    const [total, setTotal] = useState(0);
    // 初始化执行一次
    useEffect(() => {
        tagGroups({
            page: 1,
        }).then(res => {
            if (res.code === 0) {
                setAlita({
                    stateName: 'tagList',
                    data: res.data.items,
                })
                setPage(page => { return {
                    current: res.data.page * 1,
                    pageSize: res.data.page_size * 1,
                    total: res.data.total,
                }})
                setTotal(total => res.data.total);
            }
        })
    }, [setAlita])
    const getList = React.useCallback(() => {
        tagGroups({
            page: page.current,
            ...form.getFieldsValue(true)
        }).then(res => {
            if (res.code === 0) {
                setAlita({
                    stateName: 'tagList',
                    data: res.data.items,
                })
                console.log(tagList);
                setTotal(res.data.total);
                setPage({
                    current: res.data.page * 1,
                    pageSize: res.data.page_size * 1,
                    total: res.data.total * 1
                })
            }
        })
    }, [form, page, setAlita, tagList])
    const onFinish = (values) => {
        console.log('Finish:', values, form);
        setPage({
            current: 1,
            pageSize: 5,
            total: 0
        });
        getList();
    };
    const onValuesChange = (value) => {
        if (!value.tagName) {
            onFinish(value)
        }
    }
    const refresh = React.useCallback((add) => {
        if (add) {
            setPage({
                current: 1,
                pageSize: 5,
                total: 0
            });
        }
        getList();
    },[getList])
    const TagItemCallback = React.useCallback((value) => {
        return (<TagItem refresh={refresh} item={value.item} type={value.type}/>)
    },[refresh])
    return (<div>
        <PageHeader
            onBack={() => window.history.back()}
            className="site-page-header"
            title="标签管理"
            extra={
                <Form form={form} name="horizontal_login" layout="inline" onValuesChange={onValuesChange} onFinish={onFinish}>
                    <Form.Item name="group_name" label="搜索">
                        <Input placeholder="请输入标签组名称" />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (<Button type="primary" htmlType="submit">查找</Button>)}
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (<TagAddDialog refresh={refresh} text="添加标签组"/>)}
                    </Form.Item>
                </Form>
            }
        >
        </PageHeader>
        <Card>
            <List
              header={<div>一共{total}条数据</div>}
              dataSource={tagList}
              pagination={{
                  onChange: page => {
                      console.log(page);
                      setPage(page.pagination);
                      getList();
                  },
                  pageSize: page.pageSize,
                  total: total,
              }}
              renderItem={(item) =><TagItemCallback item={item} type={props.type}/>}
            />
        </Card>
    </div>)
}
export default TagManagement
