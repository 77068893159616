import React, { useEffect} from 'react'
import { PageHeader,Card} from 'antd'
import './index.scss'
import TicketImport from './TicketImport/index'
import TicketPort from './TicketPort/index'
import CreateTicket from './CreateTicket/index'
import Vouchers from '../../wechat/vouchers';
import {useAlita} from 'redux-alita';

function Ticket () {
    const [ activeTabKeyTicket = "TicketWechat" , setAlita ] = useAlita( 'activeTabKeyTicket', { light: true })
    useEffect(() => {
        console.log("useEffect............")
    }, [])

    const tabListNoTitle = [
        // {
        //   key: 'TicketImport',
        //   tab: '直接导入券',
        // },
        {
          key: 'TicketWechat',
          tab: '微信支付代金券',
        },
        // {
        //   key: 'TicketPort',
        //   tab: '接口对接券',
        // },
    ];
    // const ticketTab = {
    //     "TicketImport": <TicketImport/>,
    //     "TicketWechat": <Vouchers/>,
    //     "TicketPort": <TicketPort/>,
    // }
    // const TicketComponent = useCallback(() => {
    //     console.log("TicketComponent.........")
    //     return (ticketTab[activeTabKeyTicket])
    // }, [activeTabKeyTicket, ticketTab])


    function onTabChange (key) {
        console.log("onTabChange++++++++++++++++++++++")
        if (activeTabKeyTicket !== key) {
            setAlita({stateName: "activeTabKeyTicket", data: key});
        }

    }
    return (<div className='ticket'>
        <PageHeader title="券" className="site-page-header" extra={
    <CreateTicket/>}
        />
        <Card style={{ width: '100%' }}
            tabList={tabListNoTitle}
            activeTabKey={activeTabKeyTicket}
            onTabChange={key => {
                onTabChange(key);
            }}
        >
            {/*<TicketComponent/>*/}
            {activeTabKeyTicket === "TicketWechat" && <Vouchers/>}
            {activeTabKeyTicket === "TicketImport" && <TicketImport/>}
            {activeTabKeyTicket === "TicketPort" && <TicketPort/>}
        </Card>
    </div>)
}
export default Ticket
