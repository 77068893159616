import React from "react";

export default class LoginOut extends React.Component {
    componentDidMount() {
        console.log("logout component...................")
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        this.props.history.push("/login")
    }
    render() {
        return (<></>)
    }
}
