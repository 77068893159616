//引入react jsx写法的必须
import React from 'react';
import { Select, Dialog, Grid, Button, Notify, Input } from 'zent';
import QrCode from 'qrcode.react';
import moment from 'moment';
import {
  getList,
  getActivityReport,
  stopActivity
} from '../../axios/tools';

import './list.css';

moment.locale('zh-cn'); // zh-cn

export default class List extends React.Component {
  constructor(props) {
    super(props);
    let at = props.query['activityType'] || 5;
    this.state = {
      activityType: at,
      activityTotal: 0,
      activityList: [],
      pageSize: 20,
      page: 1,
      showCopyUrl: false,
      currentRow: {},
      userInfo: {},
      showDownloadUrl: false,
    };
  }

  componentDidMount() {
    const _user = JSON.parse(localStorage.getItem('user')) || {};

    this.setState({
      userInfo: _user
    });
    this.getActivitiesList().then(r => {});
  }


  selectActivityType(e, item) {
    console.log(e, item);
    if (this.state.activityType !== item.value) {
      let that = this;
      setTimeout(function () {
        that.setState({
          activityType: item.value,
          page: 1,
        });
        console.log(that.state);
        that.getActivitiesList().then(r => {});
      }, 1);
    }
  }

  async getActivitiesList() {
    let listRet = await getList(this.state.activityType, this.state.page);
    this.setState({
      activityList: listRet.items || [],
      activityTotal: listRet.total,
      pageSize: listRet.page_size,
    });
    console.log(this.state);
  }

  gridChange(conf) {
    let that = this;
    setTimeout(function () {
      that.setState({
        page: conf.current,
      });
      that.getActivitiesList();
    }, 1);
  }

  copyUrl() {
    document.getElementById('activityUrlCopy').select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      Notify.info('复制成功');
    }
  }

  editActivity(data) {
    console.log('---------------------------------');
    this.props.history.push(
      '/app/activity/edit/?activityID=' + data.activity_id + '&activityType=' +
      data.activity_type);
  }

  downloadReport(activityID) {
    getActivityReport(activityID);
  }

  copyDownloadUrl() {
    document.getElementById('activityDownloadUrlCopy').select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      Notify.info('复制成功');
    }
  }

  async doStopActivity(activityID) {
    let ret = await stopActivity(activityID);
    if (ret.code === 0) {
      this.getActivitiesList().then()
    }
    Notify.info(ret.message);
  }

  render() {
    const activityTypes = [
      {
        label: '翻翻乐',
        value: '1',
      },
      // {
      //   label: '喵呜咖啡馆',
      //   value: '2',
      // },
      // {
      //   label: '猜价格',
      //   value: '3',
      // },
      // {
      //   label: '猜产品名',
      //   value: '4',
      // },
      {
        label: '叠猫猫',
        value: '5',
      },
      {
        label: '福牛叠叠乐',
        value: '6',
      },
      {
        label: '人人集卡',
        value: '9',
      },
    ];
    const columns = [
      {
        'title': '活动名称',
        'name': 'title',
      }, {
        'title': '状态',
        'name': 'state',
        'width': 70,
        'bodyRender': function (data, pos, name) {
          return (<span>
            {
              data.state === 0 ?
                '未开始' :
                (data.state === 1 ? '进行中' : '已结束')
            }
          </span>);
        },
      }, {
        'title': '参加人数',
        'name': 'users_count',
        'width': 80,
      }, {
        'title': '总金币数',
        'name': 'total_coins',
        'width': 80,
      }, {
        'title': '已发放金币数',
        'name': 'paid_coins',
        'width': 120,
      }, {
        'title': '创建时间',
        'name': 'create_time',
        'bodyRender': function (data, pos, name) {
          return (<span>
            {
              moment.unix(data.create_time * 1)
                .format('YYYY-MM-DD HH:mm')
            }
          </span>);
        },
      }, {
        'title': '操作',
        'width': 235,
        'bodyRender': (data, pos, name) => {
          return (
            <span className="actions">
              <span onClick={() => {
                this.setState({
                  currentRow: data,
                });
                this.editActivity(data);
              }}
              >编辑</span>
                &nbsp;|&nbsp;
              <span onClick={() => {
                this.setState({
                  currentRow: data,
                  showCopyUrl: true,
                });
              }}
              >分享</span>
                &nbsp;|&nbsp;
              <span onClick={() => {
                this.downloadReport(data.activity_id);
              }}
              >数据</span>
                &nbsp;|&nbsp;
              <span onClick={() => {
                this.doStopActivity(data.activity_id);
              }}
              >结束</span>
            </span>
          );
        },
      }, {
        'title': '备注',
        'name': 'auto_stop_tip',
        'bodyRender': function (data, pos, name) {
          return (<span>
            {
              data.auto_stop_tip ? '活动即将自动结束，如需延长请联系客服' : ''
            }
          </span>);
        },
      }];
    return (
      <div style={{ width: '100%', overflowX: 'hidden' }}>
        <div style={{margin: '16px 0 16px 0'}}>
          <Select
              data={activityTypes}
              optionValue="value"
              optionText="label"
              value={this.state.activityType}
              onChange={(e, item) => {
                this.selectActivityType(e, item);
              }}
          />
        </div>
        <div>
          <Grid
            columns={columns}
            datasets={this.state.activityList}
            pageInfo={{
              current: this.state.page,
              pageSize: this.state.pageSize,
              total: this.state.activityTotal,
            }}
            onChange={(conf) => {
              this.gridChange(conf);
            }}
            ellipsis
          />
        </div>
        <Dialog
          visible={this.state.showDownloadUrl}
          title="下载活动数据"
          style={{ width: '380px' }}
          onClose={(e) => this.setState({ showDownloadUrl: false })}
        >
          <div style={{ marginBottom: '24px' }}>
            <Input name="activityDownloadUrlCopy" id="activityDownloadUrlCopy"
              readOnly
              value={this.state.downloadUrl}
            />
          </div>
          <div style={{ marginBottom: '24px' }}>
            <Button type="primary" onClick={() => {
              this.copyDownloadUrl();
            }}
            >复制链接</Button>
          </div>
        </Dialog>
        <Dialog
          visible={this.state.showCopyUrl}
          title="活动分享"
          style={{ width: '380px' }}
          onClose={(e) => this.setState({ showCopyUrl: false })}
        >
          <div style={{ marginBottom: '24px' }}>
            <Input name="activityUrl" id="activityUrlCopy" readOnly
              value={this.state.currentRow.share_url}
            />
          </div>
          <div style={{ marginBottom: '24px' }}>
            <Button type="primary" onClick={() => {
              this.copyUrl();
            }}
            >复制链接</Button>
            <Button type="primary" onClick={() => {
              this.editActivity(this.state.currentRow);
            }}
            >编辑</Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {!this.state.userInfo.template_msg_state &&
              <div className="tip-c">
                <div className="title">模板消息异常</div>
                <div className="body">
                  <div>请检查是否:</div>
                  <ul>
                    <li>1.已开通模版清息</li>
                    <li>2.行业选择是互联网-电子商务</li>
                    <li>3.模版息剩余空间&gt;2</li>
                  </ul>
                  <div>更多问题联系 XRM82018</div>
                </div>
              </div>
            }
            <div style={{ textAlign: 'center', paddingTop: '4px' }}>
              <QrCode value={this.state.currentRow.share_url} />
            </div>
          </div>

        </Dialog>
      </div>);
  }
}
