import React, { useEffect, useState } from 'react'
import {
    Card,
    Button,
    Form,
    Input,
    Select,
} from 'antd';
import './index.scss'
import {
    linkAdd, linkDetail, linkUpdate,
} from '../../../axios/tools';
import {useHistory} from 'react-router-dom';
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

function CreateLink (props) {
    const [ form ] = Form.useForm()
    const [oldLink, setOldLink] = useState({})
    const [linkType, setLinkType] = useState(null)
    useHistory();
    useEffect(() => {
        const id = props.linkID
        if (id) {
            linkDetail({link_id: id}).then((res) => {
                if (res.code === 0) {
                    form.setFieldsValue(res.data);
                    setOldLink(res.data);
                    setLinkType(res.data.link_type);
                }
            })
        }
    }, [form, props.linkID])

    function onReset () {
        form.resetFields();
        props.success();
    }

    function onFinish (value) { // search
        if (props.linkID) {
            linkUpdate({
                ...oldLink,
                ...value
            }).then((res) => {
                if (res.code === 0) {
                    onReset();
                    props.success();
                }
            })
        } else {
            linkAdd(value).then((res) => {
                if (res.code === 0) {
                    onReset();
                    props.success();
                }
            })
        }
    }
    return (<div className='create_physical'>
        <Card title="基本信息">
            <Form {...layout} form={form} onFinish={onFinish}>
                <Form.Item name="link_title" label="链接名称" rules={[{required: true,message: '请输入链接名称'}]}>
                    <Input placeholder='请输入链接名称' style={{width: '40%'}}/>
                </Form.Item>
                <Form.Item name="link_type" label="链接类型" rules={[{required: true,message: '请选择链接类型'}]}>
                    <Select placeholder='全部' style={{width:'150px'}} onChange={(value) => {
                        setLinkType(value)
                    }}
                    >
                        <Select.Option value>--</Select.Option>
                        <Select.Option value={1}>H5</Select.Option>
                        <Select.Option value={2}>小程序页面</Select.Option>
                        <Select.Option value={3}>第三方小程序</Select.Option>
                    </Select>
                </Form.Item>
                {linkType === 1 &&
                <Form.Item name="h5_url" label="H5链接" rules={[{required: true,message: '请输入H5链接'}]}>
                    <Input placeholder='请输入H5链接' style={{width: '40%'}}/>
                </Form.Item>
                }
                {linkType === 2 &&
                <Form.Item name="mini_path" label="小程序链接" rules={[{required: true,message: '请输入小程序链接'}]}>
                    <Input placeholder='请输入小程序链接，如：/pages/index' style={{width: '40%'}}/>
                </Form.Item>
                }
                {linkType === 3 &&
                    <>
                        <Form.Item name="mini_app_id" label="小程序APPID" rules={[{required: true,message: '请输入小程序APPID'}]}>
                            <Input placeholder='请输入第三方小程序APPID' style={{width: '40%'}}/>
                        </Form.Item>
                        <Form.Item name="mini_path" label="小程序链接" rules={[{required: true,message: '请输入小程序链接'}]}>
                            <Input placeholder='请输入小程序链接，如：/pages/index' style={{width: '40%'}}/>
                        </Form.Item>
                    </>
                }
                <Form.Item>
                    {/*<Button onClick={() =>window.history.back()}>取消</Button>*/}
                    <Button htmlType="button" onClick={onReset}>取消</Button>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </Card>
    </div>)
}
export default CreateLink
