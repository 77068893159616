import React, {useCallback, useEffect, useState} from 'react';
import {Card, Tag} from 'antd';

import UserAddTagDialog from '../../userList/UserAddTagDialog/index'
import {getUserActivityTypeTag, getUserTags} from '../../../../axios/tools';
import TagItem from '../../tagManagement/TagItem';
const UserDetailTag = (props) => {
    const [activityTags, setActivityTags] = useState({})
    const [tagsGroup, setTagsGroup] = useState([])

    const refresh = useCallback(() => {
        getUserTags({open_id: props.id}).then((res) => {
            if (res.code === 0) {
                setTagsGroup(res.data);
            }
        })
    }, [props.id])

    useEffect(() => {
        getUserActivityTypeTag({open_id: props.id}).then((res) => {
            if (res.code === 0) {
                setActivityTags(res.data);
            }
        })
        refresh();
    }, [props.id, refresh])
    return (<>
        <Card title="参加活动类型">
            {
                activityTags && activityTags.tags &&
                    activityTags.tags.map((tag) => {
                        return <Tag key={tag.tag_id} color="blue" style={{marginBottom:"8px"}}>{tag.tag_name}</Tag>
                    })
            }
        </Card>
        {
            tagsGroup && tagsGroup.items &&
                tagsGroup.items.map((group) => {
                    return <TagItem key={group.group_id} item={group} type="userList"/>
                })
        }
        <div style={{margin: '16px 0 16px 0'}}>
            <UserAddTagDialog id={props.id} type="add" refresh={refresh} tagsGroup={tagsGroup.items ? JSON.parse(JSON.stringify(tagsGroup.items)) : []}/>
        </div>
    </>)
}

export default UserDetailTag
