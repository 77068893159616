// 引入react jsx写法的必须
import React from 'react';
import {Dialog, Notify} from 'zent';
import moment from 'moment';
import {
  permission, wechatUpdateConfig,
} from '../../axios/tools';

import './list.css';
import {Form} from 'antd';
import { Input, Button } from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {connectAlita} from 'redux-alita';

const TextArea = Input.TextArea;
moment.locale('zh-cn'); // zh-cn

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 18},
};
class CreateVouchers extends React.PureComponent {
  constructor(props) {
    console.log("constructor...........");
    super(props);
    this.state = {
      userInfo: props.auth.data,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    console.log("componentDidMount...........");
    permission().then((res) => {
      if (this._isMounted) {
        // this.setState({
        //   userInfo: res
        // })
        this.props.setAlitaState({stateName: "auth", data: res});
      }
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps, _) {
    console.log(nextProps);
    this.setState({
      ...nextProps,
    });
    if (nextProps.auth.data) {
      this.setState({
        userInfo: nextProps.auth.data
      })
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  _isMounted = false;
  saveWechatConfig() {
    wechatUpdateConfig(this.props.auth.data).then((res) => {
      Notify.info(res.message);
      permission().then((u) => {
        // this.setState({
        //   userInfo: u
        // });
        this.props.setAlitaState({stateName: "auth", data: u});
      });
    });
  }
  updateActivityField(field, value) {
    let userInfo = this.props.auth.data;
    userInfo[field] = value;
    this.setState({
      userInfo: userInfo
    });
  }
  render() {
    return (
        <div style={{ width: '100%', overflowX: 'hidden', zIndex: 100000 }}>
          <Dialog
              visible={this.props.showCreateVoucher.data}
              title="微信支付代金券授权"
              style={{ width: '720px' }}
              onClose={() => this.props.setAlitaState({stateName: 'showCreateVoucher', data: false})}
          >
            <div>如需发放微信商家券，请先配置商户号API密钥，授权微信支付发券接口权限</div>
            <div style={{marginBottom:"24px"}}><a href="https://pay.weixin.qq.com/wiki/doc/apiv3/open/pay/chapter5_2_1.shtml" target="_blank" rel="noopener noreferrer">微信支付商家券API简介</a></div>
            <Form
                {...layout}
                name="wechatConfig"
            >
              <Form.Item
                  label="微信支付商户号："
              >
                <Input
                    defaultValue={this.props.auth.data.wechat_mch_id}
                    style={{width: '60%'}}
                    onChange={(e) => {
                      console.log('onchange............');
                      this.updateActivityField('wechat_mch_id', e.target.value);
                    }}
                    onBlur={(e) => {
                      console.log('onblur............');
                      this.updateActivityField('wechat_mch_id', e.target.value);
                    }}
                />
                <span style={{cursor: 'pointer'}} onClick={() => {
                  window.open("https://shimo.im/docs/1d3aVy2yr4F2Baqg");
                }}
                >
              <QuestionCircleOutlined
                  style={{color: '#FC8C03', paddingLeft: '14px'}}
              /><span >获取方式</span>
            </span>
              </Form.Item>
              <Form.Item
                  label="APIv3密钥："
              >
                <Input
                    defaultValue={this.props.auth.data.wechat_api_key}
                    style={{width: '60%'}}
                    onChange={(e) => {
                      console.log('onchange............');
                      this.updateActivityField('wechat_api_key', e.target.value);
                    }}
                    onBlur={(e) => {
                      console.log('onblur............');
                      this.updateActivityField('wechat_api_key', e.target.value);
                    }}
                />
                <span style={{cursor: 'pointer'}} onClick={() => {
                  window.open("https://pay.weixin.qq.com/wiki/doc/apiv3/wechatpay/wechatpay3_2.shtml");
                }}
                >
              <QuestionCircleOutlined
                  style={{color: '#FC8C03', paddingLeft: '14px'}}
              /><span >设置API V3密钥</span>
            </span>
              </Form.Item>
              <Form.Item
                  label="APIv2密钥："
              >
                <Input
                    defaultValue={this.props.auth.data.wechat_api_key_v2}
                    style={{width: '60%'}}
                    onChange={(e) => {
                      console.log('onchange............');
                      this.updateActivityField('wechat_api_key_v2', e.target.value);
                    }}
                    onBlur={(e) => {
                      console.log('onblur............');
                      this.updateActivityField('wechat_api_key_v2', e.target.value);
                    }}
                />
                <span style={{cursor: 'pointer'}} onClick={() => {
                  window.open("https://pay.weixin.qq.com/wiki/doc/apiv3/wechatpay/wechatpay3_2.shtml");
                }}
                >
              <QuestionCircleOutlined
                  style={{color: '#FC8C03', paddingLeft: '14px'}}
              /><span >参考API V3密钥获取</span>
            </span>
              </Form.Item>
              <Form.Item
                  label="微信支付证书序列号："
              >
                <Input
                    defaultValue={this.props.auth.data.wechat_serial_number}
                    style={{width: '60%'}}
                    onChange={(e) => {
                      console.log('onchange............');
                      this.updateActivityField('wechat_serial_number', e.target.value);
                    }}
                    onBlur={(e) => {
                      console.log('onblur............');
                      this.updateActivityField('wechat_serial_number', e.target.value);
                    }}
                />
                <span style={{cursor: 'pointer'}} onClick={() => {
                  window.open("https://pay.weixin.qq.com/wiki/doc/apiv3/wechatpay/wechatpay7_0.shtml#part-5");
                }}
                >
              <QuestionCircleOutlined
                  style={{color: '#FC8C03', paddingLeft: '14px'}}
              /><span
                    style={{}}
                >如何查看证书序列号</span>
            </span>
              </Form.Item>
              <Form.Item
                  label="小程序APPID："
              >
                <Input
                    defaultValue={this.props.auth.data.wechat_app_id}
                    style={{width: '60%'}}
                    onChange={(e) => {
                      console.log('onchange............');
                      this.updateActivityField('wechat_app_id', e.target.value);
                    }}
                    onBlur={(e) => {
                      console.log('onblur............');
                      this.updateActivityField('wechat_app_id', e.target.value);
                    }}
                />
                <span style={{cursor: 'pointer'}} onClick={() => {
                  window.open("https://shimo.im/docs/9030Mr1PnKsd0aqw");
                }}
                >
              <QuestionCircleOutlined
                  style={{color: '#FC8C03', paddingLeft: '14px'}}
              /><span
                    style={{}}
                >如何获取</span>
            </span>
              </Form.Item>
              <Form.Item
                  label="微信支付私钥："
              >
                <TextArea
                    rows="4"
                    defaultValue={this.props.auth.data.wechat_private_key}
                    style={{width: '60%'}}
                    onChange={(e) => {
                      console.log('onchange............');
                      this.updateActivityField('wechat_private_key', e.target.value);
                    }}
                    onBlur={(e) => {
                      console.log('onblur............');
                      this.updateActivityField('wechat_private_key', e.target.value);
                    }}
                />
                <span style={{cursor: 'pointer'}} onClick={() => {
                  window.open("https://pay.weixin.qq.com/wiki/doc/apiv3/wechatpay/wechatpay3_1.shtml");
                }}
                >
              <QuestionCircleOutlined
                  style={{color: '#FC8C03', paddingLeft: '14px'}}
              /><span
                    style={{}}
                >设置导出私钥</span>
            </span>
              </Form.Item>
              <Form.Item>
                <Button type={'primary'} onClick={()=>{
                  this.saveWechatConfig();
                }}
                >保存</Button>
              </Form.Item>
            </Form>
          </Dialog>
        </div>);
  }
}
export default connectAlita(["showCreateVoucher", 'auth'])(CreateVouchers)
