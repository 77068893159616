//引入react jsx写法的必须
import React from 'react';
import {Button, Dialog, Tabs} from 'zent';

import './index.css';

// import CatBg from '../../assets/Bitmap.png';
import DiediemaoBg from '../../assets/diemaomao/homeindex.png';
import FuniuBg from '../../assets/funiu/homeindex.jpg';
import FanfanleBg from '../../assets/bg_fanfanle.png';
// import GuessPriceBg from '../../assets/guess-example/主页.png';
// import GuessTitle from '../../assets/guess-example/guessproducttitle.png';

import {permission} from '../../axios/tools';
import WechatAuth from "../wechat";
import Jika from '../../assets/集卡.png';
import {connectAlita} from 'redux-alita';
// import Tiantianhongbaoyu from '../../assets/tiantianhongbaoyu.png';
// import Tiantianhongbaoyu from '../../assets/天天红包雨.png'

const TabPanel = Tabs.TabPanel;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentActivityTypeTab: 1,
      activeTabId: 'holiday',
    };
  }

  onTabChange(id) {
    this.setState({
      activeTabId: id,
    });
  }

  async addActivity(activityType) {
    let user = await permission()
    this.props.setAlitaState({stateName: "auth", data: user});
    if (activityType !== 9 && user.is_auth_wechat === false) {
      Dialog.openDialog({
        dialogId: 'create-activity-no-coins', // id is used to close the dialog
        title: '错误提示',
        children: '请先绑定认证的微信服务号',
        footer: <Button onClick={() => Dialog.closeDialog(
            'create-activity-no-coins')}
                >关闭</Button>,
        onClose() {
          Dialog.closeDialog('create-activity-no-coins');
          console.log('outer dialog closed');
        },
      });
      return;
    }
    if (user.coins < 5000) {
      Dialog.openDialog({
        dialogId: 'create-activity-no-coins', // id is used to close the dialog
        title: '错误提示',
        children: '金币不足，最低5000金币，请先充值',
        footer: <Button onClick={() => Dialog.closeDialog(
            'create-activity-no-coins')}
                >关闭</Button>,
        onClose() {
          Dialog.closeDialog('create-activity-no-coins');
          console.log('outer dialog closed');
        },
      });
      return;
    }
    this.props.history.push('/app/activity/edit/?activityID=&activityType=' + activityType);
  }

  render() {
    console.log('Home+++++++++++++++++', this.props)
    return (
        <div>
          {this.props.auth.data.merchant_id && !this.props.auth.data.is_auth_wechat &&
          <WechatAuth style={{marginBottom:"16px"}} />
          }
          <div style={{width: '100%',marginTop:"16px"}}>
            <Tabs activeId={this.state.activeTabId} onChange={(id) => {
              this.onTabChange(id);
            }} type="card"
            >
              <TabPanel tab="节假日促销活动" id="holiday">
                <div className="type-list">
                  <div className="item">
                    <img alt="" src={DiediemaoBg} width="166" height="296" align="center"/>
                    <div className="type-title">《叠叠猫》</div>
                    <div className="item-description">
                      激活用户，复购导流。次留20%以上，裂变30%以上
                    </div>
                    <div className="action">
                      <Button type="primary" className="new-activity-btn"
                              onClick={() => {
                                this.addActivity(5).then();
                              }}
                      >一键发布</Button>
                    </div>
                  </div>
                  <div className="item">
                    <img alt="" src={FuniuBg} width="166" height="296" align="center"/>
                    <div className="type-title">《福牛叠叠乐》</div>
                    <div className="item-description">
                      激活用户，复购导流。次留20%以上，裂变30%以上
                    </div>
                    <div className="action">
                      <Button type="primary" className="new-activity-btn"
                              onClick={() => {
                                this.addActivity(6).then();
                              }}
                      >一键发布</Button>
                    </div>
                  </div>
                  <div className="item">
                    <img alt="" src={FanfanleBg} width="166" height="296"
                         align="center"
                    />
                    <div className="type-title">《翻翻乐》</div>
                    <div className="item-description">
                      种草转化神器，适合新品/爆品快速种草曝光
                    </div>
                    <div className="action">
                      <Button type="primary" className="new-activity-btn"
                              onClick={() => {
                                this.addActivity(1).then();
                              }}
                      >一键发布</Button>
                    </div>
                  </div>
                  <div className="item">
                    <img alt="" src={Jika} width="166" height="296"
                         align="center"
                    />
                    <div className="type-title">《集卡》</div>
                    <div className="item-description">
                      种草转化神器，适合新品/爆品快速种草曝光
                    </div>
                    <div className="action">
                      <Button type="primary" className="new-activity-btn"
                              onClick={() => {
                                this.addActivity(9).then();
                              }}
                      >一键发布</Button>
                    </div>
                  </div>
                  {/*<div className="item">*/}
                  {/*  <img alt="" src={Tiantianhongbaoyu} width="166" height="296"*/}
                  {/*       align="center"*/}
                  {/*  />*/}
                  {/*  <div className="type-title">《天天红包雨》</div>*/}
                  {/*  <div className="item-description">*/}
                  {/*    活动促销转化，快速引爆活跃度和转化*/}
                  {/*  </div>*/}
                  {/*  <div className="action">*/}
                  {/*    <Button type="primary" className="new-activity-btn"*/}
                  {/*            onClick={(e) => {*/}
                  {/*              // this.addActivity(9).then(r => {});*/}
                  {/*              this.props.history.push('/app/create/active/index?type=basicModule')*/}
                  {/*            }}*/}
                  {/*    >一键发布</Button>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="item">*/}
                  {/*  <img alt="" src={GuessPriceBg} width="166" height="296"*/}
                  {/*       align="center"/>*/}
                  {/*  <div className="type-title">《猜价格》</div>*/}
                  {/*  <div className="item-description">*/}
                  {/*    消耗用户积分。商品和价格强关联。适用于商品促销*/}
                  {/*  </div>*/}
                  {/*  <div className="action">*/}
                  {/*    <Button type="primary" className="new-activity-btn"*/}
                  {/*            onClick={(e) => {*/}
                  {/*              this.addActivity(3);*/}
                  {/*            }}>一键发布</Button>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="item">*/}
                  {/*  <img src={GuessTitle} width="166" height="296"*/}
                  {/*       align="center"/>*/}
                  {/*  <div className="type-title">《猜产品名》</div>*/}
                  {/*  <div className="item-description">*/}
                  {/*    消耗用户积分，适合用于新品曝光，导流转化*/}
                  {/*  </div>*/}
                  {/*  <div className="action">*/}
                  {/*    <Button type="primary" className="new-activity-btn"*/}
                  {/*            onClick={(e) => {*/}
                  {/*              this.addActivity(4);*/}
                  {/*            }}>一键发布</Button>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </TabPanel>
              <TabPanel tab="日常签到打卡活动" id="sign">
                <div className="type-list">
                  {/*<div className="item">*/}
                  {/*  <img src={CatBg} width="166" height="296" align="center"/>*/}
                  {/*  <div className="type-title">《喵呜咖啡馆》</div>*/}
                  {/*  <div className="item-description">*/}
                  {/*    打卡利器，一周人均逛店15次，显著提升店铺流量*/}
                  {/*  </div>*/}
                  {/*  <div className="action">*/}
                  {/*    <Button type="primary" className="new-activity-btn"*/}
                  {/*            onClick={(e) => {*/}
                  {/*              this.addActivity(2);*/}
                  {/*            }}>一键发布</Button>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>);
  }
}
export default connectAlita(['auth'])(Home)
