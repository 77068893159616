//引入react jsx写法的必须
import React from "react";
//路由库
import { Dialog, Notify } from "zent";
import "./editJika.scss";
import moment from "moment";

import { createActivity, getAccountInfo, updateActivity } from "../../../axios/tools";

import BasicInfoEdit from "./basicInfoEdit";
import EnvironmentModule from './EnvironmentModule/index' // 环境模块
import {Card, Col, Modal, PageHeader, Row, Form, Button, Input} from 'antd';
import {NavLink} from 'react-router-dom';

moment.locale("zh-cn"); // zh-cn
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export default class EditJika extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    let activityDetail = props.activityDetail;
    activityDetail.firstRewardRatio = 0;
    activityDetail.secondRewardRatio = 0;
    activityDetail.thirdRewardRatio = 0;
    activityDetail = this.updateRewardRatio(activityDetail);
    this.state = {
      activityType: props.activityType,
      activityID: props.activityID,
      activityDetail: activityDetail,
      activeTabId: "basicModule",
      currentStep: 0,
      showCopyUrl: false,
      showMoreJoin: false,
      finish: 0,
      userInfo: {},
      qrcode: "",
      pathName: "basicModule"
    };
    this.getUserInfo().then();
  }

  form = React.createRef()

  async getUserInfo() {
    let userRet = await getAccountInfo();
    this.setState({
      userInfo: userRet,
    });
  }

  async saveActivity() {
    let activityData = JSON.parse(JSON.stringify(this.state.activityDetail));
    activityData.need_exchange_coins = activityData.need_exchange_coins * 1;
    activityData.default_power = activityData.default_power * 1;
    activityData.exchange_ratio = activityData.exchange_ratio * 1;
    activityData.notify_mobile = activityData.notify_mobile * 1;
    activityData.end_time = moment(activityData.end_time).format("YYYY-MM-DDTHH:mm:ssZ");
    activityData.begin_time = moment(activityData.begin_time).format('YYYY-MM-DDTHH:mm:ssZ');
    activityData.world_rank_rewards[1].id = activityData.world_rank_rewards[1].id * 1;
    activityData.world_rank_rewards[2].id = activityData.world_rank_rewards[2].id * 1;
    activityData.world_rank_rewards[3].id = activityData.world_rank_rewards[3].id * 1;
    if (activityData.brand_name.length < 1 || activityData.brand_name.length > 12) {
      Modal.error({
        title: "发布失败",
        content: "请输入正确的品牌名称，1-6汉字",
      });
      return false;
    }
    if (this.state.activityID.length > 1) {
      let res = await updateActivity(activityData);
      if (res.data.code === 0) {
        res.data.data.need_exchange_coins = res.data.data.need_exchange_coins + "";
        this.setState({
          showCopyUrl: true,
          activityID: res.data.data.activity_id,
          activityType: res.data.data.activity_type,
          activityDetail: res.data.data,
        });
        return true
      } else {
        Notify.info(res.data.message);
        return false
      }
    } else {
      let res = await createActivity(activityData);
      if (res.data.code === 0) {
        res.data.data.need_exchange_coins = res.data.data.need_exchange_coins + "";
        res.data.data.firstRewardRatio = 0;
        res.data.data.secondRewardRatio = 0;
        res.data.data.thirdRewardRatio = 0;
        res.data.data = this.updateRewardRatio(res.data.data);
        this.setState({
          showCopyUrl: true,
          activityID: res.data.data.activity_id,
          activityType: res.data.data.activity_type,
          activityDetail: res.data.data,
        });
        return true
      } else {
        Notify.info(res.data.message);
        return false
      }
    }
  }

  copyUrl(value) {
    const temp = document.createElement('input');
    document.body.append(temp);
    temp.value = value;
    temp.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      Notify.info("复制成功");
    }
    temp.remove();
  }

  updateActivityField(field, value) {
    let ac = this.state.activityDetail;
    ac[field] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  showMoreJoinTips() {
    this.setState({
      showMoreJoin: true,
    });
  }
  updateRewardRatio(activityDetail) {
    let totalNumber = 0;
    let rate = [];
    activityDetail.cards.map((item, index) => {
      if (index > 5) {
        return item;
      }
      console.log("item.number+++++++++++++++++", item.number);
      rate.push(item.number);
      if (item.number) {
        totalNumber += item.number;
      }
      return item;
    });

    function sortNumber(a, b) {
      return a - b;
    }
    rate.sort(sortNumber);
    activityDetail.firstRewardRatio = (Math.round((rate[0] / totalNumber) * 1000000) / 10000).toFixed(4);
    activityDetail.secondRewardRatio = (Math.round((rate[1] / totalNumber) * 1000000) / 10000).toFixed(4);
    activityDetail.thirdRewardRatio = (Math.round((rate[3] / totalNumber) * 1000000) / 10000).toFixed(4);
    return activityDetail;
  }
  onTabComponent(tab) {
    this.setState({
      pathName: tab
    })
  }
  updateState(data) {
    console.log(data);
    this.setState(data);
    data.activityDetail && this.form.current.setFieldsValue(data.activityDetail)
  }
  render() {
    return (
      <div className="new-activity-wrapper-jika">
        <PageHeader
            onBack={() => this.props.history.push('/app/activity/home')}
            className="site-page-header"
            size="small"
            ghost={false}
            title="人人集卡"
            extra={ [<div className='create_active_tab_button' key="createdButton">
              <span className={this.state.pathName === 'basicModule' ? 'active' : ''} onClick={()=>this.onTabComponent('basicModule')}>基本模块</span>
              <span className={this.state.pathName === 'environmentModule' ? 'active' : ''} onClick={()=>this.onTabComponent('environmentModule')}>对接配置</span>
            </div>]}
        >
        </PageHeader>
        <Row gutter={24} style={{height: '100%', margin: '20px 0',}}>
          <Col span={17} style={{padding: '0'}}>
            <Form {...layout} name="basic" ref={this.form} initialValues={this.state.activityDetail} onFinish={this.saveActivity.bind(this)}>
            {this.state.pathName === "basicModule" && <BasicInfoEdit activityDetail={{...this.state.activityDetail}}
    updateState={this.updateState.bind(this)}
                                                                     updateRewardRatio={this.updateRewardRatio}
                                                      />}
            {this.state.pathName === "environmentModule" && <EnvironmentModule activityDetail={{...this.state.activityDetail}}
    updateState={this.updateState.bind(this)}
                                                                               saveActivity={this.saveActivity}
                                                            />}
            </Form>
          </Col>
          <Col span={7}>
            <Card>
              <div className="preview">
                <div className="preview-img">
                  <div className="preview-content"/>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Modal title="活动地址" visible={this.state.showCopyUrl} onOk={() => {this.setState({ showCopyUrl: false })}} onCancel={() => {this.setState({ showCopyUrl: false })}} width={800}
               footer={[
                 <NavLink
                     to={`/app/activity/list?activityID=${this.state.activityID}&activityType=9`}
                     style={{ marginRight: "16px" }}
                     key={`/app/activity/list?activityID=${this.state.activityID}&activityType=9`}
                 >
                   <Button type="secondary" key="known" onClick={() => {}}>
                     知道了
                   </Button>
                 </NavLink>
               ]}
        >
          <Form>
            <Form.Item label="活动类型">人人集卡</Form.Item>
            <Form.Item label="活动名称">{this.state.activityDetail.title}</Form.Item>
            <Form.Item label="活动地址" name="share_url">
              <div style={{display: "flex"}}>
                <Input id="activityUrlCopy" style={{width: '200px', marginRight:"16px"}} disabled defaultValue={this.state.activityDetail.share_url}/><Button onClick={() => this.copyUrl(this.state.activityDetail.share_url)}>一键复制</Button>
              </div>
            </Form.Item>
          </Form>
          <Card>
            恭喜您成功创建了小程序插件活动，接下来您可以将活动植入您的小程序内。
            <div><a href="https://mp.weixin.qq.com/wxopen/plugindevdoc?appid=wx8e0f3ffa85ee9967&token=&lang=zh_CN" target="_blank" rel="noreferrer noopener">如何添加小程序插件？</a></div>
            <div><a href="https://shimo.im/docs/gO3ox4xrWWuayjqD" target="_blank" rel="noreferrer noopener">如何将活动植入商城？</a></div>
          </Card>
        </Modal>
        <Dialog
          visible={this.state.showMoreJoin}
          title="如何让更多人参与活动"
          style={{ width: "900px" }}
          onClose={() => this.setState({ showMoreJoin: false })}
        >
          <p>
            <img
              src="https://img.yzcdn.cn/upload_files/2020/04/01/FujHu3vDSyWJy1TWmMAXBqkfezM6.png"
              width="840"
              data-origin-width="1000"
              data-origin-height="563"
              alt=""
            />
          </p>
          <p>
            <img
              src="https://img.yzcdn.cn/upload_files/2020/04/01/FpMtTITCLLENJerIqvGekaefYqLp.png"
              width="840"
              data-origin-width="1000"
              data-origin-height="563"
             alt=""
            />
          </p>
          <p>
            <img
              src="https://img.yzcdn.cn/upload_files/2020/04/01/Fq-WebcSWBzDfeN864cUh3gSRDHL.png"
              width="840"
              data-origin-width="1000"
              data-origin-height="563"
             alt=""
            />
          </p>
        </Dialog>
      </div>
    );
  }
}
