import React, { useState, useEffect} from 'react'
import { Modal, Button, Form, Input } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
  tagGroupAdd,
  tagGroupDelete,
  tagGroupUpdate,
} from '../../../../axios/tools';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

function TagAddDialog (props) {
  const [ form ] = Form.useForm();
  const [buttonText] = useState(props.text ? props.text : '添加')
  const [ loading, setLoading ] = useState(false)
  const [ visible, setVisible ] = useState(false)
  const [ dialogTitle, setDialogTitle ] = useState('新建标签组')

  useEffect(() => {
  },[])

  // 监听表单打开是新建还是保存
  useEffect(() => {
    if (props.item) {
      form.setFieldsValue({
        ...props.item
      });
      setDialogTitle('编辑标签组')
    } else {
      form.resetFields();
      form.setFieldsValue({
        tag:[undefined]
      })
      setDialogTitle('新建标签组')
    }
  }, [form, props.item, visible])

  function showModal () {
    setVisible(true)
  }
  function handleOk () {
    form.submit()
  }
  function handleCancel () {
    setVisible(false)
  }
  function handleDelete () {
    setLoading(true)
    tagGroupDelete(props.item).then(res => {
      if (res.code === 0) {
        props.refresh(false)
        setVisible(false)
      }
      setLoading(false)
    }).catch(e => {
      console.log(e);
      setLoading(false)
    })
  }
  // 当触发 form.submit() 表单验证成功后自动调用 onFinish
  const onFinish = (values) => {
    console.log('Finish:', values);
    values.tags = values.tags.filter(e => e)
    setLoading(true)
    if (props.item) {
      tagGroupUpdate(values).then(res => {
        if (res.code === 0) {
          props.refresh(false)
          setVisible(false)
        }
        setLoading(false)
      }).catch(e => {
        console.log(e);
        setLoading(false)
      })
    } else {
      tagGroupAdd(values).then(res => {
        if (res.code === 0) {
          props.refresh(true)
          setVisible(false)
        }
        setLoading(false)
      }).catch(e => {
        console.log(e);
        setLoading(false)
      })
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>{ buttonText }</Button>
      <Modal
        width={800}
        visible={visible}
        title={dialogTitle}
        onCancel={handleCancel}
        footer={[
          props.item ? <Button key="delete" onClick={handleDelete}>删除标签组</Button>: '',
          <Button key="back" onClick={handleCancel}>关闭</Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>保存</Button>,
        ]}
      >
        {/*<div style={{fontSize: '12px',textAlign:'center', padding: '5px 10px',margin: '-24px 0 5px', background: '#effaff'}}>*/}
        {/*  <BellOutlined style={{ color: '#1890ff' }} />*/}
        {/*  <span>管理员可根据不同部门选择展示不同的标签组，部门管理员可创建部门可见的标签</span>*/}
        {/*</div>*/}
        <Form form={form} name="control-hooks" onFinish={onFinish} {...layout}>
          <Form.Item hidden name="group_id"><Input/></Form.Item>
          <Form.Item hidden name="merchant_id"><Input/></Form.Item>
          <Form.Item name="group_name" label="标签组名称" rules={[{ required: true, message: '请输入名称' }]}>
            <Input />
          </Form.Item>
          <Form.List name="tags">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? '标签名称' : ''}
                    required={false}
                    key={key}
                  >
                    <Form.Item hidden {...restField} name={[name, 'tag_id']}><Input /></Form.Item>
                    <Form.Item hidden {...restField} name={[name, 'group_id']}><Input /></Form.Item>
                    <Form.Item {...restField} name={[name, 'tag_name']} validateTrigger={['onChange', 'onBlur']} noStyle>
                      <Input placeholder="输入标签" style={{ width: '60%' }} />
                    </Form.Item> {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item {...formItemLayoutWithOutLabel}>
                  <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                    添加标签
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
}

export default TagAddDialog
