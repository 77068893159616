/**
 * Created by hao.cheng on 2017/4/17.
 */
import React from 'react';
import ReactEcharts from 'echarts-for-react';
const option = {
    color: [
        'rgba(77, 174, 245,1)',
        'rgba(245, 154, 35,1)',
        'rgba(239,219,200,1)',
        'rgba(137,246,100,1)',
        'rgba(155,314,203,1)',
        'rgba(155,155,146)',
        'rgba(111,222,100,1)',
    ],
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            crossStyle: {
                color: '#999',
            },
        },
    },
    toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
    },
    legend: {
        left: 'left',
        bottom: '0',
        data: ['活动一', '活动二'],
    },
    xAxis: [
        {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisPointer: {
                type: 'shadow',
            },
        },
    ],
    yAxis: [
        {
            type: 'value',
            name: '数量',
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
                formatter: '{value}',
            },
        },
    ],
    series: [
        {
            name: '活动一',
            type: 'line',
            tooltip: {
                valueFormatter: function (value) {
                    return value;
                },
            },
            data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
        },
        {
            name: '活动二',
            type: 'line',
            tooltip: {
                valueFormatter: function (value) {
                    return value;
                },
            },
            data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3],
        },
    ],
};

class EchartsComponent extends React.Component {
    // constructor(props) {
    //     super(props);
    //     const { options } = props;
    //     this.state = {
    //         option: { ...option, ...options },
    //     };
    // }
    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.options !== this.props.options) {
    //       console.log()
    //         this.setState({ option: { ...option, ...nextProps.options } });
    //     }
    // }
    render() {
        return (
            <ReactEcharts
                option={{ ...option, ...this.props.options }}
                style={{ height: '300px', width: '100%' }}
                className={'react_for_echarts'}
            />
        );
    }
}

export default EchartsComponent;
