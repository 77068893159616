//引入react jsx写法的必须
import React from 'react';
import { Form, Input, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { Notify, Button, Card } from 'zent';

import fanfanleCardExample from '../../assets/体力任务示例.png';
import catCoffeeExample from '../../assets/cat体力任务示例.png';
import guessPriceExample from '../../assets/guess-example/金币任务实例.png';
import diemaomaoExample from '../../assets/diemaomao/task-example.jpg';
import funiuExample from '../../assets/funiu/task-example.jpg';

import {
  getToken,
  getApiBase,
} from '../../axios/tools';
import explainPic from '../../assets/体力任务.png';

const uploadURI = getApiBase() + 'youzan/admin/upload';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    Notify.error('只可以上传jpg/png图片!');
  }
  const isLt500K = file.size / 1024 < 500;
  if (!isLt500K) {
    Notify.error('图片大小必须小于 500KB!');
  }
  return isJpgOrPng && isLt500K;
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export default class CardEdit extends React.Component {
  constructor(props) {
    super(props);
    console.log('init...............', props);
    let formRef = React.createRef();

    this.state = {
      ...props,
      uploading: false,
      formRef: formRef,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('next props.............', nextProps);

    this.setState({
      ...nextProps,
    });
  }

  updateActivityField(field, value) {
    let ac = this.state.activityDetail;
    ac[field] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  updateActivityBanner(field, index, type, value) {
    let ac = this.state.activityDetail;
    ac[field][index][type] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  handleChange(info, index) {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      let res = info.file.response;
      if (res.code !== 0) {
        return Notify.error(res.message);
      }
      let imageUrl = res.data.image_url;
      this.updateActivityBanner('power_task', index, 'image', imageUrl);
      this.updateActivityField('user_defined_task', 1);
      this.setState({ uploading: false });
    }
  };

  render() {
    const uploadExtra = {
      token: getToken(),
    };
    const uploadButton = (
      <div>
        {this.state.uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传</div>
      </div>
    );
    const taskSize = {
      1: '360X446',
      2: '360X446',
      3: '360X446',
      4: '360X446',
      5: '360X446',
      6: '360X446',
    };
    const taskExample = {
      1: fanfanleCardExample,
      2: catCoffeeExample,
      3: guessPriceExample,
      4: guessPriceExample,
      5: diemaomaoExample,
      6: funiuExample,
    };
    return (
      <Form
        {...layout}
        name="tasks"
        ref={this.state.formRef}
      >
        <Card style={{ marginBottom: '24px' }}>
          <div style={{
            display: 'flex',
            justifyContent: 'left',
          }}
          >
            <div><img alt="" src={explainPic} /></div>
            <div style={{ marginLeft: '24px' }}>
              <div>设置建议：</div>
              <div>1、一句话写清出产品/活动特点<br />
                  2、优质产品图片能够有更好的种草效果<br />
                  3、建议内容：
                  <ul style={{ paddingLeft: '24px' }}>
                  <li>a）砍价，拼团，具有较高的裂变性，并且新/老用户参与度都比较高</li>
                  <li>b）超低价拉新：9.9元 或者 19.9 元即可购买价值感40元以上单品，对新/老用户吸引力都比较大</li>
                  <li>c）无门槛5%-10%优惠券，有门槛15%以上优惠券</li>
                </ul>
              </div>
            </div>
          </div>
        </Card>
        <div style={{
          display: 'flex',
          justifyContent: 'left',
          flexWrap: 'wrap',
        }}
        >
          {
            this.state.activityDetail.power_task.map((item, index) => {
              return (<Card className="card-half-width" style={{
                marginBottom: '24px',
                marginLeft: index % 2 === 1 ? '24px' : '0',
              }} key={index}
                      >
                <Form.Item
                  label={`链接${index + 1}：`}
                  name={`tasklink${index}`}
                >
                  <Input
                      defaultValue={item.link}
                    onChange={(e) => {
                      console.log('onchange............');
                      this.updateActivityBanner('power_task', index, 'link',
                        e.target.value);
                      this.updateActivityField('user_defined_task', 1);
                    }}
                  />
                  <span>请输入http://或https://开头的链接</span>
                </Form.Item>
                <Form.Item
                  className="uploader"
                  label={'图片' + (index + 1) + '：'}
                  valuePropName="fileList"
                  key={index}
                  style={{ marginBottom: 0 }}
                >
                  <Upload
                    name="file"
                    listType="picture-card"
                    className="task-uploader"
                    showUploadList={false}
                    action={uploadURI}
                    data={uploadExtra}
                    beforeUpload={beforeUpload}
                    onChange={(info) => {
                      this.handleChange(info, index);
                    }}
                  >
                    {this.state.activityDetail.power_task[index]['image'] ?
                      <img
                        src={this.state.activityDetail.power_task[index]['image']}
                        alt="avatar" style={{ width: '100%' }}
                      /> :
                      uploadButton}
                  </Upload>
                  <div className="uploader-tips">
                    * {taskSize[this.state.activityDetail.activity_type]}，≤500k
                      <br />* 点击上传
                    </div>
                </Form.Item>
              </Card>);
            })
          }
        </div>
        <Form.Item>
          <Button type="primary" icon="plus" onClick={() => {
            let tasks = this.state.activityDetail.power_task;
            tasks.push({
              image: '',
              link: '',
              id: '',
            },
              {
                image: '',
                link: '',
                id: '',
              });
            this.updateActivityField('power_task', tasks);
          }}
          >增加两项</Button>
          <Button type="danger" icon="close" onClick={() => {
            if (this.state.activityDetail.power_task.length <= 2) return;
            let tasks = this.state.activityDetail.power_task;
            tasks.splice(tasks - 2, 2);
            this.updateActivityField('power_task', tasks);
          }}
          >删除最后两项</Button>
        </Form.Item>
        <Card>
          <div className="task-example">
            <div className="example-title">任务示例</div>
            <img alt="" src={taskExample[this.state.activityDetail.activity_type]} />
          </div>
        </Card>
      </Form>
    );
  }
}