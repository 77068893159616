//引入react jsx写法的必须
import React from 'react';
import {
  NavLink,
} from 'react-router-dom'; //路由库
import {Button, Input} from "antd"
import {
  Notify,
  Dialog,
} from 'zent';

import './editFanfanle.scss';

import moment from 'moment';

import {
  updateActivity,
  createActivity, permission,
} from '../../axios/tools';
import BasicInfoEdit from './basicInfoEdit';
import BannerEdit from './bannerEdit';
import CardEdit from './cardEdit';
import TaskEdit from './taskEdit';
import ShareEdit from './shareEdit';
import CoinsAndScore from './coinsAndScore';
import {Steps} from 'antd';
import QrCode from 'qrcode.react';
import {QuestionCircleOutlined} from "@ant-design/icons";
import code1 from "../../assets/code/image001.png";
import code2 from "../../assets/code/image002.png";
import code3 from "../../assets/code/image003.png";
import code4 from "../../assets/code/image004.png";
import code5 from "../../assets/code/image005.png";
import code6 from "../../assets/code/image006.png";
import code7 from "../../assets/code/image007.png";
import code8 from "../../assets/code/image008.png";
import code9 from "../../assets/code/image009.png";

moment.locale('zh-cn'); // zh-cn
const {Step} = Steps;

export default class EditDiemaomao extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      ...props,
      activeTabId: 'base',
      currentStep: 0,
      showCopyUrl: false,
      showMoreJoin: false,
      showProductIDNotFinish: false,
      showProductCodeTips: false,
      finish:0,
      user: {},
    };
    this.getUserInfo();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.activityDetail !== this.props.activityDetail) {
      this.setState({
        ...nextProps,
      });
    }
    if (nextProps.user !== this.props.user) {
      this.setState({
        ...nextProps,
      });
    }
  }

  async getUserInfo() {
    let userRet = await permission();
    this.setState({
      user: userRet,
    });
    this.props.updateState({
      user: userRet,
      auth: { data: userRet }
    })
  }

  async saveActivity() {
    let activityData = JSON.parse(JSON.stringify(this.state.activityDetail));
    activityData.need_exchange_coins = activityData.need_exchange_coins * 1;
    activityData.default_power = activityData.default_power * 1;
    activityData.exchange_ratio = activityData.exchange_ratio * 1;
    activityData.notify_mobile = activityData.notify_mobile * 1;
    if (this.state.activityID.length > 1) {
      let res = await updateActivity(activityData);
      Notify.info(res.data.message);
      if (res.data.code === 0) {
        res.data.data.need_exchange_coins = res.data.data.need_exchange_coins + '';
        this.props.updateState({
          activityDetail: res.data.data,
        })
        this.setState({
          showCopyUrl: true,
          activityID: res.data.data.activity_id,
          activityType: res.data.data.activity_type,
          activityDetail: res.data.data,
        });
        this.getUserInfo().then()
      }
    } else {
      let res = await createActivity(activityData);
      if (res.data.code === 0) {
        res.data.data.need_exchange_coins = res.data.data.need_exchange_coins + '';
        this.props.updateState({
          activityDetail: res.data.data,
        })
        this.setState({
          showCopyUrl: true,
          activityID: res.data.data.activity_id,
          activityType: res.data.data.activity_type,
          activityDetail: res.data.data,
        });
        this.getUserInfo().then()
      }
      Notify.info(res.data.message);
    }
  }

  copyUrl() {
    document.getElementById('activityUrlCopy').select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      Notify.info('复制成功');
    }
  }
  updateActivityField(field, value) {
    let ac = this.state.activityDetail;
    ac[field] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  showMoreJoinTips() {
    this.setState({
      showMoreJoin: true,
    });
  }

  render() {
    return (<div style={{display: 'flex'}}
                 className="new-activity-wrapper-fanfanle"
            >
      <div>
        <div className="preview">
          <div className="preview-img">
            <div className="preview-content">
              <div
                  className="preview-title"
              >{this.state.activityDetail.title}</div>
              <div className="banner">
                {this.state.activityDetail.banners &&
                this.state.activityDetail.banners[0].image &&
                <img alt="" src={this.state.activityDetail.banners[0].image} />
                }
              </div>
              <div className="banner-wrapper">
                {this.state.activityDetail.power_task &&
                this.state.activityDetail.power_task.map((item, index) => {
                  return (<div
                      className="banner-content"
                      key={index}
                          >
                    <img alt="" src={item.image}/>
                    <div className="banner-content-bottom">
                      <div>点击领取</div>
                      <div>+1次</div>
                    </div>
                  </div>);
                })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{width: '16px', flexShrink: 0}}>&nbsp;</div>
      <div>
        <Steps style={{marginBottom: '24px'}} size="small"
               current={this.state.currentStep}
               onChange={(currentStep) => {
                 this.setState({
                   currentStep,
                 });
               }}
        >
          <Step title="基础配置" />
          <Step title="活动主题（可选）" />
          <Step title="卡牌配置（可选）" />
          <Step title="分享配置（可选）" />
          <Step title="体力任务（可选）" />
          <Step title="金币&通知（可选）" />
        </Steps>
        <div style={{display: this.state.currentStep === 0 ? '' : 'none'}}>
          <BasicInfoEdit activityDetail={{...this.state.activityDetail}}
                         updateState={(data) => {
                           console.log(data);
                           this.setState(data);
                         }}
          />
        </div>
        <div style={{display: this.state.currentStep === 1 ? '' : 'none'}}>
          <BannerEdit activityDetail={{...this.state.activityDetail}}
                      updateState={(data) => {
                        console.log(data);
                        this.setState(data);
                      }}
          />
        </div>
        <div style={{display: this.state.currentStep === 2 ? '' : 'none'}}>
          <CardEdit activityDetail={{...this.state.activityDetail}}
                    updateState={(data) => {
                      console.log(data);
                      this.setState(data);
                    }}
          />
        </div>
        <div style={{display: this.state.currentStep === 3 ? '' : 'none'}}>
          <ShareEdit activityDetail={{...this.state.activityDetail}}
                     updateState={(data) => {
                       console.log(data);
                       this.setState(data);
                     }}
          />
        </div>
        <div style={{display: this.state.currentStep === 4 ? '' : 'none'}}>
          <TaskEdit activityDetail={{...this.state.activityDetail}}
                    updateState={(data) => {
                      console.log(data);
                      this.setState(data);
                    }}
          />
        </div>
        <div style={{display: this.state.currentStep === 5 ? '' : 'none'}}>
          <CoinsAndScore activityDetail={{...this.state.activityDetail}}
                         updateState={(data) => {
                           console.log(data);
                           this.setState(data);
                         }}
          />
        </div>
        <div style={{padding:"16px 0"}}>
          <NavLink
              to={`/app/activity/list/?activityID=${this.state.activityID}&activityType=${this.state.activityType}`} style={{ marginRight: "16px" }}
          ><Button
              type="secondary" onClick={() => {
          }}
           >返回活动列表</Button></NavLink>
          {this.state.currentStep === 0 &&
          <Button style={{ marginRight: "16px", marginLeft: "0" }} type="primary" onClick={() => {
            this.saveActivity();
          }}
          >立即发布</Button>
          }
          {this.state.currentStep !== 0 &&
          <Button style={{ marginRight: "16px", marginLeft: "0" }} type="primary" onClick={() => {
            this.saveActivity();
          }}
          >保存并发布</Button>
          }
          {this.state.currentStep === 0 &&
          <Button style={{ marginRight: "16px", marginLeft: "0" }} type="secondary" onClick={() => {
            this.setState({ currentStep: 1 });
          }}
          >高级配置</Button>
          }
          {this.state.currentStep === 1 &&
          <Button style={{ marginRight: "16px", marginLeft: "0" }} type="secondary" onClick={() => {
            this.setState({ currentStep: 2 });
          }}
          >卡牌配置</Button>
          }
          {this.state.currentStep === 2 &&
          <Button style={{ marginRight: "16px", marginLeft: "0" }} type="secondary" onClick={() => {
            if (this.state.activityDetail.activity_type === 5 || this.state.activityDetail.activity_type ===6) {
              let finish = 0
              this.state.activityDetail.cards.forEach((item) => {
                if (item.product_id.length > 0) {
                  finish++
                }
              })
              if (finish < 4) {
                this.setState({ finish: finish, showProductIDNotFinish: true });
              } else {
                this.setState({ currentStep: 3 });
              }
            } else {
              this.setState({ currentStep: 3 });
            }
          }}
          >分享配置</Button>
          }
          {this.state.currentStep === 3 &&
          <Button style={{ marginRight: "16px", marginLeft: "0" }} type="secondary" onClick={() => {
            this.setState({ currentStep: 4 });
          }}
          >体力任务</Button>
          }
          {this.state.currentStep === 4 &&
          <Button style={{ marginRight: "16px", marginLeft: "0" }} type="secondary" onClick={() => {
            this.setState({ currentStep: 5 });
          }}
          >金币&通知</Button>
          }
        </div>
        <div style={{height:"16px"}} />
        <div>{this.state.activityDetail.total_used_reward_coins > 0 &&
        <span
            className="tips-text"
        >本活动已使用：{this.state.activityDetail.total_used_reward_coins}体验金币，体验金币在活动结束后，不予以返还至账户内，充值金币将正常返还。</span>
        }</div>
      </div>
      <Dialog
          visible={this.state.showCopyUrl}
          title="活动保存成功"
          style={{width: '480px'}}
          onClose={() => {
            this.setState({
              showCopyUrl: false,
            });
          }}
      >
        <Input name="activityUrl" id="activityUrlCopy"
               placeholder="活动URL" onChange={() => {
        }} value={this.state.activityDetail.share_url || ""}
        />
        <div style={{height:"16px"}} />
        <div style={{margin: '16px 0'}}>
          <Button type="primary" onClick={() => {
            this.copyUrl();
          }}
          >复制链接</Button>
          <NavLink style={{marginLeft: '24px'}}
                   to={`/app/activity/list/?activityID=${this.state.activityID}&activityType=${this.state.activityType}`}
          ><Button type="secondary">返回活动列表</Button></NavLink>
          <Button style={{marginLeft: '24px'}} type="success" onClick={() => {
            this.showMoreJoinTips();
          }}
          >如何让更多人参加活动？</Button>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          {!this.state.user.template_msg_state &&
          <div className="tip-c">
            <div className="title">模板消息异常</div>
            <div className="body">
              <div>请检查是否:</div>
              <ul>
                <li>1.已开通模版清息</li>
                <li>2.行业选择是互联网-电子商务</li>
                <li>3.模版息剩余空间&gt;2</li>
              </ul>
              <div>更多问题联系 XRM82018</div>
            </div>
          </div>
          }
          <div style={{textAlign: 'center', paddingTop: '4px'}}>
            <QrCode value={this.state.activityDetail.share_url || ""} />
          </div>
        </div>
      </Dialog>
      <Dialog
          visible={this.state.showProductIDNotFinish}
          title="商品编码未填写完整"
          style={{width: '400px', textAlign:"center"}}
          onClose={() => this.setState({showMoreJoin: false})}
      >
        <p>需要完成{this.state.finish}/4个商品编码的配置</p>
        <p>完成后能提高活动转换率</p>
        <p>( <span
            style={{cursor: 'pointer'}} onClick={() => {
          this.setState({showProductCodeTips: true});
        }}
             >
              <QuestionCircleOutlined
                  style={{
                    color: '#FC8C03',
                  }}
              /><span>怎么设置商品编码</span>
            </span>）</p>
        <div style={{marginBottom:"16px", marginTop:"16px"}}>
          <Button type="secondary" onClick={() => {
            this.updateActivityField('pk_need_buy', 0);
            this.setState({
              showProductIDNotFinish: false,
              currentStep: 3,
            });
          }}
          >放弃并进入下一步</Button>
          <Button type="primary" onClick={() => {
            this.setState({
              showProductIDNotFinish: false
            });
          }}
          >返回继续配置</Button>
        </div>
      </Dialog>
      <Dialog
          visible={this.state.showMoreJoin}
          title="如何让更多人参与活动"
          style={{width: '900px'}}
          onClose={() => this.setState({showMoreJoin: false})}
      >
        <p><img alt=""
            src="https://img.yzcdn.cn/upload_files/2020/04/01/FujHu3vDSyWJy1TWmMAXBqkfezM6.png"
            width="840" data-origin-width="1000" data-origin-height="563"
           /></p>
        <p><img alt=""
            src="https://img.yzcdn.cn/upload_files/2020/04/01/FpMtTITCLLENJerIqvGekaefYqLp.png"
            width="840" data-origin-width="1000" data-origin-height="563"
           /></p>
        <p><img alt=""
            src="https://img.yzcdn.cn/upload_files/2020/04/01/Fq-WebcSWBzDfeN864cUh3gSRDHL.png"
            width="840" data-origin-width="1000" data-origin-height="563"
           /></p>
      </Dialog>
      <Dialog
          visible={this.state.showProductCodeTips}
          title="如何设置商品编码"
          style={{width: '800px'}}
          onClose={() => this.setState({showProductCodeTips: false})}
      >
        <div style={{marginBottom: '24px'}}>
          <p style={{fontWeight:"bold",fontSize:"16px"}}>怎么设置商品编码？</p>
          <p style={{fontWeight:"bold",fontSize:"14px"}}> 一、什么是商品编码？</p>
          <p><img src={code1} width="740" alt=""/></p>
          <p style={{fontWeight:"bold",fontSize:"14px"}}>  二、如何设置商品编码</p>
          <p> 1.点击商品页中，未设置编码的商品右侧的【编辑】按钮，进入商品管理页面</p>
          <p><img src={code2} width="740" alt=""/></p>
          <p> 2.在商品管理页面>编辑基本信息中，点开折叠更多设置中，有商品编码选项</p>
          <p><img src={code3} width="740" alt=""/></p>
          <p> 3.填写商品编码，点击保存</p>
          <p><img src={code4} width="740" alt=""/></p>
          <p> 4.返回商品管理页面，查看商品列表，商品编码已经显示，叠叠猫需要给6个商品设置商品编码才可以正常发布游戏。</p>
          <p><img src={code5} width="740" alt=""/></p>
          <p style={{fontWeight:"bold",fontSize:"16px"}}> 如何在游戏中加入购买商品增加战力值按钮【增加活动复购转化】</p>
          <p> 1.在【商品配置】栏目中，勾选开启PK模式</p>
          <p><img src={code6} width="740" alt=""/></p>
          <p> 2.【链接】处填入商品购买页面链接，在【编码】处填写为此商品设定的商品编码</p>
          <p>商品配置页面》商品设置部分</p>
          <p><img src={code7} width="740" alt=""/></p>
          <p>商品购买链接处：</p>
          <p><img src={code8} width="740" alt=""/></p>
          <p>商品对应商品编码</p>
          <p><img src={code9} width="740" alt=""/></p>
        </div>
      </Dialog>
    </div>);
  }
}
