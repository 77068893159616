import React, { useState, useEffect } from 'react'
import ReactEcharts from 'echarts-for-react';
import {getRelations} from '../../../../axios/tools';
// 社交关系
function SocialRelation (props) {
  const [ option, setOption ] = useState({})

  useEffect(() => {
    getRelations({open_id: props.id}).then(res => {
      if (res.code === 0) {
        setOption({
          series: [{
            type: 'graph', // 类型:关系图
            layout: 'force', //图的布局，类型为力导图
            symbolSize: 5, // 调整节点的大小
            roam: true, // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移,可以设置成 'scale' 或者 'move'。设置成 true 为都开启
            edgeSymbol: ['circle', 'arrow'],
            edgeSymbolSize: [1, 2],
            edgeLabel: {
              normal: {
                textStyle: {
                  fontSize: 20
                },
                color: '#a4e0df',//线上的字体颜色
                show: true,
                formatter: function (x) {
                  return "";
                }
              }
            },
            force: {
              repulsion: 1500,
              edgeLength: [10, 50]
            },
            draggable: true,
            lineStyle: {
              normal: {
                width: 2,
                color: '#a4e0df'
              }
            },
            label: {
              normal: {
                show: true,
                textStyle: {}
              }
            },

            // 数据
            data: res.data.users,
            links: res.data.links,
            color: ['#0d9e9e']
          }]
        })
      }
    })
  },[props.id]);

  return (<>
    <ReactEcharts
      option={option}
      style={{height: '700px', width: '100%'}}
      className={'react_for_echarts'}
    >
    </ReactEcharts>
  </>)
}

export default SocialRelation
