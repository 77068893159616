import React, { useEffect, useState } from 'react'
import { useAlita } from 'redux-alita'
import { PageHeader, Card, Col, Row, Button } from 'antd';
import './index.min.css'
import moment from 'moment';

/**
 * 组件
 * SwiperApp 轮播
 */
import SwiperComponent from './Swiper/index'
import CalendarComponent from './Calendar/index'
import CalendarTabComponent from './CalendarTab/index'
import EchartsComponent from '../charts/EchartsComponent'
import {
    getGiftStatistics,
    getPvStatistics,
    getUvStatistics,
    getActivityCountStatistics,
    getInviteStatistics,
    getStayStatistics,
    getTotalActivityStatistics, permission,
} from '../../axios/tools';
import {Dialog} from 'zent';
import Home from '../home';
import {useHistory} from 'react-router-dom';

function composeLineEchartData( title, legend, category, series, total) {
    return { title: title, options: {
        title: { left: 'left', text: total },
        color: ['rgba(77, 174, 245,1)', 'rgba(245, 154, 35,1)', 'rgba(239,219,200,1)','rgba(137,246,100,1)','rgba(155,314,203,1)','rgba(155,155,146)','rgba(111,222,100,1)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        },
        legend: {
            left: 'left',
            bottom: '0',
            data: legend
        },
        xAxis: [
          {
            type: 'category',
            data: category,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '数量',
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: '{value}'
            }
          },
        ],
        series: series
    }};
}

function composeBarEchartData(title, legend, category, series, total) {
    return { title: title,
                options: {
                    title: { left: 'left', text: total },
                    legend: {
                        left: 'right',
                        top: '0',
                        data: legend
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: category,
                            axisPointer: {
                                type: 'shadow'
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                        },
                    ],
                    series: series
                },
            };
}

function HomePage (props) {
    const [echartsList, setEchartsList] = useState([])
    const [ swiperTitle = '人人集卡', setAlita ] = useAlita('swiperTitle', { light: true })
    const [ auth = {} ] = useAlita('auth', { light: true })
    const [totalList, setTotalList] = useState([])
    const [showWechatAuthCoin, setShowWechatAuthCoin] = useState(false);
    const history = useHistory();
    useEffect(() => {
        const getEchartData = async () => {

            let totalData = await getTotalActivityStatistics();
            setTotalList(totalData)

            let pvData = await getPvStatistics({
                'timeType': 1,
                'activityID': 0
            });

            let pvEchartData = composeLineEchartData('Pv', pvData.legend, pvData.category, pvData.series, pvData.total);

            let uvData = await getUvStatistics({
                'timeType': 1,
                'activityID': 0
            });
            let uvEchartData = composeLineEchartData('Uv', uvData.legend, uvData.category, uvData.series, uvData.total);

            let activityData = await getActivityCountStatistics({
                'timeType': 1,
                'activityID': 0
            });
            let activityEchartData = composeLineEchartData('参与活动次数', activityData.legend, activityData.category, activityData.series, activityData.total);

            let inviteData = await getInviteStatistics({
                'timeType': 1,
                'activityID': 0
            });
            let inviteEchartData = composeLineEchartData('裂变用户', inviteData.legend, inviteData.category, inviteData.series, inviteData.total);

            let stayData = await getStayStatistics({
                'timeType': 1,
                'activityID': 0
            });
            let stayEchartData = composeLineEchartData('人均停留时长', stayData.legend, stayData.category, stayData.series, stayData.total);

            let giftData = await getGiftStatistics({
                'timeType': 1,
                'activityID': 0
            });
            let giftEchartData = composeBarEchartData('奖品发放', giftData.legend, giftData.category, giftData.series, giftData.total);
            setEchartsList([
                giftEchartData,
                pvEchartData,
                uvEchartData,
                activityEchartData,
                inviteEchartData,
                stayEchartData,
            ])
        }
        getEchartData().then(() => {});
        try {
            permission().then(user => {
                if (user) {
                    setAlita({stateName: "auth", data: user})
                    if (user.free_coins === false) {
                        // setShowWechatAuthCoin(true);
                    }
                }
            });
        } catch (error) {
        }
    }, [setAlita])
    function coinText() {
        const time = auth.rewards_time_left || 0;
        if (time < 0) {
            return 0;
        } else {
            let day = Math.floor(time / 86400);
            return day ? day + '天' : '0天' + Math.floor(time / 3600) + '小时';
        }
    }
    function coinStartAtText() {
        const time = totalList.startAt || 0;
        if (time < 0) {
            return 0;
        } else {
            let day = Math.floor(time / 86400);
            let hour = time - (day * 86400);
            return day ? day + '天' + Math.floor(hour / 3600) + '小时': '0天' + Math.floor(time / 3600) + '小时';
        }
    }
    return (<div className='home_page'>
        <PageHeader
            className="site-page-header"
            title={"您好，" + auth.nick_name}
        >
        </PageHeader>

        <div className='home_header'>
            <Row gutter={24}>
                <Col span={7}>
                    <Card className='home_header_card' bordered={false}>
                        <div className='home_header_swiper'>
                            <SwiperComponent/>
                        </div>
                        <div className='home_header_button'>
                            <span className='swiper_slide_title'>{swiperTitle}</span>
                            <Button type="primary" onClick={()=> props.history.push('/app/activity/home')}>创建活动</Button>
                        </div>
                    </Card>
                </Col>
                <Col span={17}>
                    <div className='home_header_card' style={{background: '#fff'}}>
                        <Row gutter={24} className="home_top">
                            <Col span={16}>
                                <CalendarTabComponent/>
                            </Col>
                            <Col span={8}>
                                <CalendarComponent/>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>

        <Row gutter={24}>
            <Col span={8}>
                <Card className='home_today' extra={
                    <span style={{cursor:"pointer"}} onClick={() => {
                    history.push("/app/information/activity");
                }}
                    >查看</span>} title={`今日进行中                 ${totalList.startActivityCount}`} bordered={false}
                >
                    <Row gutter={24} className="home_top">
                        <Col span={6}>
                            <div className='home_today_card'>
                                <span>{totalList.pvCount}</span>
                                <span>pv</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className='home_today_card'>
                                <span>{totalList.partakeCount}</span>
                                <span>参与用户量</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className='home_today_card'>
                                <span>{totalList.activeCount}</span>
                                <span>活跃用户量</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className='home_today_card'>
                                <span>{totalList.giftCount}</span>
                                <span>奖品发放量</span>
                            </div>
                        </Col>
                    </Row>
                    <div className="today_bottom">
                        <span>数据更新于</span>
                        <span>{ moment().format('YY-MM-DD HH:mm:ss') }</span>
                    </div>
                </Card>
            </Col>
            <Col span={8}>
                <Card className='home_today' title="未开始" bordered={false}>
                    <Row gutter={24} className="home_top">
                        <Col span={12}>
                            <div className='home_today_card'>
                                <span style={{color:'#0084ff'}}>{totalList.endStartCount}</span>
                                <span>活动数量</span>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='home_today_card'>
                                <span>{coinStartAtText()}</span>
                                <span>最近即将开始时间</span>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={8}>
                <Card className='home_today' title="账户金币" bordered={false}>
                    <Row gutter={24} className="home_top">
                        <Col span={12}>
                            <div className='home_today_card'>
                                <span style={{color:'#0084ff'}}>{auth.coins}</span>
                                <span>金币余额</span>
                                <span><Button type="primary" onClick={() => {setAlita({stateName:'showRecharge', data: true})}}>充值</Button></span>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='home_today_card'>
                                <span>{auth.rewards_coins_left}</span>
                                <span>体验金币余额</span>
                                <span
                                    style={{color: '#FFCC00'}}
                                    className="coin-title"
                                >（剩余体验时间：{coinText()}）</span>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        {
            auth.total_activities > 0 &&
            <div className='home_echarts'>
                <Row gutter={24}>
                    {
                        echartsList.map((item, index) => {
                            return (
                                <Col span={8} style={{margin: '10px 0'}} key={`home_echarts${index}`}>
                                    <Card title={item.title}>
                                        <EchartsComponent options={item.options}/>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        }
        {
            auth.total_activities === 0 &&
                <Home />
        }
        <Dialog
            visible={showWechatAuthCoin}
            title="免费领1万金币"
            style={{width: '380px'}}
            onClose={() => setShowWechatAuthCoin(false)}
        >
            <div style={{marginBottom: '16px', textAlign: 'center'}}>
                <img src="https://oss.static.rrfenwo.com/admin/img/kefu.png"
                     alt="" width="150" height="150"
                />
            </div>
            <div style={{marginBottom: '16px'}}>
                <ol>
                    <li>请扫二维码添加客服微信</li>
                    <li>在验证信息中填写【{auth.nick_name}人人粉我免费体验】</li>
                    <li>仅限第一次购买本应用赠送，赠送的金币有效期14天，有效期内未使用完的金币，系统将自动清除</li>
                </ol>
            </div>
        </Dialog>
    </div>)
}
export default HomePage
