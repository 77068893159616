import React,{ useEffect, useState } from 'react';
import { Modal, Button, message } from 'antd';
import { useAlita } from 'redux-alita'
import TagList from '../../tagManagement/TagList/index'
import {updateUserTags} from '../../../../axios/tools';

function UserAddTagDialog (props) {
    const [ visible, setVisible ] = useState(false)
    const [userTags = [], setAlita] = useAlita('userTags', { light: true })

    useEffect(() => {
        console.log(props.tagsGroup)
        let tags = []
        props.tagsGroup.forEach((g) => {
            g.tags.forEach((t) => {
                tags.push(t.tag_id)
            })
        })
        setAlita('userTags', [...tags])
    }, [props.tagsGroup, setAlita])

    function handleOk () {
        if (!userTags.length) {
            message.warning('请选择用户在添加标签');
            return
        }
        console.log('发送数据',userTags)
        updateUserTags({open_id: props.id, tags_id: userTags}).then(r => {
            if (r.code === 0) {
                setVisible(false)
                props.refresh();
            }
        })
    }

    function handleCancel () {
        setAlita('userTags', [])
        setVisible(false)
    }

    return (
    <>
        <Button type="primary" onClick={() => {
            setVisible(true);
            let tags = []
            props.tagsGroup.forEach((g) => {
                g.tags.forEach((t) => {
                    tags.push(t.tag_id)
                })
            })
            console.log("-----------------", tags, props.tagsGroup)
            setAlita('userTags', [...tags])
        }}
        >编辑用户标签</Button>
        <Modal
            width={1000}
            visible={visible}
            title="编辑用户标签"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>取消</Button>,
                <Button key="submit" type="primary" onClick={handleOk}>确认</Button>
            ]}
        >
            <TagList type="add"/>
        </Modal>
    </>
    );
}

export default UserAddTagDialog
