import React, {useCallback, useEffect, useState} from 'react';
import { List } from 'antd'
import { useAlita } from 'redux-alita';

import TagItem from '../TagItem/index'
import {tagGroups} from '../../../../axios/tools';

const TagManagementList = (props) => {
    const [ tagList = [], setAlita] = useAlita('tagList', { light: true })
    const [page, setPage] = useState({pageSize: 5, current: 1, total: 0});
    const [total, setTotal] = useState(0);
    // 初始化执行一次
    useEffect(() => {
      tagGroups({
        page: 1,
      }).then(res => {
        if (res.code === 0) {
          setAlita({
            stateName: 'tagList',
            data: res.data.items,
          })
          setPage(page => { return {
            current: res.data.page * 1,
            pageSize: res.data.page_size * 1,
            total: res.data.total,
          }})
          setTotal(total => res.data.total);
        }
      })
    }, [setAlita])
  const getList = React.useCallback(() => {
    tagGroups({
      page: page.current,
    }).then(res => {
      if (res.code === 0) {
        setAlita({
          stateName: 'tagList',
          data: res.data.items,
        })
        console.log(tagList);
        setTotal(res.data.total);
        setPage({
          current: res.data.page * 1,
          pageSize: res.data.page_size * 1,
          total: res.data.total * 1
        })
      }
    })
  }, [page, setAlita, tagList])
    const TagItemCallback = useCallback((value) => {
        return (<TagItem item={value.item} type={value.type}/>)
    },[])

    return (<>
        <List
            size="large"
            header={<div>一共{total}条数据</div>}
            dataSource={tagList}
            pagination={{
                onChange: page => {
                  setPage(page.pagination);
                  getList();
                },
                pageSize: 5,
                total: total,
                current: 1,
            }}
            renderItem={(item) =><TagItemCallback item={item} type={props.type}/>}
        />
    </>)
}
export default TagManagementList
