import React, {useEffect, useState} from 'react';
import { Table} from 'antd';
import {getUserJoinedActivities} from '../../../../axios/tools';

const columns = [
    {
        title: '序号',
        dataIndex: 'activity_id',
        key: 'activity_id',
    },
    {
        title: '活动名称',
        dataIndex: 'activity_title',
        key: 'activity_title',
        render: text => <span>{text}</span>,
    },
    {
        title: '活动类型',
        dataIndex: 'activity_type_name',
        key: 'activity_type_name',
        render: text => <span>{text}</span>,
    },
    {
        title: '参与时间',
        dataIndex: 'join_at',
        key: 'join_at',
    },
];

// 参与活动
const ParticipateActivities = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getUserJoinedActivities({open_id: props.id}).then(res => {
      if (res.code === 0) {
        setData(res.data)
      }
    })
  }, [props.id])
  return(<>
      <Table columns={columns} dataSource={data} />
  </>)
}
export default ParticipateActivities
