import React from 'react'
import { Card } from 'antd'

import './index.scss'

function TypeWechat () {
    return (<>
        <Card className='type_wechat' title="什么是微信支付商家授权？">
            <div>微信支付商家券是微信支付为商户提供的电子优惠券解决方案，商家可在微信支付允许的范围内通过该功能实现商家优惠券信息生成、下发、领取、核销的闭环，并使用数据对账、券信息查询等配套功能完成商家券的管理操作。</div>
        </Card>
        <Card className='type_wechat' title="如何设置微信支付商家授权？">
            <div>微信支付商家券功能当前暂时仅提供API接口功能，有开发意愿的服务商或者商户可使用API接口完成商家券创建、发放、领取、核销、数据对账等全链路操作。SCEM私域游戏化管理中可调用微信支付接口为您的活动发放微信商家券。</div>
            <div><a href="https://pay.weixin.qq.com/wiki/doc/apiv3/open/pay/chapter5_2_1.shtml" target="_blank" rel="noopener noreferrer">微信支付商家券API简介</a></div>
        </Card>
        <Card className='type_wechat' title="如何发放微信商家券？">
            <div>在SCEM私域游戏化管理中，通过活动设置，将微信商家券在活动中进行派发。</div>
        </Card>
    </>)
}

export default TypeWechat
