//引入react jsx写法的必须
import React from "react";
import {
  Card,
  DatePicker,
  Input,
  Select,
  Upload,
  Modal,
  Switch,
  Form, Divider, Radio, Button,
} from 'antd';

import {
  DownOutlined,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined, UpOutlined,
} from '@ant-design/icons';

import { Dialog, Notify } from "zent";

import moment from "moment";
import { getUploadURI, getCouponsUnfinished, getToken } from "../../../axios/tools";

import Vouchers from '../../wechat/vouchers';

import CardEdit from './cardEdit';

import {beforeUpload} from '../../../utils';
import Physical from '../../prize/physical';

moment.locale("zh-cn"); // zh-cn

const uploadURI = getUploadURI();
const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

function disabledDate(current) {
  // Can not select days before today and today
  return current && (current < moment() || current > moment().add(90, "days"));
}

class BasicInfoEdit extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      ...props,
      uploading: false,
      showRewardTypeTips: false,
      coupons: [],
      worldRankIndex: 1,
      showChooseVoucher: false,
      showChooseProduct: false,
      showBasicAdvanced: false,
      chosenProductID: "",
      chosenVoucherID: "",
      shareStyle: props.activityDetail.shares_new.share_type,
      shareStyleOptions: [
        {
          label: '风格一',
          value: 1,
        }, {
          label: '虎年分享',
          value: 2,
        }, {
          label: '自定义',
          value: 3,
        }],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, _) {
    this.setState({
      ...nextProps,
      shareStyle: nextProps.activityDetail.shares_new.share_type,
    });
    if (this.form.current && this.form.current.setFieldsValue) {
      this.form.current.setFieldsValue({
        ...nextProps.activityDetail,
      });
    }
  }
  form = React.createRef()
  async getCoupons() {
    let coupons = await getCouponsUnfinished();
    this.setState({
      coupons: coupons || [],
    });
  }

  updateActivityField(field, value) {
    if (field === "end_time") {
      value = moment(value).format("YYYY-MM-DDTHH:mm:ssZ");
    }
    let ac = this.state.activityDetail;
    ac[field] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  updateActivityWorldRankRewards(field, index, type, value) {
    console.log(field, index, type, value);
    let ac = this.state.activityDetail;
    ac[field][index][type] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  chooseVoucher(data) {
    this.updateActivityWorldRankRewards('world_rank_rewards', this.state.worldRankIndex, 'link', data.stock_id)
  }
  choosePhysical(data) {
    this.updateActivityWorldRankRewards('world_rank_rewards', this.state.worldRankIndex, 'title', data.product_title)
    this.updateActivityWorldRankRewards('world_rank_rewards', this.state.worldRankIndex, 'image', data.product_thumb)
    this.updateActivityWorldRankRewards('world_rank_rewards', this.state.worldRankIndex, 'link', data.product_id + "")
    this.updateActivityWorldRankRewards('world_rank_rewards', this.state.worldRankIndex, 'id', data.product_id)
  }

  updateActivityBanner(field, index, type, value) {
    let ac = this.state.activityDetail;
    ac[field][index][type] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }

  handleChange(type, info, index) {
    if (info.file.status === "uploading") {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      let res = info.file.response;
      if (res.code !== 0) {
        return Notify.error(res.message);
      }
      let imageUrl = res.data.image_url;
      if (type === "qrcode") {
        this.updateActivityField("qrcode", imageUrl);
      } else if (type === "banners") {
        this.updateActivityBanner("banners", index, "image", imageUrl);
      } else if (type === "world_rank_rewards") {
        this.updateActivityBanner("world_rank_rewards", index, "image", imageUrl);
      } else if (type === "logo") {
        this.updateActivityField('logo', imageUrl);
      } else if (type === "share") {
        this.updateActivityShareItem('image', this.state.shareStyle, imageUrl);
      }
      this.setState({ uploading: false });
    }
  }
  updateActivityShareItem(field, type, value) {
    let ac = this.state.activityDetail;
    ac['shares_new']['items'][type][field] = value;
    this.state.updateState({
      activityDetail: ac,
    });
  }
  shareStyleChange(e) {
    console.log('radio3 checked', e.target.value);
    let shareStyle = e.target.value * 1;
    let ac = this.state.activityDetail;
    ac['shares_new']['share_type'] = shareStyle;
    this.state.updateState({
      activityDetail: ac,
      shareStyle: shareStyle,
    });

  }
  onGoNext() {
    this.state.updateState({
      pathName: 'environmentModule'
    })
  }
  render() {
    const uploadExtra = {
      token: getToken(),
    };

    const uploadButton = (
      <div>
        {this.state.uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传</div>
      </div>
    );
    return (
      <>

        <Card title="基础设置">
          <Form.Item label="活动名称：" name="title">
            <Input
                style={{ width: "480px" }}
                onChange={(e) => {
                  console.log("onchange............");
                  this.updateActivityField("title", e.target.value);
                }}
                onBlur={(e) => {
                  console.log("onblur............");
                  this.updateActivityField("title", e.target.value);
                }}
            />
          </Form.Item>
          <Form.Item
              label="起止时间："
          >
            <>
              <div>
                <RangePicker defaultValue={[moment(this.state.activityDetail.begin_time),moment(this.state.activityDetail.end_time)]}
                             format="YYYY-MM-DD HH:mm:ss"
                             showTime
                             disabledDate={disabledDate}
                             placeholder={["请选择活动开始时间","请选择活动结束时间"]}
                             onChange={(d, ds) => {
                               this.updateActivityField('end_time', ds[1]);
                               this.updateActivityField('begin_time', ds[0]);
                             }}
                />
              </div>
              <div
                  style={{'color': 'red'}}
              >此处为游戏起止时间，到您设置的时间，游戏会自动开始和结束，时间外用户将无法参与活动。
              </div>
            </>
          </Form.Item>
          <Divider orientation="left" onClick={() => {
            this.setState({
              showBasicAdvanced: !this.state.showBasicAdvanced
            })
          }}
          >{!this.state.showBasicAdvanced && <>展开高级设置<DownOutlined size="small" /></>}{this.state.showBasicAdvanced && <>收起高级设置<UpOutlined size="small" /></>}</Divider>
          {this.state.showBasicAdvanced && <>
            <Form.Item label="品牌名称：" name="brand_name">
              <Input
                  style={{ width: "480px" }}
                  onChange={(e) => {
                    console.log("onchange............");
                    this.updateActivityField("brand_name", e.target.value);
                  }}
                  onBlur={(e) => {
                    console.log("onblur............");
                    this.updateActivityField("brand_name", e.target.value);
                  }}
              />
            </Form.Item>

            <Form.Item
                label="奖品及可抽次数不足通知："
                name="need_coins_notice"
            >
              <Switch checkedChildren="开启" unCheckedChildren="关闭"
                      checked={this.state.activityDetail.need_coins_notice ===
                      1} onChange={(val) => {
                this.updateActivityField('need_coins_notice', val ? 1 : 0);
              }}
              />
            </Form.Item>
            {
              this.state.activityDetail.need_coins_notice === 1 &&
              <Form.Item
                  label="通知手机号："
                  name="notify_mobile"
              >
                <Input
                    style={{ width: '120px' }}
                    onChange={(e) => {
                      console.log('onchange............');
                      this.updateActivityField('notify_mobile', e.target.value);
                    }}
                    onBlur={(e) => {
                      console.log('onblur............');
                      this.updateActivityField('notify_mobile', e.target.value);
                    }}
                />
              </Form.Item>
            }
            <Form.Item
                className="uploader"
                label="自定义品牌头图："
                style={{marginBottom: 0}}
            >
              <div>
                <div style={{display: 'flex'}}>
                  <Upload
                      name="file"
                      listType="picture-card"
                      className="logo-uploader"
                      showUploadList={false}
                      action={uploadURI}
                      data={uploadExtra}
                      beforeUpload={beforeUpload}
                      onChange={(info) => {
                        this.handleChange('logo', info, 0);
                      }}
                  >
                    {this.state.activityDetail.logo ?
                        <img
                            src={this.state.activityDetail.logo}
                            alt="avatar" style={{width: '100%'}}
                        /> :
                        uploadButton}
                  </Upload>
                  <div className="uploader-tips">
                    * 374X58，≤500k，建议使用png格式
                    <br/>* 点击上传
                    <br/>* 首页品牌标志自定义
                  </div>
                </div>
                <div style={{color: '#155BD4', cursor: 'pointer'}}
                     onClick={() => {
                       this.updateActivityField('logo',
                           'https://rrtcard.oss-cn-beijing.aliyuncs.com/admin/statics/red/logo.png');
                     }}
                >恢复默认
                </div>
              </div>
            </Form.Item>
            <Form.Item label="分享设置">
              <Radio.Group
                  options={this.state.shareStyleOptions}
                  onChange={(e) => {
                    this.shareStyleChange(e);
                  }}
                  value={this.state.shareStyle}
                  optionType="button"
                  buttonStyle="solid"
                  style={{marginBottom: '24px'}}
              />
              {this.state.shareStyle === 1 &&
              <>
                <Form.Item
                    label="分享卡片文案："
                    name={['shares_new','items',this.state.shareStyle,'title']}
                >
                  <Input
                      disabled
                      style={{ width: "480px" }}
                      onChange={(e) => {
                        console.log('onchange............');
                        this.updateActivityShareItem('title', this.state.shareStyle,
                            e.target.value);
                      }}
                  />
                </Form.Item>
                <Form.Item
                    className="uploader"
                    label="分享卡片图片："
                    style={{marginBottom: 0}}
                >
                  <>
                    <Upload
                        disabled
                        name="file"
                        listType="picture-card"
                        className="share-uploader"
                        showUploadList={false}
                        action={uploadURI}
                        data={uploadExtra}
                        beforeUpload={beforeUpload}
                        onChange={(info) => {
                          this.handleChange("share", info, 0);
                        }}
                    >
                      {this.state.activityDetail.shares_new.items[this.state.shareStyle]['image'] ?
                          <img
                              src={this.state.activityDetail.shares_new.items[this.state.shareStyle]['image']}
                              alt="avatar" style={{width: '100%'}}
                          /> :
                          uploadButton}
                    </Upload>
                    <div className="uploader-tips">
                      * 500X400，≤500k
                      <br/>* 点击上传
                    </div>
                  </>
                </Form.Item>
              </>
              }
              {this.state.shareStyle === 2 &&
              <>
                <Form.Item
                    label="分享卡片文案："
                    name={['shares_new','items',this.state.shareStyle,'title']}
                >
                  <Input
                      disabled
                      style={{ width: "480px" }}
                      onChange={(e) => {
                        console.log('onchange............');
                        this.updateActivityShareItem('title', this.state.shareStyle,
                            e.target.value);
                      }}
                  />
                </Form.Item>
                <Form.Item
                    className="uploader"
                    label="分享卡片图片："
                    style={{marginBottom: 0}}
                >
                  <>
                    <Upload
                        disabled
                        name="file"
                        listType="picture-card"
                        className="share-uploader"
                        showUploadList={false}
                        action={uploadURI}
                        data={uploadExtra}
                        beforeUpload={beforeUpload}
                        onChange={(info) => {
                          this.handleChange("share", info, 0);
                        }}
                    >
                      {this.state.activityDetail.shares_new.items[this.state.shareStyle].image ?
                          <img
                              src={this.state.activityDetail.shares_new.items[this.state.shareStyle]['image']}
                              alt="avatar" style={{width: '100%'}}
                          /> :
                          uploadButton}
                    </Upload>
                    <div className="uploader-tips">
                      * 500X400，≤500k
                      <br/>* 点击上传
                    </div>
                  </>
                </Form.Item>
              </>
              }
              {this.state.shareStyle === 3 &&
              <>
                <Form.Item
                    label="分享卡片文案："
                    name={['shares_new','items',this.state.shareStyle,'title']}
                >
                  <Input
                      disabled={this.state.shareStyle !== 3}
                      style={{ width: "480px" }}
                      onChange={(e) => {
                        console.log('onchange............');
                        this.updateActivityShareItem('title', this.state.shareStyle,
                            e.target.value);
                      }}
                  />
                </Form.Item>
                <Form.Item
                    className="uploader"
                    label="分享卡片图片："
                    style={{marginBottom: 0}}
                >
                  <>
                    <Upload
                        disabled={this.state.shareStyle !== 3}
                        name="file"
                        listType="picture-card"
                        className="share-uploader"
                        showUploadList={false}
                        action={uploadURI}
                        data={uploadExtra}
                        beforeUpload={beforeUpload}
                        onChange={(info) => {
                          this.handleChange("share", info, 0);
                        }}
                    >
                      {this.state.activityDetail.shares_new.items[this.state.shareStyle]['image'] ?
                          <img
                              src={this.state.activityDetail.shares_new.items[this.state.shareStyle]['image']}
                              alt="avatar" style={{width: '100%'}}
                          /> :
                          uploadButton}
                    </Upload>
                    <div className="uploader-tips">
                      * 500X400，≤500k
                      <br/>* 点击上传
                    </div>
                  </>
                </Form.Item>
              </>
              }
            </Form.Item>
            <Form.Item label="攻略：" name="rule_link" >
              <TextArea
                  rows="4"
                  style={{ width: "680px" }}
                  onChange={(e) => {
                    console.log("onchange............");
                    this.updateActivityField("rule_link", e.target.value);
                  }}
                  onBlur={(e) => {
                    console.log("onblur............");
                    this.updateActivityField("rule_link", e.target.value);
                  }}
              />
            </Form.Item>
          </>
          }
        </Card>

        <Card title="资源模块" style={{marginTop:"16px"}}>
          <Form.Item label="默认抽卡次数：" name="default_power" >
            <Input
                type="number"
                style={{ width: "480px" }}
                onChange={(e) => {
                  console.log("onchange............");
                  this.updateActivityField("default_power", e.target.value * 1);
                }}
                onBlur={(e) => {
                  console.log("onblur............");
                  this.updateActivityField("default_power", e.target.value * 1);
                }}
            />
          </Form.Item>

        </Card>
        <Card title="奖品设置" style={{marginTop:"16px"}} extra="特别注意：未授权登录过商户小程序的用户，会出现微信代金券领取失败的情况，需要商户引导用户先在商户小程序内授权登录">
          <Card type="inner" bordered={false} size="small" title="一等奖 集齐六张卡片" extra={`获奖概率：${this.state.activityDetail.firstRewardRatio}% （该数值通过配置的卡牌可抽次数计算）`}>
            <Form.Item label="奖励结算方式：">
              <>
                <Select
                    disabled={
                      this.state.activityDetail.activity_id !== "" &&
                      this.state.activityDetail.activity_id !== "0" &&
                      this.state.activityDetail.activity_id !== 0
                    }
                    defaultValue={this.state.activityDetail.world_rank_rewards[1].state}
                    style={{ width: 240 }}
                    onChange={(v) => {
                      this.updateActivityBanner("world_rank_rewards", 1, "state", v);
                    }}
                >
                  <Option value={1}>实物奖品发放</Option>
                  <Option value={3}>优惠券发放</Option>
                </Select>
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ showRewardTypeTips: true });
                    }}
                >
                <QuestionCircleOutlined style={{ color: "#FC8C03", paddingLeft: "14px" }} />
                <span style={{}}>奖励结算方式</span>
              </span>
              </>
            </Form.Item>

            <Form.Item
                label="奖品名称："
            >
              <div style={{display:"flex"}}>
                <Form.Item name={['world_rank_rewards',1,'title']}>
                  <Input
                      onClick={() => {
                        if (this.state.activityDetail.world_rank_rewards[1].state === 1) {
                          this.setState({
                            showChooseProduct: true,
                            chosenProductID: this.state.activityDetail.world_rank_rewards[1].link,
                            worldRankIndex: 1,
                          })
                        }
                      }}
                      onChange={(e) => {
                        console.log("onchange............");
                        this.updateActivityWorldRankRewards("world_rank_rewards", 1, "title", e.target.value);
                      }}
                  />
                </Form.Item>
                {this.state.activityDetail.world_rank_rewards[1].state === 1 &&
                <Button type="primary" style={{marginLeft:"16px"}} onClick={() => {
                  this.setState({
                    showChooseProduct: true,
                    chosenProductID: this.state.activityDetail.world_rank_rewards[1].link,
                    worldRankIndex: 1,
                  })
                }}
                >选择</Button>
                }
              </div>
            </Form.Item>
            <Form.Item
                label="奖品数量："
                name={['world_rank_rewards',1,'number']}
            >
              <Input
                  onChange={(e) => {
                    console.log("onchange............");
                    this.updateActivityWorldRankRewards("world_rank_rewards", 1, "number", e.target.value * 1);
                  }}
              />
            </Form.Item>
            <Form.Item className="uploader" label="奖品图片：">
              <>
                <Upload
                    name="file"
                    listType="picture-card"
                    className="banner-uploader"
                    showUploadList={false}
                    action={uploadURI}
                    data={uploadExtra}
                    beforeUpload={beforeUpload}
                    onChange={(info) => {
                      this.handleChange("world_rank_rewards", info, 1);
                    }}
                >
                  {this.state.activityDetail.world_rank_rewards[1]["image"] ? (
                      <img
                          src={this.state.activityDetail.world_rank_rewards[1]["image"]}
                          alt="avatar"
                          style={{ width: "100%" }}
                      />
                  ) : (
                      uploadButton
                  )}
                </Upload>
                <div className="uploader-tips">
                  * 460X340，≤500k
                  <br />* 点击上传
                </div>
              </>
            </Form.Item>
            {(this.state.activityDetail.world_rank_rewards[1].state === 1 ||
                this.state.activityDetail.world_rank_rewards[2].state === 1 ||
                this.state.activityDetail.world_rank_rewards[3].state === 1) && (
                <>
                  <Form.Item label="客服二维码：" className="uploader">
                    <>
                      <Upload
                          name="file"
                          listType="picture-card"
                          className="qrcode-uploader"
                          showUploadList={false}
                          action={uploadURI}
                          data={uploadExtra}
                          beforeUpload={beforeUpload}
                          onChange={(info) => {
                            this.handleChange("qrcode", info);
                          }}
                      >
                        {this.state.activityDetail.qrcode ? (
                            <img src={this.state.activityDetail.qrcode} alt="avatar" style={{ width: "100%" }} />
                        ) : (
                            uploadButton
                        )}
                      </Upload>
                      <div className="uploader-tips">
                        * 客服二维码(258x258，≤500k)
                        <br />* 拖曳文件或点击上传
                        <br />* 线下结算时，用户联系此二维码领奖
                      </div>
                    </>
                  </Form.Item>
                </>
            )}
            {this.state.activityDetail.world_rank_rewards[1].state === 2 && (
                <>
                  <Form.Item label="一等奖优惠券：">
                    <Select
                        defaultValue={this.state.activityDetail.world_rank_rewards[1]["link"]}
                        style={{ width: 240 }}
                        onChange={(v) => {
                          this.updateActivityWorldRankRewards("world_rank_rewards", 1, "link", v);
                        }}
                    >
                      <Option value="">--请选择优惠券--</Option>
                      {this.state.coupons.map((item, index) => {
                        return (
                            <Option value={item.group_id + ""} key={index}>
                              {item.title}
                            </Option>
                        );
                      })}
                    </Select>
                    <div style={{ color: "red" }}>此处建议选择可以放入微信卡包的优惠券。</div>
                  </Form.Item>
                </>
            )}
            {this.state.activityDetail.world_rank_rewards[1].state === 3 &&
            <Form.Item label="一等奖优惠券：">
              <div style={{display:"flex"}}>
                <Form.Item name={['world_rank_rewards', 1, 'link']}>
                  <Input
                      onClick={() => {
                        this.setState({
                          showChooseVoucher: true,
                          chosenVoucherID: this.state.activityDetail.world_rank_rewards[1].link,
                          worldRankIndex: 1,
                        })
                      }}
                  />
                </Form.Item>
                <Button type="primary" style={{marginLeft:"16px"}} onClick={() => {
                  this.setState({
                    showChooseVoucher: true,
                    chosenVoucherID: this.state.activityDetail.world_rank_rewards[1].link,
                    worldRankIndex: 1,
                  })
                }}
                >选择</Button>
              </div>
            </Form.Item>
            }
          </Card>
          <Card type="inner" bordered={false} size="small" title="二等奖 集齐五张卡片" extra={`获奖概率：${this.state.activityDetail.secondRewardRatio}% （该数值通过配置的卡牌可抽次数计算）`}>
            <Form.Item label="奖励结算方式：">
              <>
                <Select
                    disabled={
                      this.state.activityDetail.activity_id !== "" &&
                      this.state.activityDetail.activity_id !== "0" &&
                      this.state.activityDetail.activity_id !== 0
                    }
                    defaultValue={this.state.activityDetail.world_rank_rewards[2].state}
                    style={{ width: 240 }}
                    onChange={(v) => {
                      this.updateActivityBanner("world_rank_rewards", 2, "state", v);
                    }}
                >
                  <Option value={1}>实物奖品发放</Option>
                  <Option value={3}>优惠券发放</Option>
                </Select>
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ showRewardTypeTips: true });
                    }}
                >
                <QuestionCircleOutlined style={{ color: "#FC8C03", paddingLeft: "14px" }} />
                <span style={{}}>奖励结算方式</span>
              </span>
              </>
            </Form.Item>

            <Form.Item
                label="奖品名称："
            >
              <div style={{display:"flex"}}>
                <Form.Item name={['world_rank_rewards',2,'title']}>
                  <Input
                      onClick={() => {
                        if (this.state.activityDetail.world_rank_rewards[2].state === 1) {
                          this.setState({
                            showChooseProduct: true,
                            chosenProductID: this.state.activityDetail.world_rank_rewards[2].link,
                            worldRankIndex: 2,
                          })
                        }
                      }}
                      onChange={(e) => {
                        console.log("onchange............");
                        this.updateActivityWorldRankRewards("world_rank_rewards", 2, "title", e.target.value);
                      }}
                  />
                </Form.Item>
                {this.state.activityDetail.world_rank_rewards[2].state === 1 &&
                <Button type="primary" style={{marginLeft:"16px"}} onClick={() => {
                  this.setState({
                    showChooseProduct: true,
                    chosenProductID: this.state.activityDetail.world_rank_rewards[2].link,
                    worldRankIndex: 2,
                  })
                }}
                >选择</Button>
                }
              </div>
            </Form.Item>
            <Form.Item
                label="奖品数量："
                name={['world_rank_rewards',2,'number']}
            >
              <Input
                  onChange={(e) => {
                    console.log("onchange............");
                    this.updateActivityWorldRankRewards("world_rank_rewards", 2, "number", e.target.value * 1);
                  }}
              />
            </Form.Item>
            <Form.Item className="uploader" label="奖品图片：">
              <>
                <Upload
                    name="file"
                    listType="picture-card"
                    className="banner-uploader"
                    showUploadList={false}
                    action={uploadURI}
                    data={uploadExtra}
                    beforeUpload={beforeUpload}
                    onChange={(info) => {
                      this.handleChange("world_rank_rewards", info, 2);
                    }}
                >
                  {this.state.activityDetail.world_rank_rewards[2]["image"] ? (
                      <img
                          src={this.state.activityDetail.world_rank_rewards[2]["image"]}
                          alt="avatar"
                          style={{ width: "100%" }}
                      />
                  ) : (
                      uploadButton
                  )}
                </Upload>
                <div className="uploader-tips">
                  * 460X340，≤500k
                  <br />* 点击上传
                </div>
              </>
            </Form.Item>
            {this.state.activityDetail.world_rank_rewards[1].state === 2 &&
            (this.state.activityDetail.world_rank_rewards[2].state === 1 ||
                this.state.activityDetail.world_rank_rewards[3].state === 1) && (
                <>
                  <Form.Item label="客服二维码：" className="uploader">
                    <>
                      <Upload
                          name="file"
                          listType="picture-card"
                          className="qrcode-uploader"
                          showUploadList={false}
                          action={uploadURI}
                          data={uploadExtra}
                          beforeUpload={beforeUpload}
                          onChange={(info) => {
                            this.handleChange("qrcode", info);
                          }}
                      >
                        {this.state.activityDetail.qrcode ? (
                            <img src={this.state.activityDetail.qrcode} alt="avatar" style={{ width: "100%" }} />
                        ) : (
                            uploadButton
                        )}
                      </Upload>
                      <div className="uploader-tips">
                        * 客服二维码(258x258，≤500k)
                        <br />* 拖曳文件或点击上传
                        <br />* 线下结算时，用户联系此二维码领奖
                      </div>
                    </>
                  </Form.Item>
                </>
            )}
            {this.state.activityDetail.world_rank_rewards[2].state === 2 && (
                <>
                  <Form.Item label="二等奖优惠券：">
                    <Select
                        defaultValue={this.state.activityDetail.world_rank_rewards[2]["link"]}
                        style={{ width: 240 }}
                        onChange={(v) => {
                          this.updateActivityWorldRankRewards("world_rank_rewards", 2, "link", v);
                        }}
                    >
                      <Option value="">--请选择优惠券--</Option>
                      {this.state.coupons.map((item, index) => {
                        return (
                            <Option value={item.group_id + ""} key={index}>
                              {item.title}
                            </Option>
                        );
                      })}
                    </Select>
                    <div style={{ color: "red" }}>此处建议选择可以放入微信卡包的优惠券。</div>
                  </Form.Item>
                </>
            )}

            {this.state.activityDetail.world_rank_rewards[2].state === 3 &&
            <Form.Item label="二等奖优惠券：">
              <div style={{display:"flex"}}>
                <Form.Item name={['world_rank_rewards', 2, 'link']}>
                  <Input
                      onClick={() => {
                        this.setState({
                          showChooseVoucher: true,
                          chosenVoucherID: this.state.activityDetail.world_rank_rewards[2].link,
                          worldRankIndex: 2,
                        })
                      }}
                  />
                </Form.Item>
                <Button type="primary" style={{marginLeft:"16px"}} onClick={() => {
                  this.setState({
                    showChooseVoucher: true,
                    chosenVoucherID: this.state.activityDetail.world_rank_rewards[2].link,
                    worldRankIndex: 2,
                  })
                }}
                >选择</Button>
              </div>
            </Form.Item>
            }
          </Card>
          <Card bordered={false} type="inner" size="small" title="三等奖 集齐三张卡片" extra={`获奖概率：${this.state.activityDetail.thirdRewardRatio}% （该数值通过配置的卡牌可抽次数计算）`}>
            <Form.Item label="奖励结算方式：">
              <>
                <Select
                    disabled={
                      this.state.activityDetail.activity_id !== "" &&
                      this.state.activityDetail.activity_id !== "0" &&
                      this.state.activityDetail.activity_id !== 0
                    }
                    defaultValue={this.state.activityDetail.world_rank_rewards[3].state}
                    style={{ width: 240 }}
                    onChange={(v) => {
                      this.updateActivityBanner("world_rank_rewards", 3, "state", v);
                    }}
                >
                  <Option value={1}>实物奖品发放</Option>
                  <Option value={3}>优惠券发放</Option>
                </Select>
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ showRewardTypeTips: true });
                    }}
                >
                <QuestionCircleOutlined style={{ color: "#FC8C03", paddingLeft: "14px" }} />
                <span style={{}}>奖励结算方式</span>
              </span>
              </>
            </Form.Item>

            <Form.Item
                label="奖品名称："
            >
              <div style={{display:"flex"}}>
                <Form.Item name={['world_rank_rewards',3,'title']}>
                  <Input
                      onClick={() => {
                        if (this.state.activityDetail.world_rank_rewards[3].state === 1) {
                          this.setState({
                            showChooseProduct: true,
                            chosenProductID: this.state.activityDetail.world_rank_rewards[3].link,
                            worldRankIndex: 3,
                          })
                        }
                      }}
                      onChange={(e) => {
                        this.updateActivityWorldRankRewards("world_rank_rewards", 3, "title", e.target.value);
                      }}
                  />
                </Form.Item>
                {this.state.activityDetail.world_rank_rewards[3].state === 1 &&
                <Button type="primary" style={{marginLeft:"16px"}} onClick={() => {
                  this.setState({
                    showChooseProduct: true,
                    chosenProductID: this.state.activityDetail.world_rank_rewards[3].link,
                    worldRankIndex: 3,
                  })
                }}
                >选择</Button>
                }
              </div>
            </Form.Item>
            <Form.Item
                label="奖品数量："
                name={['world_rank_rewards',3,'number']}
            >
              <Input
                  onChange={(e) => {
                    console.log("onchange............");
                    this.updateActivityWorldRankRewards("world_rank_rewards", 3, "number", e.target.value * 1);
                  }}
              />
            </Form.Item>
            <Form.Item className="uploader" label="奖品图片：">
              <>
                <Upload
                    name="file"
                    listType="picture-card"
                    className="banner-uploader"
                    showUploadList={false}
                    action={uploadURI}
                    data={uploadExtra}
                    beforeUpload={beforeUpload}
                    onChange={(info) => {
                      this.handleChange("world_rank_rewards", info, 3);
                    }}
                >
                  {this.state.activityDetail.world_rank_rewards[3]["image"] ? (
                      <img
                          src={this.state.activityDetail.world_rank_rewards[3]["image"]}
                          alt="avatar"
                          style={{ width: "100%" }}
                      />
                  ) : (
                      uploadButton
                  )}
                </Upload>
                <div className="uploader-tips">
                  * 460X340，≤500k
                  <br />* 点击上传
                </div>
              </>
            </Form.Item>
            {this.state.activityDetail.world_rank_rewards[1].state === 2 &&
            this.state.activityDetail.world_rank_rewards[2].state === 2 &&
            this.state.activityDetail.world_rank_rewards[3].state === 1 && (
                <>
                  <Form.Item label="客服二维码：" className="uploader">
                    <>
                      <Upload
                          name="file"
                          listType="picture-card"
                          className="qrcode-uploader"
                          showUploadList={false}
                          action={uploadURI}
                          data={uploadExtra}
                          beforeUpload={beforeUpload}
                          onChange={(info) => {
                            this.handleChange("qrcode", info);
                          }}
                      >
                        {this.state.activityDetail.qrcode ? (
                            <img src={this.state.activityDetail.qrcode} alt="avatar" style={{ width: "100%" }} />
                        ) : (
                            uploadButton
                        )}
                      </Upload>
                      <div className="uploader-tips">
                        * 客服二维码(258x258，≤500k)
                        <br />* 拖曳文件或点击上传
                        <br />* 线下结算时，用户联系此二维码领奖
                      </div>
                    </>
                  </Form.Item>
                </>
            )}
            {this.state.activityDetail.world_rank_rewards[3].state === 2 && (
              <Form.Item label="三等奖优惠券：">
                <Select
                    defaultValue={this.state.activityDetail.world_rank_rewards[3]["link"]}
                    style={{ width: 240 }}
                    onChange={(v) => {
                      this.updateActivityWorldRankRewards("world_rank_rewards", 3, "link", v);
                    }}
                >
                  <Option value="">--请选择优惠券--</Option>
                  {this.state.coupons.map((item, index) => {
                    return (
                        <Option value={item.group_id + ""} key={index}>
                          {item.title}
                        </Option>
                    );
                  })}
                </Select>
                <div style={{ color: "red" }}>此处建议选择可以放入微信卡包的优惠券。</div>
              </Form.Item>
            )}
            {this.state.activityDetail.world_rank_rewards[3].state === 3 &&
            <Form.Item label="三等奖优惠券：">
              <div style={{display:"flex"}}>
                <Form.Item name={['world_rank_rewards', 3, 'link']}>
                  <Input
                      onClick={() => {
                        this.setState({
                          showChooseVoucher: true,
                          chosenVoucherID: this.state.activityDetail.world_rank_rewards[3].link,
                          worldRankIndex: 3,
                        })
                      }}
                  />
                </Form.Item>
                <Button type="primary" style={{marginLeft:"16px"}} onClick={() => {
                  this.setState({
                    showChooseVoucher: true,
                    chosenVoucherID: this.state.activityDetail.world_rank_rewards[3].link,
                    worldRankIndex: 3,
                  })
                }}
                >选择</Button>
              </div>
            </Form.Item>
            }
          </Card>
        </Card>
        <Card title="互动模块">
          <CardEdit activityDetail={{ ...this.state.activityDetail }}
                    updateState={(data) => {
                      this.setState(data);
                    }}
                    updateRewardRatio={this.state.updateRewardRatio}
          />
        </Card>
        <div className='create_active_footer'>
          <Button onClick={() => {
            this.onGoNext()
          }}
          >下一步：对接配置</Button>
        </div>

        <Modal
          visible={this.state.showChooseVoucher}
          title="选择微信代金券"
          width={800}
          onCancel={() => this.setState({ showChooseVoucher: false })}
        >
        {
          this.state.showChooseVoucher &&
          <Vouchers chooseDialog chosenVoucherID={this.state.chosenVoucherID} chooseCallback={(data) => {
            this.chooseVoucher(data);
            this.setState({ showChooseVoucher: false });
          }
          }
          />
        }
        </Modal>
        <Modal
          visible={this.state.showChooseProduct}
          title="选择实物奖品"
          width={1200}
          onCancel={() => this.setState({ showChooseProduct: false })}
          onOk={() => this.setState({ showChooseProduct: false })}
        >
        {
          this.state.showChooseProduct &&
          <Physical chooseDialog chosenProductID={this.state.chosenProductID} chooseCallback={(data) => {
            this.choosePhysical(data);
            this.setState({showChooseProduct: false})
          }} hide={() => {
          this.setState({showChooseProduct: false})}
          }
          />
        }
        </Modal>
        <Dialog
          visible={this.state.showRewardTypeTips}
          title="奖励结算方式的区别"
          style={{ width: "380px" }}
          onClose={() => this.setState({ showRewardTypeTips: false })}
        >
          <div style={{ marginBottom: "24px" }}>
            <p>线下结算</p>
            <p style={{ color: "red" }}>【商家需要在游戏规则中补充：获奖用户需要主动填写手机号，否则视为放弃奖品】</p>
            <p>活动结束后，活动会弹框提示用户填写手机号。商家根据排行榜、中奖数据以及用户填写的手机号自行联系用户，发放奖励。</p>

            <p>优惠券发放</p>
            <p style={{ color: "red" }}>
              【优惠券会比涉及到资金或货物，商家设置请一定要设置门槛和领取次数，否则造成的不良后果由商家自行承担】
            </p>
            <p>
              活动在发出前，需要先设置好优惠券，并且把优惠券链接填写到对应的名次中。活动结束后，会根据金币排行榜自动的对对应名次的用户发放优惠券。建议优惠券设置成有门槛优惠券，并且设置可领取次数，以免被用户领爆。
            </p>
            <p>备注：</p>
            <p>所有奖品以及奖品如何发放，建议商家在游戏规则中进行添加，用于展示给用户。</p>
          </div>
        </Dialog>
        </>
    );
  }
}
export default BasicInfoEdit
